import { FlashList } from '@shopify/flash-list';
import { debounce } from 'lodash';
import isNil from 'lodash/isNil';
import moment from 'moment';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState, } from 'react';
import { Animated, FlatList, InteractionManager, Keyboard, Platform, StatusBar, StyleSheet, View, } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Fuse from 'fuse.js';
import { CommonActions } from '@react-navigation/native';
import getSymbolFromCurrency from 'currency-symbol-map';
import { AppView } from 'design-system/AppView';
import Indicator from 'design-system/Indicator';
import FakeSearchBar from 'design-system/FakeSearchBar';
import Text from 'design-system/Text';
import { SCROLL_INDICATOR_INSETS, rem } from 'design-system/values';
import FloatingButton from 'design-system/FloatingButton';
import NoTransactions from 'features/feed/components/search/NoTransactions';
import NewSearchHeaderFiltered from 'features/feed/components/search/SearchHeaderFiltered';
import NewSearchToolbar from 'features/feed/components/search/SearchToolbar';
import { SEARCH_TOTAL_SPENT_MAX_HEIGHT } from 'features/feed/components/search/styles';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import { useDisableAndroidBackButton } from 'hooks';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import useColorScheme from 'hooks/useColorScheme';
import useEstimatedListSize from 'hooks/useEstimatedListSize';
import { useStatusBarHeight } from 'hooks/useHeaderHeight';
import usePrivateRoute from 'hooks/usePrivateRoute';
import { useFetchSearchTransactionData } from 'hooks/useFetchSearchTransactionData';
import { useMarginBottom } from 'hooks/useMarginBottom';
import { selectAllCategories } from 'reducers/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getTransactionsFlatlistData, } from 'utils/getTransactions';
import { addTransactionToCache } from 'actions';
import { useKeyboardHeightWeb } from 'features/feed/components/search/hooks/useKeyboardHeightWeb';
import SearchTransactionRow, { ROW_ITEM_HEIGHT, } from './components/SearchTransactionRow';
import { SEARCH_UPGRADE_BANNER_HEIGHT } from './components/SearchTransactionUpgradeBanner';
import { removeEmptyAmountFilters } from './removeEmptyAmountFilters';
import { selectSearchTransactionsFormatted } from './selectors';
import { isWeb } from '../../constants';
const HEADER_MAX_HEIGHT = rem(70);
const searchOptions = {
    includeScore: true,
    ignoreLocation: true,
    threshold: 0.2,
    ignoreFieldNorm: true,
    keys: [
        'counterpartName',
        'customName',
        'category.displayName',
        'merchant.displayName',
        'notes',
        'labels',
    ],
};
const getItemType = (item) => {
    if ('sectionTitle' in item) {
        return 'sectionHeader';
    }
    return 'row';
};
const keyExtractor = (item) => item.id?.toString();
export const extractNumbers = (input) => {
    const words = input.split(' ');
    const numbers = [];
    for (const word of words) {
        const sanitizedWord = word.replace(/[^0-9.-]+/g, '');
        const num = parseFloat(sanitizedWord);
        if (!Number.isNaN(num) && Number.isFinite(num)) {
            numbers.push(num);
        }
    }
    return numbers;
};
export const refineNumberSearch = (n, amount, nativeAmount) => {
    const absNumber = Math.abs(n);
    const absTransactionAmount = Math.abs(Math.trunc(amount));
    const absNativeAmount = Math.abs(Math.trunc(nativeAmount));
    const absTransactionAmountRound = Math.abs(Math.round(amount));
    const absNativeAmountRound = Math.abs(Math.round(nativeAmount));
    if (Number.isInteger(n)) {
        if (absNumber === absTransactionAmount ||
            absNumber === absNativeAmount ||
            absNumber === absTransactionAmountRound ||
            absNumber === absNativeAmountRound) {
            return true;
        }
    }
    else if (absNumber === Math.abs(Number(amount.toFixed(2))) ||
        absNumber === Math.abs(Number(nativeAmount.toFixed(2)))) {
        return true;
    }
    return false;
};
const defaultCategory = {
    id: 'bills',
    badgeText: 'Bills',
    filterType: 'CATEGORY',
};
const INSETS = {
    right: SCROLL_INDICATOR_INSETS.right,
    top: HEADER_MAX_HEIGHT,
};
const SearchTransactions = ({ navigation, route, }) => {
    usePrivateRoute();
    const fetchSearchData = useFetchSearchTransactionData();
    useEffect(() => {
        if (isWeb) {
            fetchSearchData();
        }
    }, [fetchSearchData]);
    const { height, paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
            title: 'Search Transactions',
        });
    }, []);
    const close = useCallback(() => {
        if (navigation.canGoBack()) {
            navigation.goBack();
        }
        else {
            navigation.dispatch(CommonActions.reset({
                index: 0,
                routes: [{ name: 'Feed' }],
            }));
        }
    }, []);
    const { filterType, filterLabel, merchant, category, searchBarY } = route.params || {};
    const onSearchCancel = useCallback(async () => {
        if (typeof route.params?.searchBarY === 'number') {
            Animated.timing(progress.current, {
                toValue: 0,
                duration: 200,
                useNativeDriver: true,
            }).start(() => {
                close();
            });
        }
        else {
            close();
        }
    }, [close, route.params?.searchBarY]);
    const backHandler = useCallback(() => {
        onSearchCancel();
        return true;
    }, [onSearchCancel]);
    useDisableAndroidBackButton(backHandler);
    const colorScheme = useColorScheme();
    const marginBottom = useMarginBottom();
    const { top } = useSafeAreaInsets();
    const categories = useAppSelector(selectAllCategories);
    const { isUnlockedForSpace, onPressUpgrade } = useShouldShowUpgrade({
        benefitId: 'search',
    });
    const transactions = useAppSelector((store) => selectSearchTransactionsFormatted(store, !isUnlockedForSpace));
    const isFetching = useAppSelector((store) => store.transactions.isFetchingSearchTransactions);
    const tags = useAppSelector((store) => store.transactions.tags);
    const statusBarHeight = useStatusBarHeight();
    useEffect(() => {
        if (!isWeb) {
            Animated.timing(progress.current, {
                toValue: 1,
                duration: 350,
                useNativeDriver: true,
            }).start();
        }
    }, []);
    const refToolbar = useRef(null);
    const timeoutHandle = useRef();
    const refSearchComponent = useRef(null);
    const refSearchBar = useRef(null);
    const refAmountLowerInput = useRef(null);
    const refAmountUpperInput = useRef(null);
    const getActiveFilter = useCallback((oldActiveFilters) => {
        const activeFilter = { ...oldActiveFilters };
        // set default filter by tag
        if (filterType === 'TAG' && !!filterLabel) {
            const currentTag = (tags || []).find((tag) => tag.label.toLowerCase() === filterLabel.toLowerCase());
            if (currentTag) {
                activeFilter[filterType] = [
                    {
                        ...currentTag,
                        badgeText: `#${filterLabel}`,
                        filterType,
                    },
                ];
            }
        }
        if (filterType === 'MERCHANT' && merchant) {
            const merchantObj = {
                ...merchant,
                id: merchant.id,
                badgeText: merchant.displayName,
                filterType,
            };
            activeFilter[filterType] = [merchantObj];
        }
        if (filterType === 'CATEGORY' && category) {
            activeFilter[filterType] = [
                {
                    ...category,
                    badgeText: category.displayName,
                    filterType,
                },
            ];
        }
        return activeFilter;
    }, [category, filterLabel, filterType, merchant, tags]);
    const currency = useAppSelector((store) => store.user.user.currency || 'GBP');
    const currencySymbol = useMemo(() => getSymbolFromCurrency(currency) || currency, [currency]);
    const [state, setState] = useState(() => {
        const defaultAmount = {
            value: '15',
            badgeText: `>${currencySymbol}`,
            filterType: 'AMOUNT_UPPER',
        };
        return {
            defaultAmount,
            flashListTransactions: [],
            searchValue: '',
            listPadding: 0,
            activeFilter: getActiveFilter(),
            loadingResultsFirstLoad: true,
            totalAmount: null,
            defaultCategory,
            isKeyboardVisible: true,
        };
    });
    useEffect(() => {
        if (tags.length) {
            setState((oldState) => ({
                ...oldState,
                activeFilter: getActiveFilter(oldState.activeFilter),
            }));
        }
    }, [getActiveFilter, tags]);
    useEffect(() => {
        if (!state.defaultCategory.displayName && categories && categories.length) {
            const categData = categories.find((item) => item.id === defaultCategory.id);
            setState((oldState) => ({
                ...oldState,
                defaultCategory: {
                    ...defaultCategory,
                    ...categData,
                },
            }));
        }
    }, [categories, state.defaultCategory]);
    const filterResponse = useCallback((dataInput) => {
        clearTimeout(timeoutHandle.current);
        const fuse = new Fuse([], searchOptions);
        timeoutHandle.current = setTimeout(() => {
            const activeStateFilter = state.activeFilter;
            const wordsToMatch = [];
            if (dataInput ||
                (activeStateFilter && Object.keys(activeStateFilter).length)) {
                const numbers = extractNumbers(dataInput || '');
                const filteredTransactions = transactions?.filter((transaction) => {
                    let shouldBeShown = true;
                    if (activeStateFilter.CATEGORY) {
                        const shouldPassThisCheck = activeStateFilter.CATEGORY.some((element) => 'id' in element && transaction.categoryId === element.id);
                        if (!shouldPassThisCheck)
                            shouldBeShown = false;
                    }
                    if (activeStateFilter.MERCHANT) {
                        const shouldPassThisCheck = activeStateFilter.MERCHANT.some((element) => 'displayName' in element &&
                            transaction.merchant?.displayName === element.displayName);
                        if (!shouldPassThisCheck)
                            shouldBeShown = false;
                    }
                    if (activeStateFilter.TAG) {
                        const shouldPassThisCheck = activeStateFilter.TAG.some((element) => 'label' in element &&
                            transaction.labels
                                ?.map((label) => label.toLowerCase())
                                .includes(element.label.toLowerCase()));
                        if (!shouldPassThisCheck)
                            shouldBeShown = false;
                    }
                    if (activeStateFilter.ACCOUNT) {
                        const shouldPassThisCheck = activeStateFilter.ACCOUNT.some((element) => 'id' in element && element.id === transaction.accountId);
                        if (!shouldPassThisCheck)
                            shouldBeShown = false;
                    }
                    if (activeStateFilter.AMOUNT_LOWER &&
                        Math.abs(transaction.amount) >=
                            Number(activeStateFilter.AMOUNT_LOWER.value)) {
                        shouldBeShown = false;
                    }
                    if (activeStateFilter.AMOUNT_UPPER &&
                        Math.abs(transaction.amount) <=
                            Number(activeStateFilter.AMOUNT_UPPER.value)) {
                        shouldBeShown = false;
                    }
                    if (activeStateFilter.DATES) {
                        const date = transaction.customDate || transaction.bookingDate;
                        if (!(date &&
                            moment(date).isBetween(activeStateFilter.DATES.startDate, activeStateFilter.DATES.endDate, 'day', '[]'))) {
                            shouldBeShown = false;
                        }
                    }
                    if (activeStateFilter.RECEIPT && !transaction.receiptId) {
                        shouldBeShown = false;
                    }
                    if (dataInput) {
                        fuse.setCollection([transaction]);
                        const result = fuse.search(dataInput);
                        // the score: 0 is perfecting match, 1 is no match, can be adjusted to get different search results
                        // https://www.fusejs.io/api/options.html#includescore
                        if (result.length === 0 || (result[0]?.score ?? 1) > 0.2) {
                            shouldBeShown = false;
                        }
                        // exact match for numbers
                        numbers.forEach((number) => {
                            if (refineNumberSearch(number, transaction.amount || Number.MIN_SAFE_INTEGER, transaction.nativeAmount?.amount || Number.MIN_SAFE_INTEGER)) {
                                shouldBeShown = true;
                            }
                        });
                    }
                    return shouldBeShown;
                });
                const flashListTransactions = getTransactionsFlatlistData(filteredTransactions, false);
                let totalAmount = 0;
                if (filteredTransactions && filteredTransactions.length > 0) {
                    const isActiveExcludedFilter = activeStateFilter &&
                        Object.keys(activeStateFilter).length &&
                        activeStateFilter.CATEGORY &&
                        activeStateFilter.CATEGORY.find((category) => 'id' in category && category.id === 'internal');
                    const isExcludedCategoryInSearch = isActiveExcludedFilter ||
                        (dataInput &&
                            wordsToMatch.every((word) => word.match('excluded')));
                    totalAmount = filteredTransactions.reduce((prev, next) => {
                        //  count excluded if selected in search
                        if (isExcludedCategoryInSearch &&
                            next.category &&
                            next.category.id === 'internal') {
                            return prev + next.amount;
                        }
                        if (next.category?.id === 'internal') {
                            return prev;
                        }
                        return prev + next.amount;
                    }, 0);
                }
                setState((oldState) => ({
                    ...oldState,
                    flashListTransactions,
                    loadingResultsFirstLoad: false,
                    totalAmount,
                }));
            }
            else {
                setState((oldState) => ({
                    ...oldState,
                    totalAmount: null,
                }));
            }
        }, 0);
    }, [state.activeFilter, transactions]);
    const onChangeText = useMemo(() => debounce((dataInput) => {
        setState((state) => ({
            ...state,
            searchValue: dataInput || '',
        }));
    }, 300), []);
    useEffect(() => {
        const keyboardDidShow = (e) => {
            setState((oldState) => ({
                ...oldState,
                listPadding: e.endCoordinates.height,
                isKeyboardVisible: true,
            }));
        };
        const keyboardDidHide = () => {
            setState((oldState) => ({
                ...oldState,
                listPadding: 0,
                isKeyboardVisible: false,
            }));
        };
        const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', keyboardDidShow);
        const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', keyboardDidHide);
        return () => {
            keyboardDidShowListener?.remove();
            keyboardDidHideListener?.remove();
        };
    }, [close, filterResponse]);
    const onSelectFilter = useCallback((newFilter) => {
        if (newFilter) {
            setState((state) => {
                if (!state.activeFilter)
                    return state;
                const newActiveFilter = { ...state.activeFilter };
                const newFilterType = newFilter.filterType;
                if (newFilterType === 'TAG' ||
                    newFilterType === 'CATEGORY' ||
                    newFilterType === 'MERCHANT' ||
                    newFilterType === 'ACCOUNT') {
                    const oldFilters = newActiveFilter[newFilterType];
                    if (oldFilters?.length) {
                        // @ts-ignore
                        newActiveFilter[newFilterType] = [...oldFilters];
                    }
                    else {
                        newActiveFilter[newFilterType] = [];
                    }
                    const indexExisting = newActiveFilter[newFilterType]?.findIndex((e) => ('id' in e &&
                        'id' in newFilter &&
                        newFilter.id &&
                        newFilter.id === e.id) ||
                        ('displayName' in newFilter &&
                            newFilter.displayName &&
                            'displayName' in e &&
                            newFilter.displayName === e.displayName) ||
                        ('label' in newFilter &&
                            newFilter.label &&
                            'label' in e &&
                            newFilter.label === e.label));
                    if (indexExisting === -1) {
                        // @ts-ignore
                        newActiveFilter[newFilterType]?.push(newFilter);
                    }
                }
                else {
                    // @ts-ignore
                    newActiveFilter[newFilterType] = newFilter;
                }
                return {
                    ...state,
                    activeFilter: newActiveFilter,
                };
            });
        }
    }, []);
    /**
     * This hook listens to change in filterResponse<state.activeFilters, transactions>
     * and state.searchValue to update the list
     */
    useEffect(() => {
        InteractionManager.runAfterInteractions(() => {
            filterResponse(state.searchValue);
        });
    }, [filterResponse, state.searchValue]);
    const clearEmptyAmountFilter = useCallback((filterType) => {
        setState((prevState) => ({
            ...prevState,
            activeFilter: removeEmptyAmountFilters(prevState.activeFilter, filterType),
        }));
    }, []);
    const addNewAmountFilter = useCallback((filterType) => {
        if (!filterType) {
            return;
        }
        if (state.activeFilter[filterType]) {
            clearEmptyAmountFilter(filterType);
            return;
        }
        // eslint-disable-next-line react/no-access-state-in-setstate
        const newFilter = { ...state.activeFilter };
        newFilter[filterType] = {
            value: '',
            badgeText: `${filterType === 'AMOUNT_UPPER' ? '>' : '<'}${currencySymbol}`,
            filterType,
        };
        setState((oldState) => ({
            ...oldState,
            activeFilter: removeEmptyAmountFilters(newFilter, filterType),
        }));
    }, [clearEmptyAmountFilter, currencySymbol, state.activeFilter]);
    const onToggleAmountFilter = useCallback((filterType) => {
        if (filterType) {
            // New amount filter has been selected
            addNewAmountFilter(filterType);
        }
        else {
            // No amount filter has been selected
            clearEmptyAmountFilter(undefined);
        }
    }, [addNewAmountFilter, clearEmptyAmountFilter]);
    const applyFilterDefaultCateg = useCallback(() => {
        onSelectFilter(state.defaultCategory);
    }, [onSelectFilter, state.defaultCategory]);
    const applyFilterDefaultAmount = useCallback(() => {
        onToggleAmountFilter('AMOUNT_UPPER');
        onSelectFilter(state.defaultAmount);
        refSearchComponent.current?.onSetDefaultAmount(state.defaultAmount.value);
    }, [onSelectFilter, onToggleAmountFilter, state.defaultAmount]);
    const renderNoTransactions = useCallback(() => (<NoTransactions style={paddingHorizontalStyle} defaultAmount={state.defaultAmount.value} defaultCategoryName={state.defaultCategory.badgeText} onApplyDefaultCategoryFilter={applyFilterDefaultCateg} onApplyDefaultAmountFilter={applyFilterDefaultAmount}/>), [
        applyFilterDefaultAmount,
        applyFilterDefaultCateg,
        paddingHorizontalStyle,
        state.defaultAmount.value,
        state.defaultCategory.badgeText,
    ]);
    const paddingTop = useMemo(() => (isNil(state.totalAmount)
        ? HEADER_MAX_HEIGHT + statusBarHeight
        : HEADER_MAX_HEIGHT + SEARCH_TOTAL_SPENT_MAX_HEIGHT) + statusBarHeight, [state.totalAmount, statusBarHeight]);
    const renderNoResults = useCallback(() => (<View style={[styles.noTransactionsContainer, { marginTop: paddingTop }]}>
        <Text Text-16>No results found</Text>
      </View>), [paddingTop]);
    const dispatch = useAppDispatch();
    const openItem = useCallback((data) => {
        if (!isUnlockedForSpace) {
            onPressUpgrade();
            return;
        }
        dispatch(addTransactionToCache({
            ...data,
            isCompact: true,
        }));
        navigation.navigate('Item', {
            id: data.id,
        });
    }, [isUnlockedForSpace, onPressUpgrade]);
    const onRemoveFilter = useCallback((filterToRemove) => {
        if (!filterToRemove || !state.activeFilter) {
            return;
        }
        if (filterToRemove === 'CLEAR_FILTERS') {
            setState((oldState) => ({
                ...oldState,
                activeFilter: {},
                searchValue: '',
                loadingResultsFirstLoad: true,
                totalAmount: null,
            }));
        }
        else {
            const activeFilter = { ...state.activeFilter };
            const filterTypeToRemove = filterToRemove.filterType;
            if (filterTypeToRemove === 'TAG' ||
                filterTypeToRemove === 'CATEGORY' ||
                filterTypeToRemove === 'MERCHANT' ||
                filterTypeToRemove === 'ACCOUNT') {
                const oldFilters = activeFilter[filterTypeToRemove];
                if (oldFilters) {
                    // @ts-ignore
                    activeFilter[filterTypeToRemove] = [...oldFilters];
                }
                else {
                    activeFilter[filterTypeToRemove] = [];
                }
                if (oldFilters?.length) {
                    // @ts-ignore
                    activeFilter[filterTypeToRemove] = oldFilters.filter(
                    // @ts-ignore
                    (e) => e !== filterToRemove);
                }
                // @ts-ignore
                if (activeFilter[filterTypeToRemove]?.length < 1)
                    delete activeFilter[filterTypeToRemove];
            }
            else {
                delete activeFilter[filterTypeToRemove];
            }
            if (!Object.keys(activeFilter).length)
                setState((oldState) => ({
                    ...oldState,
                    loadingResultsFirstLoad: true,
                    totalAmount: null,
                }));
            setState((oldState) => ({ ...oldState, activeFilter }));
            InteractionManager.runAfterInteractions(() => {
                if (filterToRemove.filterType === 'AMOUNT_UPPER' ||
                    filterToRemove.filterType === 'AMOUNT_LOWER') {
                    refSearchBar.current?.focus();
                }
            });
        }
    }, [state.activeFilter]);
    const renderRow = useCallback(({ item }) => (<SearchTransactionRow item={item} openItem={openItem}/>), [openItem]);
    const onScrollBeginDrag = useCallback(() => {
        refToolbar.current?.keyboardHideFromScroll();
        Keyboard.dismiss();
    }, []);
    const estimatedListSize = useEstimatedListSize(state.flashListTransactions);
    const { bottom } = useSafeAreaInsets();
    const webKeyboardHeight = useKeyboardHeightWeb();
    const contentContainerStyle = useMemo(() => ({
        paddingTop,
        ...paddingHorizontalStyle,
        paddingBottom: bottom +
            state.listPadding +
            rem(16) +
            (isUnlockedForSpace ? 0 : SEARCH_UPGRADE_BANNER_HEIGHT) +
            (isWeb ? webKeyboardHeight : 0),
    }), [
        bottom,
        isUnlockedForSpace,
        paddingHorizontalStyle,
        paddingTop,
        state.listPadding,
        webKeyboardHeight,
    ]);
    const renderContent = useCallback(() => {
        if (state.loadingResultsFirstLoad ||
            (!state.searchValue &&
                (!state.activeFilter || !Object.keys(state.activeFilter).length))) {
            return renderNoTransactions();
        }
        if (!state.flashListTransactions.length) {
            if (isFetching) {
                return (<Indicator dark style={[styles.noTransactionsContainer, styles.indicatorContainer]}/>);
            }
            return renderNoResults();
        }
        const baseProps = {
            keyExtractor,
            onScrollBeginDrag,
            renderItem: renderRow,
            contentContainerStyle,
            scrollEventThrottle: 16,
            scrollIndicatorInsets: INSETS,
            data: state.flashListTransactions,
            keyboardShouldPersistTaps: 'always',
        };
        if (isWeb) {
            return <FlatList {...baseProps}/>;
        }
        return (<FlashList {...baseProps} getItemType={getItemType} onScrollBeginDrag={onScrollBeginDrag} estimatedItemSize={ROW_ITEM_HEIGHT} estimatedListSize={estimatedListSize}/>);
    }, [
        state.loadingResultsFirstLoad,
        state.searchValue,
        state.activeFilter,
        state.flashListTransactions,
        renderRow,
        onScrollBeginDrag,
        contentContainerStyle,
        estimatedListSize,
        renderNoTransactions,
        isFetching,
        renderNoResults,
    ]);
    const onSearchInputFocus = useCallback(() => {
        refToolbar.current?.showSpecialKeyboard(undefined);
    }, []);
    const progress = useRef(new Animated.Value(isWeb ? 1 : 0));
    const contentStyles = useMemo(() => Platform.OS === 'android'
        ? {
            flex: 1,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: StatusBar.currentHeight
                ? StatusBar.currentHeight + height
                : height,
        }
        : { flex: 1 }, [height]);
    const mainStyles = useMemo(() => [
        {
            opacity: progress.current?.interpolate({
                inputRange: [0.5, 1],
                outputRange: [0, 1],
                extrapolate: 'clamp',
            }),
        },
    ], []);
    const fakeSearchBarStyles = useMemo(() => [
        styles.fakeSearchBar,
        {
            top,
            transform: [
                {
                    translateY: progress.current?.interpolate({
                        inputRange: [0, 0.5],
                        outputRange: [searchBarY || 0, rem(4)],
                        extrapolate: 'clamp',
                    }),
                },
            ],
            opacity: progress.current?.interpolate({
                inputRange: [0.5, 1],
                outputRange: [1, 0],
                extrapolate: 'clamp',
            }),
        },
    ], [searchBarY, top]);
    const headerStyles = useMemo(() => ({
        elevation: progress.current?.interpolate({
            inputRange: [0.7, 1],
            outputRange: [0, colorScheme === 'dark' ? 0 : 5],
            extrapolate: 'clamp',
        }),
    }), [colorScheme]);
    const goToEditResults = useCallback(() => {
        navigation.navigate('EditSearchResults', {
            flashListTransactions: state.flashListTransactions,
        });
    }, [state.flashListTransactions]);
    return (<View style={styles.container}>
      <AppView type="secondary" withFrame={false}>
        <Animated.View style={[mainStyles, contentStyles]}>
          <View style={contentStyles}>
            {renderContent()}
            <NewSearchHeaderFiltered delayFocusTime={searchBarY ? 300 : undefined} ref={refSearchComponent} refSearchBar={refSearchBar} refAmountLowerInput={refAmountLowerInput} refAmountUpperInput={refAmountUpperInput} refSearchToolbar={refToolbar} onCancel={onSearchCancel} onChangeText={onChangeText} onInputFocus={onSearchInputFocus} activeFilter={state.activeFilter} onSelectFilter={onSelectFilter} onRemoveFilter={onRemoveFilter} onToggleAmountFilter={onToggleAmountFilter} headerStyles={headerStyles} totalAmount={state.flashListTransactions.length ? state.totalAmount : null} currencySymbol={currencySymbol}/>
            <NewSearchToolbar ref={refToolbar} refSearchBar={refSearchBar} refAmountLowerInput={refAmountLowerInput} refAmountUpperInput={refAmountUpperInput} activeFilter={state.activeFilter} onSelectFilter={onSelectFilter} onRemoveFilter={onRemoveFilter} onToggleAmountFilter={onToggleAmountFilter} isUnlocked={isUnlockedForSpace} onPressUpgrade={onPressUpgrade}/>
          </View>
        </Animated.View>
        {!state.isKeyboardVisible && state.flashListTransactions.length > 0 && (<FloatingButton Edit onPress={goToEditResults} style={marginBottom}/>)}
      </AppView>
      {!!searchBarY && (<Animated.View style={[fakeSearchBarStyles, paddingHorizontalStyle]} pointerEvents="none">
          <FakeSearchBar />
        </Animated.View>)}
    </View>);
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    noTransactionsContainer: {
        alignItems: 'center',
    },
    indicatorContainer: {
        bottom: rem(140),
    },
    fakeSearchBar: {
        left: 0,
        right: 0,
        position: 'absolute',
    },
});
export default SearchTransactions;
