import React, { useCallback, useRef } from 'react';
import { View, } from 'react-native';
import { useDerivedValue } from 'react-native-reanimated';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import SectionHeader from 'design-system/SectionHeader';
import { CARD_VIEW_BORDER_RADIUS } from 'design-system/CardView';
import { selectMonthlyCategories } from 'features/analytics/selectors';
import { useSyncMonthlyTotals } from 'features/analytics/hooks/useSyncMonthlyTotals';
import useStyles from 'hooks/useStyles';
import { selectCurrentPaydayRange } from 'reducers/selectors';
import { useAppSelector } from 'store/hooks';
import createStyleSheets from 'utils/createStyleSheets';
import AnalyticsCarousel from '../AnalyticsCarousel.web';
import AnalyticsControls from '../AnalyticsControls';
import AnalyticsSelector from './AnalyticsSelector';
import AnalyticsCarouselItem from '../AnalyticsCarouselItem';
import PeriodControl from '../PeriodControl';
const AnalyticsSectionListHeader = ({ position, positionToBlurFrom, monthlyTotals, filteredMonthlyTotals, chartTypeToShow, contentContainerStyle, setSelection, onLayout, onPressUpgradeForSearch, }) => {
    const currentPaydayRange = useAppSelector(selectCurrentPaydayRange);
    const monthlyCategories = useAppSelector(selectMonthlyCategories);
    const [onEndReached, fetching] = useSyncMonthlyTotals(position);
    const positionRef = useRef(position);
    positionRef.current = position;
    const positionAnimatedValue = useDerivedValue(() => position, [position]);
    const renderCarouselItem = useCallback(({ item, index }) => (<View>
        <AnalyticsCarouselItem index={index} positionAnimatedValue={positionAnimatedValue} value={item.value} spending={item.spending} previousSpending={monthlyTotals[index + 1]?.spending} currency={item.currency} income={item.income} monthlyCategories={monthlyCategories[`${item.from}|${item.to}`]} spendingBreakdown={item.spendBreakdown} spendingBreakdownPrevious={monthlyTotals[index + 1]?.spendBreakdown} chartTypeToShow={chartTypeToShow} currentFrom={item.from} currentTo={item.to} previousFrom={monthlyTotals[index + 1]?.from} previousTo={monthlyTotals[index + 1]?.to} blurValue={positionToBlurFrom !== undefined && index >= positionToBlurFrom} onPressUpgradeForSearch={onPressUpgradeForSearch}/>
        <PeriodControl type="analytics" maxDataIndex={monthlyTotals.length - 1}/>
      </View>), [
        positionAnimatedValue,
        monthlyTotals,
        monthlyCategories,
        chartTypeToShow,
        positionToBlurFrom,
        onPressUpgradeForSearch,
    ]);
    const styles = useStyles(styleSet);
    return (<View onLayout={onLayout}>
      <View style={contentContainerStyle}>
        <AnalyticsControls month={monthlyTotals[position]} payPeriod={currentPaydayRange} step={filteredMonthlyTotals?.step} accountIds={filteredMonthlyTotals?.accountIds} enableFilter/>
        <AnalyticsCarousel type="analytics" data={monthlyTotals} onEndReached={onEndReached} renderItem={renderCarouselItem} isSwitchingTotalsStep={fetching.switchingTotalsStep}/>
        <Spacer.H8 />
        <SectionHeader style={styles.title}>Analytics breakdown</SectionHeader>
        <View style={styles.container}>
          <AnalyticsSelector setSelection={setSelection}/>
        </View>
      </View>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    title: {
        marginHorizontal: rem(16),
        marginBottom: rem(16),
    },
    container: {
        backgroundColor: colors.cards.onDark,
        marginHorizontal: rem(16),
        paddingTop: rem(16),
        borderTopLeftRadius: CARD_VIEW_BORDER_RADIUS,
        borderTopRightRadius: CARD_VIEW_BORDER_RADIUS,
    },
}));
export default AnalyticsSectionListHeader;
