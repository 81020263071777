import React from 'react';
import { rem } from 'design-system';
import SubscriptionEditTypeModal from 'features/subscriptions/components/modal/SubscriptionEditTypeModalContent';
import IconTransactionCategory from 'features/transactions/icons/IconTransactionCategory';
const ExcludedModal = ({ onPressEdit, onCancel }) => {
    const pressedEdit = () => {
        onPressEdit();
    };
    const pressedCancel = () => {
        onCancel();
    };
    return (<SubscriptionEditTypeModal icon={<IconTransactionCategory width={rem(32)} height={rem(32)} category={{ id: 'internal' }}/>} confirm="Thanks, I'll keep it here" negative="Okay, but let me edit!" title="Excluded" subTitle="Your internal transfers and credit card repayments should always stay
    here, otherwise your income and expenses will count twice in Analytics." onConfirm={pressedCancel} onCancel={pressedEdit}/>);
};
export default ExcludedModal;
