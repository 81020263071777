import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState, } from 'react';
import { View, Keyboard, Platform, TouchableOpacity, } from 'react-native';
import emoji from 'node-emoji';
import { CommonActions } from '@react-navigation/native';
import Spacer from 'design-system/Spacer';
import Indicator from 'design-system/Indicator';
import StatusBar from 'design-system/StatusBar';
import { AppView } from 'design-system/AppView';
import ShakeContainer from 'design-system/ShakeContainer';
import Text, { styles as textStyles } from 'design-system/Text';
import { hitSlop, marginBottom, rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import Button from 'design-system/Button';
import { saveNote } from 'actions/transactions';
import TextInput from 'utils/packages/TextInput';
import usePrivateRoute from 'hooks/usePrivateRoute';
import createStyleSheets from 'utils/createStyleSheets';
import { useAutoFocusInput } from 'hooks/useAutoFocusInput';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { isValidUserInputMultilineString } from 'utils/validation';
import { triggerInputErrorHapticFeedback } from 'utils/hapticFeedback';
import useFetchOld, { muteErrorAndResultButThrows } from 'hooks/useFetch';
import { isWeb } from '../../../constants';
import { useTransaction } from '../hooks/useTransaction';
import useRateAppAfterTransactionEdit from '../hooks/useRateAppAfterTransactionEdit';
const placeholders = [
    emoji.emojify('Spending all my :moneybag:!'),
    emoji.emojify(':pizza: with friends.'),
    emoji.emojify(':hamburger: :fries: :icecream:'),
    emoji.emojify('My terrific weekend! :tada: :confetti_ball:'),
    emoji.emojify('I :heart: :coffee:!'),
    emoji.emojify(':cloud: :ok_woman: :cloud:'),
    emoji.emojify(':money_with_wings: :money_with_wings: :money_with_wings:'),
    emoji.emojify(':see_no_evil: :hear_no_evil: :speak_no_evil:'),
    emoji.emojify(':sushi: :ramen: :rice_ball:'),
    emoji.emojify(':poop: :poop: :poop:'),
];
const AddNoteScreen = ({ navigation, route, transaction }) => {
    usePrivateRoute();
    const { height } = useAppFrameDimensions();
    const rateApp = useRateAppAfterTransactionEdit();
    const [note, setNoteState] = useState(transaction.notes || '');
    const error = useMemo(() => !isValidUserInputMultilineString(note), [note]);
    const shakeCallbackRef = useRef();
    const styles = useStyles(styleSet);
    const [, , isSaving, fetch] = useFetchOld(muteErrorAndResultButThrows);
    const saveDetails = useCallback(async () => {
        try {
            if (error) {
                triggerInputErrorHapticFeedback();
                shakeCallbackRef.current?.();
                return;
            }
            await fetch(saveNote(route.params.id, note.trim()));
            if (navigation.canGoBack()) {
                navigation.goBack();
            }
            else {
                navigation.dispatch(CommonActions.reset({
                    index: 0,
                    routes: [{ name: 'Feed' }],
                }));
            }
            rateApp();
        }
        catch {
            //
        }
    }, [error, note, rateApp, route.params.id]);
    const colors = useColors();
    useLayoutEffect(() => {
        const title = transaction?.customName || transaction?.counterpartName || 'Add a note';
        navigation.setOptions({
            title,
            headerTitle: 'Add a note',
            headerTintColor: colors.text.primary,
            ...(!isWeb && {
                headerRight: () => isSaving ? (<Indicator style={styles.indicator} dark/>) : (<TouchableOpacity onPress={saveDetails} hitSlop={hitSlop}>
              <Text Text-16 OffBlack>
                Save
              </Text>
            </TouchableOpacity>),
            }),
        });
    }, [
        colors.background.light,
        colors.text.primary,
        isSaving,
        saveDetails,
        styles.indicator,
        transaction,
    ]);
    const [visibleHeight, setVisibleHeight] = useState(height);
    useEffect(() => {
        const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', (e) => {
            const newSize = height - e.endCoordinates.height;
            setVisibleHeight(newSize);
        });
        const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
            setVisibleHeight(height);
        });
        return () => {
            keyboardDidShowListener?.remove();
            keyboardDidHideListener?.remove();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const registerShakeCallback = useCallback((shakeCallback) => {
        shakeCallbackRef.current = shakeCallback;
    }, []);
    const placeholder = useMemo(() => placeholders[Math.floor(Math.random() * placeholders.length)], []);
    const textInputRef = useRef(null);
    useAutoFocusInput(textInputRef);
    return (<AppView avoidHeader isInTab={isWeb}>
      <StatusBar barStyle="dark-content"/>
      {isWeb && <Spacer h={72}/>}
      <View style={{
            height: Platform.OS === 'ios'
                ? visibleHeight - rem(80) - marginBottom
                : undefined,
        }}>
        <TextInput multiline value={note} maxLength={1000} ref={textInputRef} allowFontScaling={false} placeholder={placeholder} onChangeText={setNoteState} underlineColorAndroid="transparent" focusStyle={styles.textInputFocus} selectionColor={colors.text.selection} style={[styles.input, textStyles.Text_16]} placeholderTextColor={colors.text.placeholder}/>
        {error && (<ShakeContainer shakeOnMount registerCallback={registerShakeCallback}>
            <Text TextThin-14 Red centered>
              {emoji.emojify("Note contains invalid characters '<' and '>' :warning:")}
            </Text>
          </ShakeContainer>)}
      </View>
      <Button brand floating title="Save" onPress={saveDetails} isFetching={isSaving}/>
    </AppView>);
};
const AddNoteScreenWrapper = (props) => {
    usePrivateRoute();
    const { id } = props.route.params;
    const { transaction, LoadingOr404 } = useTransaction(id);
    useEffect(() => {
        if (!transaction) {
            props.navigation.setOptions({
                title: 'Add Note',
                headerTitle: '',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (!transaction) {
        return LoadingOr404;
    }
    return <AddNoteScreen {...props} transaction={transaction}/>;
};
const styleSet = createStyleSheets((colors) => ({
    input: {
        flexGrow: 1,
        padding: rem(16),
        ...(isWeb
            ? {
                color: colors.text.secondary,
            }
            : {
                paddingVertical: 0,
                color: colors.text.primary,
            }),
    },
    indicator: {
        flex: undefined,
    },
    textInputFocus: {
        color: colors.text.primary,
    },
}));
export default AddNoteScreenWrapper;
