import { View } from 'react-native';
import Amount from 'design-system/Amount';
import moment from 'moment';
import React, { memo } from 'react';
import { CardView, rem } from 'design-system';
import { IconBoldCalendar } from 'design-system/icons';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import Text from 'design-system/Text';
import IconCircle from 'design-system/icons/IconCircle';
import { getDailyAllowance } from '../utils/getDailyAllowance';
export const DailyAllowanceView = memo(({ isCurrentPeriod, currentMonthlyTotal }) => {
    const styles = useStyles(styleSet);
    const colors = useColors();
    if (!isCurrentPeriod || !currentMonthlyTotal) {
        return null;
    }
    const dailyAllowance = getDailyAllowance(currentMonthlyTotal);
    if (dailyAllowance == null) {
        return null;
    }
    return (<CardView noMarginTop>
        <View style={styles.container}>
          <View style={styles.content}>
            <IconCircle width={44} height={44} color={colors.background.dark}>
              <IconBoldCalendar />
            </IconCircle>
            <View style={styles.innerContent}>
              <View style={styles.titleContainer}>
                <Text Text-16>Daily allowance</Text>
                <Amount Numbers-16 value={{
            value: dailyAllowance,
            currency: currentMonthlyTotal.currency,
        }}/>
              </View>
              <Text Secondary TextThin-14>{`Until ${moment(currentMonthlyTotal.to).format('DD MMMM')}`}</Text>
            </View>
          </View>
        </View>
      </CardView>);
});
const styleSet = createStyleSheets(() => ({
    container: {
        padding: rem(16),
        justifyContent: 'center',
    },
    content: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    innerContent: {
        flex: 1,
        marginStart: rem(16),
        flexDirection: 'column',
    },
    titleContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flex: 1,
        marginBottom: rem(4),
    },
}));
