import isEqual from 'lodash/isEqual';
import { call, put, select, take, takeEvery, takeLatest, } from 'typed-redux-saga/macro';
import * as types from 'actions/types';
import moment from 'moment';
import { selectCurrentPaydayRange, selectCurrentPaydayRangeWithFallback, selectHasSetPaydayRange, } from 'reducers/selectors';
import { getMonthlyCategories, getMonthlyMerchants, getMonthlyTotals, setAnalyticsPosition, } from 'actions/expenses';
import { selectMonthlyMerchants } from 'features/budgeting/selectors';
import { CANCEL_SUBSCRIPTIONS_SUCCESS, EDIT_SUBSCRIPTIONS_SUCCESS, } from 'features/subscriptions/actions/types';
import { AnalyticsLoadingState, isLoadedState, } from 'reducers/types';
import { navigationRef } from 'utils/navigationv6';
import { checkIfNeedToRefreshMonthlyTotals } from 'utils/api/refresh';
import { SAVE_BUDGETS_SUCCESS } from 'features/budgeting/actions/types';
import { SWITCHED_SPACE } from 'features/spaces/constants';
import { selectAnalyticsPosition, selectBudgetingPosition, selectExpensesScreen, selectIsFetchingTotals, selectMonthlyTotals, selectMonthlyTotalsFiltered, } from './selectors';
import { getDateRange } from './utils/api';
const frmt = (date) => moment(date).format('YYYY-MM-DD');
const frmtNow = () => moment().format('YYYY-MM-DD');
function getFormattedDate({ from, to }) {
    const fromFormatted = frmt(from);
    const toFormatted = frmt(to);
    return [fromFormatted, toFormatted];
}
function* fetchCategoriesAndMerchantsForPeriod({ from, to, forceRefresh = false, accountIds, }) {
    const monthlyCategories = yield* select((state) => state.expenses.monthlyCategories);
    const monthlyMerchants = yield* select((state) => state.expenses.monthlyMerchants);
    const [fromFormatted, toFormatted] = getFormattedDate({ from, to });
    const periodKey = `${from}|${to}`;
    const categoriesLoadingState = monthlyCategories[periodKey]?.loadingState;
    const categoriesValid = monthlyCategories[periodKey]?.isValid;
    if (forceRefresh ||
        !(
        // Reload unless the categories are loading or loaded already, or in case they have been invalidated
        (categoriesLoadingState === AnalyticsLoadingState.LOADING ||
            isLoadedState(categoriesLoadingState) ||
            categoriesValid))) {
        yield* put(getMonthlyCategories(fromFormatted, toFormatted, accountIds));
    }
    const merchantsLoadingState = monthlyMerchants[periodKey]?.loadingState;
    const merchantsValid = monthlyMerchants[periodKey]?.isValid;
    if (forceRefresh ||
        !(
        // Reload unless the merchants are loading or loaded already, or in case they have been invalidated
        (merchantsLoadingState === AnalyticsLoadingState.LOADING ||
            isLoadedState(merchantsLoadingState) ||
            merchantsValid))) {
        yield* put(getMonthlyMerchants(fromFormatted, toFormatted, accountIds));
    }
}
let prevPosition = 0;
const getEarliestFrom = (monthlyTotals) => frmt(monthlyTotals && monthlyTotals[monthlyTotals.length - 1]
    ? monthlyTotals[monthlyTotals.length - 1].from
    : moment().clone().subtract(12, 'month').startOf('month'));
function* refreshCategoriesAndMerchants(forceRefresh = false) {
    log(`[refreshCategoriesAndMerchants] refreshing categories and merchants... forceRefresh: ${forceRefresh}`, false, 'cyan');
    const monthlyTotalsUnfilitered = yield* select(selectMonthlyTotals);
    const monthlyTotalsFiltered = yield* select(selectMonthlyTotalsFiltered);
    const monthlyTotals = monthlyTotalsFiltered?.results || monthlyTotalsUnfilitered;
    const currentPaydayRange = yield* select(selectCurrentPaydayRangeWithFallback);
    const selectedScreen = navigationRef?.getCurrentRoute()?.name ||
        (yield* select(selectExpensesScreen));
    // todo we could optimise the following calls by combining them and splitting when they return in the reducer
    let position = prevPosition;
    if (selectedScreen && ['Analytics', 'Budgeting'].includes(selectedScreen)) {
        if (selectedScreen === 'Budgeting') {
            position = yield* select(selectBudgetingPosition);
        }
        else if (selectedScreen === 'Analytics') {
            position = yield* select(selectAnalyticsPosition);
        }
        prevPosition = position;
    }
    const currentPeriod = monthlyTotals[position];
    if (currentPeriod == null) {
        fetchCategoriesAndMerchantsForPeriod({
            from: currentPaydayRange.from,
            to: currentPaydayRange.to,
            accountIds: monthlyTotalsFiltered?.accountIds,
            forceRefresh: true,
        });
    }
    else {
        yield fetchCategoriesAndMerchantsForPeriod({
            from: currentPeriod.from,
            to: currentPeriod.to,
            accountIds: monthlyTotalsFiltered?.accountIds,
            forceRefresh,
        });
        const previousPeriod = monthlyTotals[position + 1];
        if (previousPeriod) {
            yield fetchCategoriesAndMerchantsForPeriod({
                from: previousPeriod.from,
                to: previousPeriod.to,
                accountIds: monthlyTotalsFiltered?.accountIds,
                forceRefresh,
            });
        }
        const nextPeriod = monthlyTotals[position - 1];
        if (nextPeriod) {
            yield fetchCategoriesAndMerchantsForPeriod({
                from: nextPeriod.from,
                to: nextPeriod.to,
                forceRefresh,
            });
        }
    }
}
export function* refreshAnalyticsData(action) {
    log('[refreshAnalyticsData] refreshing data...', false, 'cyan');
    const monthlyTotals = yield* select(selectMonthlyTotals);
    const hasPaydayRange = yield* select(selectHasSetPaydayRange);
    const earliestLoadedFormatted = getEarliestFrom(monthlyTotals);
    const isFetching = yield* select(selectIsFetchingTotals);
    if (isFetching)
        return;
    if (checkIfNeedToRefreshMonthlyTotals(action)) {
        yield* put({
            type: types.IS_FETCHING_TOTALS,
        });
        yield* put(getMonthlyTotals({
            dateFrom: earliestLoadedFormatted,
            dateTo: frmtNow(),
            isPayDay: hasPaydayRange,
            step: undefined,
            reset: { totals: true, filteredTotals: false },
        }));
        const monthlyTotalsFiltered = yield* select(selectMonthlyTotalsFiltered);
        if (monthlyTotalsFiltered) {
            yield* put(getMonthlyTotals({
                dateFrom: monthlyTotalsFiltered.results[monthlyTotalsFiltered.results.length - 1].from,
                dateTo: monthlyTotalsFiltered.results[0].to,
                isPayDay: undefined,
                step: monthlyTotalsFiltered.step,
                reset: { totals: false, filteredTotals: true },
                firstItem: undefined,
                accountIds: monthlyTotalsFiltered.accountIds,
            }));
        }
    }
    /**
     * Whenever the budgeting period is changed we have to wait for getMonthlyTotals to complete first
     * in order to get the new from/to dates to load categories and merchants for.
     *
     * In other cases the from/to dates are not changing, so loading categories and merchants alongside the totals is ok.
     */
    const canFetchCategoriesAndMerchantsInParallel = action.type !== types.FETCH_ANALYTICS_BUDGETING_DATA;
    if (canFetchCategoriesAndMerchantsInParallel) {
        yield* call(refreshCategoriesAndMerchants, true);
    }
    else {
        const action = yield* take((action) => [
            types.GET_MONTHLY_TOTALS_FAILURE,
            types.GET_MONTHLY_TOTALS_SUCCESS,
        ].includes(action.type));
        if (action.type === types.GET_MONTHLY_TOTALS_SUCCESS) {
            yield* call(refreshCategoriesAndMerchants, true);
        }
    }
}
function* fetchMoreAnalayticsData() {
    const hasPaydayRange = yield* select(selectHasSetPaydayRange);
    const monthlyTotals = yield* select(selectMonthlyTotals);
    const monthlyTotalsFiltered = yield* select(selectMonthlyTotalsFiltered);
    const selectedScreen = yield* select(selectExpensesScreen);
    if (monthlyTotalsFiltered && selectedScreen === 'Analytics') {
        // Custom steps only display as one card, so never try fetch more data
        if (monthlyTotalsFiltered.step === 'custom')
            return;
        const toDate = monthlyTotalsFiltered.results[monthlyTotalsFiltered.results.length - 1]
            .from;
        if (monthlyTotalsFiltered.step) {
            const { from, to } = getDateRange(monthlyTotalsFiltered.step, {
                from: moment(toDate),
                to: moment(toDate),
            }, true);
            const fromFormatted = frmt(from);
            const toFormatted = frmt(to);
            yield* put(getMonthlyTotals({
                dateFrom: fromFormatted,
                dateTo: toFormatted,
                isPayDay: undefined,
                step: monthlyTotalsFiltered.step,
                accountIds: monthlyTotalsFiltered.accountIds,
            }));
        }
        else {
            const lastToDate = moment(toDate).subtract(1, 'month');
            const fromDate = lastToDate.clone().subtract(6, 'month');
            const fromFormatted = frmt(fromDate);
            const toFormatted = frmt(toDate);
            yield* put(getMonthlyTotals({
                dateFrom: fromFormatted,
                dateTo: toFormatted,
                isPayDay: hasPaydayRange,
                accountIds: monthlyTotalsFiltered.accountIds,
            }));
        }
    }
    else {
        const toDate = moment(monthlyTotals[monthlyTotals.length - 1].from);
        const fromDate = toDate.clone().subtract(6, 'month');
        const toFormatted = frmt(toDate);
        const fromFormatted = frmt(fromDate);
        yield* put(getMonthlyTotals({
            dateFrom: fromFormatted,
            dateTo: toFormatted,
            isPayDay: hasPaydayRange,
        }));
    }
}
function* fetchCategoriesAndMerchants() {
    yield* call(refreshCategoriesAndMerchants, false);
}
function* handleFilterChanged(action) {
    const { step, accountIds } = action.payload;
    const monthlyTotals = yield* select(selectMonthlyTotals);
    const monthlyTotalsFiltered = yield* select(selectMonthlyTotalsFiltered);
    if (step !== undefined) {
        // The user has used the date filter
        const { from, to, difference } = getDateRange(step, {
            from: moment(action.payload.from),
            to: moment(action.payload.to),
        });
        const payPeriod = yield* select(selectCurrentPaydayRange);
        const prevPosition = yield* select(selectAnalyticsPosition);
        const fromFormatted = frmt(from);
        const payloadFromFormatted = frmt(action.payload.from);
        // we can apply this same optimisation to the filtered totals too (in the future)
        if (step === 'month' && !payPeriod) {
            // this isn't really a custom filter because the user is using a monthly pay period
            // so we can treat it like a regular monthly total
            // if we have this month already no need to reload totals
            if (monthlyTotals.find((total) => total.from === payloadFromFormatted)) {
                yield put(setAnalyticsPosition(difference, difference, true));
            }
            else {
                const to = moment(monthlyTotals[monthlyTotals.length - 1].from).subtract(1, 'month');
                const toFormatted = frmt(to);
                yield put(getMonthlyTotals({
                    dateFrom: fromFormatted,
                    dateTo: toFormatted,
                    isPayDay: false,
                    step: undefined,
                    reset: { totals: false, filteredTotals: true },
                    firstItem: difference,
                    accountIds,
                }));
                yield take((action) => [
                    types.GET_MONTHLY_TOTALS_SUCCESS,
                    types.GET_MONTHLY_TOTALS_FAILURE,
                ].includes(action.type));
            }
        }
        else if (monthlyTotalsFiltered &&
            step !== 'custom' &&
            step === monthlyTotalsFiltered.step &&
            isEqual(accountIds, monthlyTotalsFiltered.accountIds) &&
            monthlyTotalsFiltered.results.find((total) => total.from === payloadFromFormatted)) {
            log('[handleFilterChanged] Already in cache, switching position');
            yield put(setAnalyticsPosition(difference, difference, false));
        }
        else {
            log('[handleFilterChanged] custom filter, not in cache or related to pay period', false, 'cyan');
            const toFormatted = frmt(to);
            yield put(getMonthlyTotals({
                dateFrom: fromFormatted,
                dateTo: toFormatted,
                isPayDay: undefined,
                step,
                reset: { totals: false, filteredTotals: true },
                firstItem: difference,
                accountIds,
            }));
            yield take((action) => [
                types.GET_MONTHLY_TOTALS_SUCCESS,
                types.GET_MONTHLY_TOTALS_FAILURE,
            ].includes(action.type));
        }
        // only do this if position is the same as the component will call on other position changes
        if (prevPosition === difference) {
            yield call(refreshCategoriesAndMerchants, false);
        }
    }
    else if (accountIds !== undefined) {
        // The user has used the accountIds filter
        if (isEqual(accountIds, monthlyTotalsFiltered?.accountIds)) {
            return;
        }
        const earliestFromFormatted = getEarliestFrom(monthlyTotals);
        const hasPaydayRange = yield* select(selectHasSetPaydayRange);
        const toFormatted = frmtNow();
        yield put(getMonthlyTotals({
            dateFrom: earliestFromFormatted,
            dateTo: toFormatted,
            isPayDay: hasPaydayRange,
            step: undefined,
            reset: { totals: false, filteredTotals: true },
            firstItem: prevPosition,
            accountIds,
        }));
        yield take((action) => [
            types.GET_MONTHLY_TOTALS_SUCCESS,
            types.GET_MONTHLY_TOTALS_FAILURE,
        ].includes(action.type));
        yield call(refreshCategoriesAndMerchants, false);
    }
}
function* resetAnalyticsFilter() {
    const prevPosition = yield* select(selectAnalyticsPosition);
    log(`[resetAnalyticsFilter] reseting filter, previous position was ${prevPosition}...`, false, 'cyan');
    yield* put(setAnalyticsPosition(0, 0, true));
    if (prevPosition === 0) {
        yield* call(refreshCategoriesAndMerchants, true);
    }
}
// This handles the case where the user adds a merchant budget that was not already in the totals
// If there is no spending this period then it won't be there by default
function* refreshCurrentMerchantsIfNotInTotals(action) {
    log('[refreshCurrentMerchantsIfNotInTotals] checking if new merchant budget...', false, 'cyan');
    const monthlyMerchants = yield* select(selectMonthlyMerchants);
    const { from, to } = yield* select(selectCurrentPaydayRangeWithFallback);
    const paydayRange = getFormattedDate({ from, to });
    const currentMerchants = monthlyMerchants[`${paydayRange[0]}|${paydayRange[1]}`];
    if (currentMerchants) {
        const missingMerchant = Object.keys(action.extra.data).some((budgetKey) => {
            const [type, id] = budgetKey.split('.');
            if (type === 'merchant') {
                return (currentMerchants.merchants.find((merchant) => merchant.id.toString() === id) === undefined);
            }
            return false;
        });
        if (missingMerchant) {
            log('[refreshCurrentMerchantsIfNotInTotals] ...merchant total not found for current period refreshing...', false, 'cyan');
            yield* put(getMonthlyMerchants(paydayRange[0], paydayRange[1]));
        }
    }
}
function* budgetingAnalyticsSaga() {
    log('[budgetingAnalyticsSaga] starting...', false, 'cyan');
    yield* takeEvery((action) => [
        types.REFRESH_ON_START,
        types.SET_CONNECTIONS_STATUS,
        types.SET_PRIMARY_INCOME_SUCCESS,
        types.DELETE_INCOME_SUCCESS,
        types.HIDE_SUCCESS,
        types.DELETE_ACCOUNT_SUCCESS,
        types.CREATE_TRANSACTION_SUCCESS,
        types.DELETE_TRANSACTION_SUCCESS,
        types.UNSPLIT_TRANSACTION_SUCCESS,
        types.SPLIT_TRANSACTION_SUCCESS,
        types.CLOSED_CONNECTION_SUCCESS,
        types.DELETE_CONNECTION_SUCCESS,
        types.EDIT_CATEGORY_SUCCESS,
        CANCEL_SUBSCRIPTIONS_SUCCESS,
        EDIT_SUBSCRIPTIONS_SUCCESS,
        types.DELETE_CATEGORY_SUCCESS,
        types.SAVE_TOTAL_BUDGET_SUCCESS,
        types.CHANGE_DATE_TRANSACTION_SUCCESS,
        types.UPDATE_CATEGORY_SUCCESS,
        types.FETCH_ANALYTICS_BUDGETING_DATA,
        types.CHOOSE_RETAINED_CONNECTIONS_SUCCESS,
        SWITCHED_SPACE,
    ].includes(action.type), refreshAnalyticsData);
    yield* takeLatest(types.FETCH_CATEGORIES_MERCHANTS, fetchCategoriesAndMerchants);
    yield* takeLatest(types.FETCH_MORE_ANALYTICS_BUDGETING_DATA, fetchMoreAnalayticsData);
    yield* takeLatest(SAVE_BUDGETS_SUCCESS, refreshCurrentMerchantsIfNotInTotals);
    yield* takeLatest(types.RESET_ANALYTICS_FILTER, resetAnalyticsFilter);
    yield* takeLatest(types.ANALYTICS_FILTER_CHANGED, handleFilterChanged);
}
export default budgetingAnalyticsSaga;
