import { Platform } from 'react-native';
const toggle = {
    on: '#B368FF',
    off: '#6E719180',
};
const control = {
    hoverOnLight: '#362E4C66',
    hoverOnDark: '#362E4C99',
};
const borders = {
    logoStroke: '#393349',
    divider: '#393349',
    strongDivider: '#393349',
    iconStroke: '#393349',
};
const text = {
    white: '#FFFFFFE6',
    whiteOnColor: '#FFFFFF',
    whitePlaceholder: '#FFFFFFB3',
    primary: '#FFFFFFE6',
    onPrimary: '#17102A',
    secondary: '#FFFFFFCC',
    tertiary: '#FFFFFFCC',
    brand: '#B56CFF',
    textOnBrand: '#FFFFFFE6',
    textOnBrandLight: '#FFFFFF',
    income: '#7ED1FC',
    negative: '#FF7D9C',
    positive: '#5CD4CD',
    feed: '#FF93E4',
    invest: '#A07FFF',
    pay: '#5ADFE6',
    more: '#FFB8A7',
    save: '#FFD99E',
    placeholder: '#FFFFFF99',
    selection: '#B56CFF80',
    debt: '#FFFFFFCC',
};
const background = {
    contrast: '#B56CFF',
    dark: '#17102A',
    light: '#17102A',
    transparent: '#FFFFFF33',
    brand: '#17102A',
    brandMedium: '#231C35',
    secondary: '#FFFFFF33',
    frosted: '#231C35',
    brandLight: '#231C35',
    underlay: '#FFFFFF04',
    logo: '#FFFFFF',
    black: '#000',
    fullTransparent: 'rgba(0, 0, 0, 0)',
    artwork: '#F8F9FB',
    verticalTab: '#231C35',
};
const cards = {
    onDark: '#231C35',
    onLight: '#231C35',
    transparentOnDark: '#393349',
    brand: '#17102A',
};
const buttons = {
    primary: '#E9E8EB',
    primaryOnBrand: '#E9E8EB',
    primaryOnBrandDisabled: '#FFFFFF80',
    secondary: '#393349',
    secondaryDisabled: '#B56CFF4D',
    brand: '#B56CFF',
    brandDisabled: '#B56CFF4D',
    brandLight: '#B56CFF',
    brandLightDisabled: '#F5EAFF4D',
    brandGroup: '#393349',
    brandGroupDisabled: '#3933494D',
    frosted: '#FFFFFF33',
    frostedDisabled: '#FFFFFF0D',
    onColor: '#393349',
    blackAbsolutelyOnBrand: '#FFF',
    negativeLight: '#FF7D9C1A',
    negativePrimary: '#FF7D9C',
    brandHover: '#7F49B6',
    brandLightHover: '#7F49B6',
    brandGroupHover: '#2B2340',
};
const elements = {
    black: '#17102A',
    white: '#FFFFFFE6',
    floatingWhite: '#FFFFF6',
    brandLight: '#B56CFF',
    brandMedium: '#57397E',
    primary: '#FFFFFFE6',
    secondary: '#FFFFFFE6',
    secondaryFill: '#FFFFFF',
    secondaryLight: '#FFFFFFCC',
    secondaryTransparent: '#FFFFFF33',
    secondaryTransparentFill: '#494356',
    onLight: '#393349',
    brand: '#B56CFF',
    feed: '#F692FF',
    positive: '#5CD4CD',
    negative: '#FF7D9C',
    income: '#7ED1FC',
    green: '#5CD4CD',
};
const status = {
    negative: '#FF7D9C',
    negativeLight: '#FF7D9C1A',
    positive: '#65C5BF',
    positiveLight: '#63DDCF17',
    informational: '#EC9E8F',
    informationalLight: '#EC9E8F12',
    offline: '#FFFFFFCC',
    offlineLight: '#D3D2D714',
};
const gradients = {
    white: {
        colors: ['#FFFFFFE6', '#FFFFFFE6'],
        locations: [0, 1],
        useAngle: true,
        angle: 90,
    },
    metallic: {
        colors: ['#FFFFFF00', '#FFFFFFFF', '#FFFFFF00'],
        locations: [0, 0.5, 1],
        useAngle: true,
        angle: 90,
    },
    ultimate: {
        colors: ['#B368FF', '#B368FF'],
        locations: [0, 1],
        useAngle: true,
        angle: 90,
    },
    pro: {
        colors: ['#5ADFE6', '#5ADFE6'],
        locations: [0, 1],
        useAngle: true,
        angle: 90,
    },
    plus: {
        colors: ['#FFD99E', '#FFD99E'],
        locations: [0, 1],
        useAngle: true,
        angle: 90,
    },
    green: {
        colors: ['#4FE8D6', '#4FE8D6'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    darkPurple: {
        colors: ['#A07FFF', '#A07FFF'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    lightPurple: {
        colors: ['#D490F8', '#D490F8'],
        locations: [0, 1],
        useAngle: true,
        angle: 90,
    },
    darkPink: {
        colors: ['#F692FF', '#F692FF'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    lightPink: {
        colors: ['#FCA1E5', '#FCA1E5'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    deepPink: {
        colors: ['#FF87BF', '#FF87BF'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    apricot: {
        colors: ['#FFA7A5', '#FFA7A5'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    orange: {
        colors: ['#FFB8A7', '#FFB8A7'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    peach: {
        colors: ['#FFBAC5', '#FFBAC5'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    yellow: {
        colors: ['#FFE49D', '#FFE49D'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    lime: {
        colors: ['#D6F991', '#D6F991'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    turquoise: {
        colors: ['#6AF2F0', '#6AF2F0'],
        locations: [0, 1],
        useAngle: true,
        angle: 90,
    },
    skyBlue: {
        colors: ['#7FDEFF', '#7FDEFF'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    red: {
        colors: ['#FF6BA0', '#FF6BA0'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    headerGradientCards: {
        colors: ['#17102A', '#17102A'],
        locations: [0, 1],
        useAngle: true,
        angle: 90,
    },
    brandGradient: {
        colors: ['#17102A', '#17102A'],
        locations: [0, 1],
        useAngle: true,
        angle: 90,
    },
    card: {
        colors: ['#17102A', '#17102A', '#23164200'],
        locations: [0, 0.1, 1],
        useAngle: true,
        angle: 0,
    },
    negativeCards: {
        colors: ['#231C35', '#231C35'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    brandCards: {
        colors: ['#231C35', '#231C35'],
        locations: [0, 1],
        useAngle: true,
        angle: 90,
    },
};
const night = {
    toggle,
    borders,
    control,
    text,
    background,
    cards,
    buttons,
    elements,
    status,
    gradients,
    shadows: {
        chart: '#FFFFFF33',
        androidShadowGradientStart: 'rgba(0,0,0,0)',
        androidShadowGradientEnd: 'rgba(0,0,0,0)',
        rewardPreview: 'transparent',
        tabBar: {},
        floatingButton: {},
        floatingSquircle: {
            ...Platform.select({
                android: {
                    elevation: 0,
                },
            }),
        },
        logoShadow: {},
        logoShadowInner: {},
        logoShadowInnerLight: {},
        receipt: {},
        fakeReceipt: {},
        cardLight: {},
        card: {},
        extendedHeader: {},
        cardMedium: {},
        cardLarge: {},
        badge: {},
        headerAnimatedShadow: {},
        headerAnimatedShadowWithElevation: {},
        squircle: {},
        stockScreenCTA: {},
        budgetingChartShadow: {},
        recommendationCard: {},
        subscriptionsLogo: {},
        subscriptionsLogoBolder: {},
        reportGiphyCard: {},
        reportImageCard: {},
        graphBar: {},
        statusIconContainer: {},
        webFloatingCard: {
            elevation: 2,
            shadowColor: '#133068',
            shadowOpacity: 0.01,
            shadowOffset: { width: 1, height: 1 },
            shadowRadius: 10,
        },
    },
    tabBar: {
        default: '#FFFFFFE6',
    },
    invest: {
        header: elements.white,
    },
    statusBar: {
        regular: 'light-content',
    },
};
export default night;
