import Config from 'utils/packages/configs';
import equal from 'fast-deep-equal/es6';
import { getAccessToken } from 'middleware/api';
import { cachePotsAccount, getPots } from 'features/pots/actions';
import { tryAtMost } from '../utils/network';
import { cacheTradingAccount, cacheConnectedAccount, cacheAccounts, getTradingAccount as getTradingAccountApiCall, getPendingTopups, getPositions, } from '../actions';
import { GET_CONNECTED_ACCOUNT_FAILURE, GET_TRADING_ACCOUNT_FAILURE } from '../actions/types';
import { transformAxiosErrorToFailureApiPayload } from '../utils/api';
let dispatch;
// eslint-disable-next-line import/no-extraneous-dependencies
const uuidV4 = require('uuid/v4');
export const ACCOUNT_UPDATE_INTERVAL_AFTER_SIGNUP = 10 * 1000;
/**
 * These are just wrappers for the network stuff, encapsulating the format of API calls.
 */
export const getTradingAccount = async () => {
    const url = `${Config.API_URL}/wealth/trading/account`;
    const access_token = (await getAccessToken.next({ next: undefined, errorType: 'GET_TRADING_ACCOUNT_FAILURE' })).value;
    if (access_token === null) {
        throw new Error('Refresh token expired');
    }
    const response = await tryAtMost(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${access_token}`,
            'Client-Date': new Date().toString(),
            'X-Request-ID': uuidV4(),
        },
    }, 1, true);
    return response.data;
};
export const getConnectedAccount = async () => {
    const url = `${Config.API_URL}/wealth/trading/connected-account`;
    const access_token = (await getAccessToken.next({ next: undefined, errorType: 'GET_CONNECTED_ACCOUNT_FAILURE' })).value;
    if (access_token === null) {
        throw new Error('Refresh token expired');
    }
    const response = await tryAtMost(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${access_token}`,
            'Client-Date': new Date().toString(),
            'X-Request-ID': uuidV4(),
        },
    }, 1, true);
    return response.data;
};
const getPotsAccount = async () => {
    const url = `${Config.API_URL}/wealth/pots/account`;
    const access_token = (await getAccessToken.next({ next: undefined, errorType: 'GET_POTS_ACCOUNT_FAILURE' })).value;
    if (access_token === null) {
        throw new Error('Refresh token expired');
    }
    const response = await tryAtMost(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${access_token}`,
            'Client-Date': new Date().toString(),
            'X-Request-ID': uuidV4(),
        },
    }, 1, true);
    return response.data;
};
/**
 * This is the core generator for API, that ensures API requests don't fire too often. And AsyncGenerator IS defined, eslint is misconfigured
 */
async function* fireRefreshAccountRequestGenerator() {
    // observer is only ready for input after first invocation below, because this first invocation advances execution to the first yield.
    let forceUpdate = yield null; // on prepare run, we will come until here, but the `fetch` will be written later when somebody calls it again passing `refresh` callback
    let account = null;
    let connectedAccount = null;
    let potsAccount = null;
    let didUpdateTradingAccount = true;
    let didUpdateConnectedAccount = true;
    let didUpdatePotsAccount = true;
    let lastCallTime = 0;
    while (true) {
        if (forceUpdate === 'reset') {
            lastCallTime = 0;
            account = null;
            connectedAccount = null;
            potsAccount = null;
            didUpdateTradingAccount = true;
            didUpdateConnectedAccount = true;
            didUpdatePotsAccount = true;
        }
        else {
            const now = new Date().getTime();
            const timeSinceLastCall = now - lastCallTime;
            if (forceUpdate ||
                timeSinceLastCall > 15000 ||
                ((account === null || (account.status === 'ACTIVE' && connectedAccount === null)) && timeSinceLastCall > 3000)) {
                lastCallTime = now;
                try {
                    // await in loop inside of async generator is a perfectly natural thing!
                    // eslint-disable-next-line no-await-in-loop
                    const [tradingAccountResponse, connectedAccountResponse, potsAccountResponse] = await Promise.all([
                        getTradingAccount(),
                        // eslint-disable-next-line no-loop-func
                        new Promise((resolve) => {
                            getConnectedAccount()
                                .then((response) => {
                                resolve(response);
                            })
                                .catch((error) => {
                                const dispatchProps = transformAxiosErrorToFailureApiPayload(error);
                                if (dispatchProps) {
                                    dispatch({
                                        type: GET_CONNECTED_ACCOUNT_FAILURE,
                                        ...dispatchProps,
                                    });
                                }
                                resolve(null);
                            });
                        }),
                        // eslint-disable-next-line no-loop-func
                        new Promise((resolve) => {
                            getPotsAccount()
                                .then((response) => {
                                if (response.account.status === 'ACTIVE' && dispatch) {
                                    dispatch(getPots());
                                }
                                resolve(response);
                            })
                                .catch((error) => {
                                const dispatchProps = transformAxiosErrorToFailureApiPayload(error);
                                if (dispatchProps) {
                                    dispatch({
                                        type: 'GET_POTS_ACCOUNT_FAILURE',
                                        ...dispatchProps,
                                    });
                                }
                                resolve(null);
                            });
                        }),
                    ]);
                    dispatch({
                        type: 'SAVE_INVEST_ACCOUNT_STATE',
                        hasFunded: tradingAccountResponse.hasFunded,
                        hasTraded: tradingAccountResponse.hasTraded,
                    });
                    if (!equal(account, tradingAccountResponse.account)) {
                        account = tradingAccountResponse.account;
                        didUpdateTradingAccount = true;
                    }
                    else {
                        didUpdateTradingAccount = false;
                    }
                    if (connectedAccountResponse && !equal(connectedAccount, connectedAccountResponse.account)) {
                        connectedAccount = connectedAccountResponse.account;
                        didUpdateConnectedAccount = true;
                    }
                    else {
                        didUpdateConnectedAccount = false;
                    }
                    if (potsAccountResponse) {
                        const newPotsAccount = {
                            account: potsAccountResponse.account,
                            hasFunded: potsAccountResponse.hasFunded,
                            hasFundedPot: potsAccountResponse.hasFundedPot,
                            hasCreatedPot: potsAccountResponse.hasCreatedPot,
                            savingsPlan: potsAccountResponse.savingsPlan,
                            savingsPlanMode: potsAccountResponse.savingsPlanMode,
                            savingsPlanTierFees: potsAccountResponse.savingsPlanTierFees,
                            savingsPlanFrequency: potsAccountResponse.savingsPlanFrequency,
                        };
                        if (!equal(potsAccount, newPotsAccount)) {
                            potsAccount = newPotsAccount;
                            didUpdatePotsAccount = true;
                        }
                        else {
                            didUpdatePotsAccount = false;
                        }
                    }
                    else {
                        didUpdatePotsAccount = false;
                    }
                }
                catch (error) {
                    const dispatchProps = transformAxiosErrorToFailureApiPayload(error);
                    if (dispatchProps) {
                        dispatch({
                            type: GET_TRADING_ACCOUNT_FAILURE,
                            ...dispatchProps,
                        });
                    }
                }
            }
            else {
                didUpdateTradingAccount = false;
                didUpdateConnectedAccount = false;
                didUpdatePotsAccount = false;
            }
        }
        forceUpdate = yield account
            ? {
                account,
                connectedAccount,
                potsAccount,
                didUpdate: didUpdateTradingAccount,
                didUpdateConnectedAccount,
                didUpdatePotsAccount,
            }
            : null;
    }
}
/**
 * Here we initialize a generator that will hold internal state of when did it call API last time, and what was the result
 * I don't use the result anywhere, but decided to return it becasue I can.
 */
export const refreshAccount = fireRefreshAccountRequestGenerator();
/**
 * The reason for this function's existense is because updateTradingAccount needs to dispatch actions to store.
 * So we need to pass it `dispatch` somehow. I believe this way is clean and tidy.
 * It is used by InvestScreen, which acts like a root screen for the whole feature.
 * InvestScreen calls it inside of componentDidMount hook. Once on app start if user is logged in, or each time user logs in again.
 */
export const initializeApi = (d) => {
    dispatch = d;
    // On app start this will launch generator.
    // If user logs out - this will reset internal state of generator, to allow for cache update when user logs back in.
    refreshAccount.next('reset');
};
const scheduleNextUpdate = (times) => {
    setTimeout(() => {
        updateTradingAccount(true, times - 1);
    }, ACCOUNT_UPDATE_INTERVAL_AFTER_SIGNUP);
};
/**
 * A simple async function that fetches account data and caches it, returning TradingAccount
 *
 * Under the hood, it manages to save excessive dispatch to store when nothing really changed.
 * It also prevents from fetching API too often (max once per 15 sec).
 */
export const updateTradingAccount = async (forceUpdate, times, fetchPendingTopups) => {
    const result = (await refreshAccount.next(forceUpdate)).value;
    if (result?.account?.status === 'ACTIVE' || result?.potsAccount?.account?.status === 'ACTIVE') {
        if (fetchPendingTopups) {
            dispatch(getPendingTopups());
        }
    }
    if (result?.account?.status === 'ACTIVE') {
        dispatch(getPositions({ paging: { page: 1, perPage: 10 ** 6 } }));
    }
    if (result && result.didUpdate) {
        if (dispatch) {
            if (result.didUpdateConnectedAccount && result.connectedAccount) {
                dispatch(cacheAccounts(result.account, result.connectedAccount));
            }
            else {
                dispatch(cacheTradingAccount(result.account));
            }
            if (result.didUpdatePotsAccount && result.potsAccount) {
                dispatch(cachePotsAccount(result.potsAccount.account, result.potsAccount.hasFunded, result.potsAccount.hasCreatedPot, result.potsAccount.hasFundedPot, result.potsAccount.savingsPlan, result.potsAccount.savingsPlanMode, result.potsAccount.savingsPlanFrequency, result.potsAccount.savingsPlanTierFees));
            }
        }
        else {
            log('updateTradingAccount failed to cache: no dispatch provided', false, 'red');
        }
        if (result.account.status !== 'ACTIVE' && times && times > 0) {
            scheduleNextUpdate(times - 1);
        }
    }
    else {
        if (result === null) {
            if (dispatch) {
                dispatch({
                    type: 'GET_TRADING_ACCOUNT_FAILURE',
                });
            }
        }
        if (times && times > 0) {
            scheduleNextUpdate(times - 1);
        }
        if (result && result.didUpdateConnectedAccount && result.connectedAccount) {
            if (dispatch) {
                dispatch(cacheConnectedAccount(result.connectedAccount));
            }
        }
        if (result && result.didUpdatePotsAccount && result.potsAccount) {
            if (dispatch) {
                dispatch(cachePotsAccount(result.potsAccount.account, result.potsAccount.hasFunded, result.potsAccount.hasCreatedPot, result.potsAccount.hasFundedPot, result.potsAccount.savingsPlan, result.potsAccount.savingsPlanMode, result.potsAccount.savingsPlanFrequency, result.potsAccount.savingsPlanTierFees));
            }
        }
    }
    return result || null;
};
export const getTradingAccountFn = async () => {
    if (dispatch) {
        // @ts-ignore
        await dispatch(getTradingAccountApiCall());
        return true;
    }
    return null;
};
