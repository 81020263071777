import React, { useCallback, useEffect, useMemo, useState, memo, } from 'react';
import { View } from 'react-native';
import Animated, { useAnimatedScrollHandler, } from 'react-native-reanimated';
import { CommonActions } from '@react-navigation/native';
import { rem } from 'design-system/values';
import Indicator from 'design-system/Indicator';
import Button, { HEIGHT } from 'design-system/Button';
import { ButtonGroup } from 'design-system/ButtonGroup';
import FlatListItemCard from 'design-system/FlatListItemCard';
import { UpgradeWebModal } from 'design-system/UpgradeWebModal';
import { SettingsItemSwitch } from 'design-system/SettingsItem';
import { delay } from 'utils/delay';
import useStyles from 'hooks/useStyles';
import { isUnlocked } from 'features/premium/iap';
import { useMarginBottom } from 'hooks/useMarginBottom';
import createStyleSheets from 'utils/createStyleSheets';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useAppSelector } from 'store/hooks';
import { selectUserTier } from 'features/premium/selectors/tier';
import { isWeb } from '../../../../constants';
import { useNotificationsPreferences } from '../hooks/useNotificationsPreferences';
const strings = {
    buttonTitle: 'Save',
    preferenceItems: {
        'alerts.global.email': {
            title: 'All email communications',
            description: "Keep this off if you don't want any emails",
        },
        'alerts.daily_coaching.email': {
            title: 'Personal coaching',
            description: 'Personalised tips that help you get into a savings routine',
        },
        'alerts.product_update.email': {
            title: 'Product updates',
            description: 'Your monthly report with insights about your spending',
        },
        'alerts.new_rewards.email': {
            title: 'Cashback',
            description: 'Know when there is new cashback available',
        },
        'alerts.savings_offers.email': {
            title: 'Savings offers',
            description: 'Get personalised offers to save on energy, broadband and more',
        },
        'alerts.credit_offers.email': {
            title: 'Credit offers',
            description: 'Get personalised offers on credit cards, loans and more',
        },
    },
};
const notificationKeys = [
    'alerts.global.email',
    'alerts.daily_coaching.email',
    'alerts.product_update.email',
    'alerts.new_rewards.email',
    'alerts.savings_offers.email',
    'alerts.credit_offers.email',
];
const itemLength = notificationKeys.length;
const EmailNotificationSettings = ({ error, isFetching, scrollYPush, ListHeaderComponent, }) => {
    const { emailNormalizedNotificationPreferences: notificationPreferences, updatePreferences, } = useNotificationsPreferences();
    const styles = useStyles(styleSet);
    const [sectionShown, setSectionShown] = useState(!!isWeb);
    const userTier = useAppSelector(selectUserTier);
    useEffect(() => {
        if (!isWeb) {
            delay(() => {
                setSectionShown(true);
            }, 400);
        }
    }, []);
    const navigation = useMainStackNavigation();
    const [preferencesState, setPreferencesState] = useState(notificationPreferences);
    // Keep the local state in sync
    useEffect(() => {
        setPreferencesState(notificationPreferences);
    }, [notificationPreferences]);
    const onPreferenceChange = useCallback((value, preferenceKey) => {
        const { requiredTier } = strings.preferenceItems[preferenceKey];
        if (!requiredTier || isUnlocked(userTier, requiredTier)) {
            if (preferenceKey === 'alerts.global.email') {
                if (!value) {
                    setPreferencesState({
                        'alerts.global.email': false,
                        'alerts.product_update.email': false,
                        'alerts.new_rewards.email': false,
                        'alerts.savings_offers.email': false,
                        'alerts.credit_offers.email': false,
                        'alerts.daily_coaching.email': false,
                    });
                }
                else {
                    setPreferencesState({
                        'alerts.global.email': true,
                        'alerts.product_update.email': true,
                        'alerts.new_rewards.email': true,
                        'alerts.savings_offers.email': true,
                        'alerts.credit_offers.email': true,
                        'alerts.daily_coaching.email': true,
                    });
                }
            }
            else {
                setPreferencesState((oldState) => ({
                    ...oldState,
                    'alerts.global.email': true,
                    [preferenceKey]: value,
                }));
            }
        }
        else if (isWeb) {
            UpgradeWebModal.show(requiredTier);
        }
        else {
            navigation.navigate('EmmaProModal', { tier: requiredTier });
        }
    }, [userTier]);
    const getPreferenceValue = useCallback((preferenceKey) => {
        const { requiredTier } = strings.preferenceItems[preferenceKey];
        if (!requiredTier || isUnlocked(userTier, requiredTier)) {
            return preferencesState[preferenceKey];
        }
        return false;
    }, [preferencesState, userTier]);
    const data = useMemo(() => notificationKeys.map((preferenceKey) => ({
        preferenceKey,
        title: strings.preferenceItems[preferenceKey].title,
        description: strings.preferenceItems[preferenceKey].description,
        value: getPreferenceValue(preferenceKey),
        onValueChange: onPreferenceChange,
    })), [getPreferenceValue, onPreferenceChange]);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const paddingBottom = useMarginBottom('paddingBottom', HEIGHT + rem(16), paddingHorizontalStyle);
    const scrollHandlerPush = useAnimatedScrollHandler((handle) => {
        // eslint-disable-next-line no-param-reassign
        scrollYPush.value = handle.contentOffset.y;
    });
    const renderItem = useCallback(({ item: { preferenceKey, title, description, value, onValueChange }, index, }) => (<FlatListItemCard index={index} style={styles.eachItem} itemsLength={itemLength}>
        <SettingsItemSwitch key={preferenceKey} itemKey={preferenceKey} title={title} description={description} value={value} onValueChange={onValueChange}/>
      </FlatListItemCard>), [styles.eachItem]);
    if (!sectionShown) {
        return null;
    }
    if ((isFetching && Object.keys(notificationPreferences).length === 0) ||
        error ||
        Object.keys(preferencesState).length === 0) {
        return (<View style={styles.container}>
        <Indicator dark/>
      </View>);
    }
    return (<>
      <Animated.FlatList data={data} renderItem={renderItem} scrollEventThrottle={16} onScroll={scrollHandlerPush} showsVerticalScrollIndicator={false} ListHeaderComponent={ListHeaderComponent} contentContainerStyle={[styles.flatListContainer, paddingBottom]}/>
      <ButtonGroup type="floating" margins>
        <Button brand title={strings.buttonTitle} onPress={() => {
            updatePreferences(preferencesState);
            if (!navigation.canGoBack() && isWeb) {
                navigation.dispatch(CommonActions.reset({
                    index: 0,
                    routes: [{ name: 'Feed' }],
                }));
            }
            else {
                navigation.goBack();
            }
        }}/>
      </ButtonGroup>
    </>);
};
export default memo(EmailNotificationSettings);
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
    },
    flatListContainer: {
        paddingTop: rem(8),
    },
    eachItem: {
        backgroundColor: colors.cards.onDark,
    },
}));
