import { put, putResolve, takeLatest } from 'typed-redux-saga/macro';
import { editSavingsPlan, getPotsAccount, updateSavingRulesFailure, } from '../actions';
import { UPDATE_SAVING_RULES } from '../actions/types';
function* updateSavingRulesWorker(action) {
    try {
        const { savingsPlanId, rules } = action.payload;
        const response = yield* putResolve(editSavingsPlan(savingsPlanId, { rules }, true));
        if (response.payload.savingsPlan) {
            const modifiedResponse = { ...response, extra: { ...response.extra, skipSet: false } };
            yield* put(modifiedResponse);
        }
        else {
            throw new Error('Api failure');
        }
    }
    catch {
        yield* put(updateSavingRulesFailure());
        yield* putResolve(getPotsAccount());
    }
}
function* autosaveSagas() {
    yield* takeLatest(UPDATE_SAVING_RULES, updateSavingRulesWorker);
}
export default autosaveSagas;
