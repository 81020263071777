import * as types from './types';

export const setError = (error, errorMessage) => ({
  type: types.SET_ERROR,
  error,
  errorMessage,
  errorType: 'SetError',
});

export const resetError = () => ({
  type: types.RESET_ERROR,
});

export const clearHistoryError = (errorToClear) => ({
  type: types.CLEAR_HISTORY_ERROR,
  errorToClear,
});
