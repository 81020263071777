import { REHYDRATE } from 'redux-persist/constants';
import { LOGGED_OUT } from 'actions/types';
import * as ContactsActions from '../actions/types';
const initialState = {
    contactsCache: [],
    sentContacts: {},
    alreadyEmmaUsers: {},
    contactsWithFriends: {},
};
export const contactsReducer = (state = initialState, action) => {
    switch (action?.type) {
        case REHYDRATE: {
            if (action.payload && action.payload.contacts) {
                return {
                    ...initialState,
                    ...action.payload.contacts,
                };
            }
            return state;
        }
        case ContactsActions.SYNC_CONTACTS: {
            return {
                ...state,
                contactsCache: action.payload,
            };
        }
        case ContactsActions.POST_CONTACTS_SUCCESS: {
            const sentContacts = action?.extra?.contacts ?? [];
            const newContactsCache = {};
            sentContacts.forEach((contact) => {
                newContactsCache[contact.recordID] = {
                    phoneNumbers: contact?.phoneNumbers ?? [],
                };
            });
            return {
                ...state,
                sentContacts: {
                    ...state.sentContacts,
                    ...newContactsCache,
                },
            };
        }
        case ContactsActions.GET_CONTACTS_REQUEST: {
            return {
                ...state,
                contactsWithFriends: {},
                alreadyEmmaUsers: {},
            };
        }
        case ContactsActions.GET_CONTACTS_SUCCESS: {
            const { contactsWithFriends, alreadyEmmaUsers } = action.payload;
            return {
                ...state,
                contactsWithFriends,
                alreadyEmmaUsers,
            };
        }
        case LOGGED_OUT: {
            return initialState;
        }
        default:
            return state;
    }
};
