export const getDateRange = (step, { from, to }, excludeCurrent) => {
    switch (step) {
        case 'custom':
            return { from, to, difference: 0 };
        case 'isoWeek': {
            const unit = 'week';
            const fromDate = from.clone().subtract(12, unit).utc();
            const difference = to.diff(from, unit);
            const toDate = excludeCurrent
                ? to.clone().subtract(1, unit).utc()
                : to.clone().weekday(7);
            return { from: fromDate, to: toDate, difference };
        }
        case 'month': {
            const unit = 'month';
            const fromDate = from.clone().subtract(12, unit).startOf(unit);
            const difference = to.diff(from, unit);
            const toDate = excludeCurrent
                ? to.clone().subtract(1, unit).startOf(unit)
                : to.clone().endOf(unit);
            return { from: fromDate, to: toDate, difference };
        }
        case 'quarter': {
            const unit = 'quarter';
            const fromDate = from.clone().subtract(4, unit).startOf(unit);
            const difference = to.diff(from, unit);
            const toDate = excludeCurrent
                ? to.clone().subtract(1, unit).endOf(unit)
                : to.clone().endOf(unit);
            return { from: fromDate, to: toDate, difference };
        }
        case 'year': {
            const unit = 'year';
            const fromDate = from.clone().subtract(4, unit).startOf(unit);
            const difference = to.diff(from, unit);
            const toDate = excludeCurrent
                ? to.clone().subtract(1, unit)
                : to.clone().endOf('year');
            return { from: fromDate, to: toDate, difference };
        }
        default:
            throw new Error('Unsupport date in getDateRange');
    }
};
