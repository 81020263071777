import React, { useEffect } from 'react';
import Animated, { Easing, withTiming, ReduceMotion, useSharedValue, useAnimatedStyle, interpolate, Extrapolation, cancelAnimation, } from 'react-native-reanimated';
import { View } from 'react-native';
import { delay } from 'utils/delay';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import Text from './Text';
import { rem } from './values';
import SuccessAnimation from './SuccessAnimation';
export const SuccessView = ({ title, topTitle, subTitle, smallSubTitle, children, contentContainerStyle, }) => {
    const styles = useStyles(styleSet);
    const { paddingHorizontalStyle } = useAppFrameDimensions();
    const paddingBottom = useMarginBottom('paddingBottom', undefined, styles.buttonContainer);
    const sharedValue = useSharedValue(0);
    useEffect(() => {
        delay(() => {
            sharedValue.value = withTiming(1, {
                duration: 1200,
                reduceMotion: ReduceMotion.System,
                easing: Easing.bezier(0, 0.5, 0.25, 1),
            });
        }, 300);
        return () => {
            cancelAnimation(sharedValue);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const animatedTextStyles = useAnimatedStyle(() => ({
        opacity: interpolate(sharedValue.value, [0.5, 0.8], [0, 1], Extrapolation.CLAMP),
    }), []);
    return (<View style={styles.content}>
      <View style={[
            styles.innerContent,
            contentContainerStyle,
            paddingHorizontalStyle,
        ]}>
        <View style={styles.animationContainer}>
          <SuccessAnimation sharedValue={sharedValue}/>
        </View>
        <Animated.View style={animatedTextStyles}>
          {Boolean(topTitle) && (<Text centered Text-14 Secondary style={styles.title}>
              {topTitle}
            </Text>)}
          <Text Text-24 centered style={styles.title}>
            {title}
          </Text>
          {Boolean(subTitle) && (<Text centered {...(smallSubTitle
            ? { 'TextThin-14': true }
            : { 'TextThin-16': true })} Secondary>
              {subTitle}
            </Text>)}
        </Animated.View>
      </View>
      <View style={paddingBottom}>{children}</View>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    content: {
        flex: 1,
        backgroundColor: colors.background.light,
        paddingTop: rem(16),
        paddingHorizontal: rem(16),
    },
    innerContent: {
        flex: 1,
        justifyContent: 'center',
        marginBottom: rem(140),
    },
    title: {
        marginBottom: rem(8),
    },
    animationContainer: {
        alignItems: 'center',
        marginBottom: rem(24),
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        paddingHorizontal: rem(16),
    },
}));
export default SuccessView;
