import React from 'react';
import { FlatList, StyleSheet } from 'react-native';
import { rem } from 'design-system/values';
import { useCustomCategoryColors } from 'hooks/useCategory';
import ColorSelectorItem from './ColorSelectorItem';
export const ColorSelector = ({ value, onSelected }) => {
    const customCategoryColors = useCustomCategoryColors();
    const renderItem = ({ item }) => (<ColorSelectorItem value={item} selected={value === item[0]} onPress={onSelected}/>);
    const keyExtractor = (color) => color[0];
    return (<FlatList renderItem={renderItem} data={customCategoryColors} horizontal showsHorizontalScrollIndicator={false} contentContainerStyle={styles.listContainer} keyExtractor={keyExtractor}/>);
};
const styles = StyleSheet.create({
    listContainer: {
        paddingHorizontal: rem(6),
        paddingBottom: rem(16),
    },
});
