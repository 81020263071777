import _ from 'lodash';
import { sortAccordingToTimestamp } from 'utils/sort';
import { LOGGED_OUT } from 'actions/types';
import { filterActivities } from '../utils';
const initialState = {
    recurringBuys: [],
    recurringBuysForAssetId: {},
    historyForRecurringBuyId: {},
    isErrorInFetchingInvestAcc: undefined,
};
const getPercent = (num) => Number(`${Math.round(parseFloat(`${num}e${2}`))}e-${2}`);
const getParsedAnalystData = (analystInfo) => {
    const buyTotal = analystInfo.numberOfBuy + analystInfo.numberOfStrongBuy;
    const sellTotal = analystInfo.numberOfSell + analystInfo.numberOfStrongSell;
    const holdTotal = analystInfo.numberOfHold;
    const ratingsTotal = buyTotal + sellTotal + holdTotal;
    return {
        buyTotal,
        sellTotal,
        holdTotal,
        ratingsTotal,
        currency: analystInfo.currency,
        priceTargetLow: analystInfo.priceTargetLow,
        priceTargetHigh: analystInfo.priceTargetHigh,
        priceTargetMean: analystInfo.priceTargetMean,
        buyPercent: getPercent((buyTotal / ratingsTotal) * 100),
        holdPercent: getPercent((holdTotal / ratingsTotal) * 100),
        sellPercent: getPercent((sellTotal / ratingsTotal) * 100),
    };
};
const unpersistedInvestReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'INVEST_GET_ANALYST_INFO_SUCCESS_ACTION': {
            return {
                ...state,
                analystInfoMap: {
                    ...state.analystInfoMap,
                    [action.extra.symbol]: getParsedAnalystData(action.payload.analystInfo),
                },
            };
        }
        case 'CACHE_ACCOUNTS':
        case 'CACHE_TRADING_ACCOUNT':
        case 'GET_TRADING_ACCOUNT_SUCCESS': {
            return {
                ...state,
                isErrorInFetchingInvestAcc: false,
            };
        }
        case 'GET_TRADING_ACCOUNT_FAILURE': {
            return {
                ...state,
                isErrorInFetchingInvestAcc: true,
            };
        }
        case 'GET_RECURRING_BUYS_SUCCESS': {
            if (action.extra) {
                return {
                    ...state,
                    recurringBuysForAssetId: {
                        ...state.recurringBuysForAssetId,
                        [action.extra]: action.payload.recurringBuys,
                    },
                };
            }
            return {
                ...state,
                recurringBuys: action.payload.recurringBuys,
            };
        }
        case 'DELETE_RECURRING_BUY_SUCCESS': {
            const { id, assetId } = action.extra;
            return {
                ...state,
                recurringBuys: state.recurringBuys?.filter((recurringBuy) => recurringBuy.id !== id),
                recurringBuysForAssetId: {
                    ...state.recurringBuysForAssetId,
                    [assetId]: state.recurringBuysForAssetId?.[assetId]?.filter((recurringBuy) => recurringBuy.id !== id) || [],
                },
            };
        }
        case 'UPDATE_RECURRING_BUY_SUCCESS': {
            const { recurringBuy } = action.payload;
            return {
                ...state,
                recurringBuys: state.recurringBuys?.map((oldRecurringBuy) => {
                    if (oldRecurringBuy.id === recurringBuy.id) {
                        return recurringBuy;
                    }
                    return oldRecurringBuy;
                }),
                recurringBuysForAssetId: {
                    ...state.recurringBuysForAssetId,
                    [recurringBuy.assetId]: state.recurringBuysForAssetId?.[recurringBuy.assetId]?.map((oldRecurringBuy) => {
                        if (oldRecurringBuy.id === recurringBuy.id) {
                            return recurringBuy;
                        }
                        return oldRecurringBuy;
                    }) || [],
                },
            };
        }
        case 'GET_RECURRING_BUY_ACTIVITIES_SUCCESS': {
            const { id } = action.extra;
            const { activities, paging } = action.payload;
            const filteredActivities = activities.filter(filterActivities);
            if (paging.page === 1) {
                return {
                    ...state,
                    historyForRecurringBuyId: {
                        ...state.historyForRecurringBuyId,
                        [id]: filteredActivities,
                    },
                };
            }
            return {
                ...state,
                historyForRecurringBuyId: {
                    ...state.historyForRecurringBuyId,
                    [id]: _.uniqBy(state.historyForRecurringBuyId?.[id]?.concat(filteredActivities) ?? filteredActivities, (i) => i.id).sort(sortAccordingToTimestamp),
                },
            };
        }
        case LOGGED_OUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
export default unpersistedInvestReducer;
