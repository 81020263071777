import moment from 'moment';
import { createSelector } from 'reselect';
export const selectSubscriptions = (store) => store.subscriptions.subscriptions.data;
export const selectActiveSubscriptions = createSelector([selectSubscriptions], (subscriptions) => subscriptions.filter(({ isActive }) => isActive));
export const selectOrderedActiveSubscriptions = createSelector([selectActiveSubscriptions], (subscriptions) => subscriptions.slice().sort((a, b) => {
    if (a && a.predictions && !a.predictions.length) {
        return 1;
    }
    if (b && b.predictions && !b.predictions.length) {
        return -1;
    }
    const pa = a.predictions[0];
    const pb = b.predictions[0];
    return moment.utc(pa.date).diff(moment.utc(pb.date));
}));
export const selectInactiveSubscriptions = createSelector([selectSubscriptions], (subscriptions) => subscriptions.filter(({ isActive }) => !isActive));
export const selectInactiveSubscriptionsLength = createSelector([selectInactiveSubscriptions], (subscriptions) => subscriptions.length);
export const makeSelectSubscriptionById = () => createSelector([
    selectSubscriptions,
    (state, subscriptionId) => subscriptionId,
], (subscriptions, subscriptionId) => subscriptions.find((subscription) => subscription.id === subscriptionId));
