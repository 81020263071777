import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { REHYDRATE } from 'redux-persist/constants';
import { CALL_API } from 'middleware/api';
import { LOGGED_OUT } from 'actions/types';
import { useReSelector } from 'utils/hooksApi';
import * as fetch from './fetch';
/**
 * @deprecated
 */
export const createFetchReducer = (name, requestKey, successKey, errorKey, payloadMapper, clearOnFail = true) => {
    const initialState = {
        isFetching: false,
        data: undefined,
        error: undefined,
    };
    return (state = initialState, action) => {
        switch (action.type) {
            case REHYDRATE:
                return {
                    data: action.payload?.[name]?.data,
                    error: clearOnFail
                        ? undefined
                        : action.payload?.[name]?.error,
                    isFetching: state.isFetching,
                };
            case requestKey:
                return { ...state, isFetching: true, error: undefined };
            case successKey:
                let data;
                if (action.payload) {
                    data = payloadMapper ? payloadMapper(action.payload) : action.payload;
                }
                return {
                    ...state,
                    isFetching: false,
                    data,
                };
            case errorKey:
                return {
                    ...state,
                    isFetching: false,
                    data: clearOnFail ? undefined : state.data,
                    error: action.payload,
                };
            case LOGGED_OUT: {
                return initialState;
            }
            default:
                return state;
        }
    };
};
// Fetch helper code
/**
 * @deprecated
 */
export const useFetchReduxed = (name, debug) => {
    const state = useReSelector((store) => store[name]);
    const dispatch = useDispatch();
    const fetchCall = useCallback((apiCall) => {
        if (!state.isFetching) {
            const [, success] = apiCall[CALL_API].types;
            if (debug)
                fetch.debugCall(apiCall[CALL_API]);
            dispatch(apiCall).then((answer) => {
                if (debug)
                    if (answer.type === success) {
                        fetch.debugAnswer(answer);
                    }
                    else {
                        fetch.debugError(answer);
                    }
            });
        }
    }, [state.isFetching, debug, dispatch]);
    return [state.data, state.error, state.isFetching, fetchCall];
};
export default useFetchReduxed;
