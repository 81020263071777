import React, { useCallback } from 'react';
import { Calendar } from 'design-system/calendar/Calendar';
import { Day } from 'design-system/calendar/Day';
import { useDateRange } from 'design-system/calendar/useRange';
import moment from 'moment';
export const CalendarRangeFormat = 'YYYY-MM-DD';
export const CalendarRange = React.memo(({ showReset, selectWeek, maxDate, onChangeRange: onChangeRangeProp, initialDates = [], ...props }) => {
    const { checkInRange, onAddDayToRange, checkIsEndDay, checkIsStartDay, resetRange, selectEntireWeek, } = useDateRange(initialDates, props.firstDay);
    const onDayPress = useCallback((date) => {
        const newRange = selectWeek
            ? selectEntireWeek(date.dateString)
            : onAddDayToRange(date.dateString);
        onChangeRangeProp?.(newRange);
    }, [onAddDayToRange, onChangeRangeProp, selectEntireWeek, selectWeek]);
    const handleReset = useCallback(() => {
        resetRange();
        onChangeRangeProp?.([]);
    }, [onChangeRangeProp, resetRange]);
    const checkIfDayDisabled = useCallback((date) => {
        if (!maxDate || !date) {
            return false;
        }
        return moment(date).isAfter(moment(maxDate), 'day');
    }, [maxDate]);
    return (<Calendar maxDate={maxDate} dayComponent={({ date, state }) => (<Day key={date?.dateString} date={date} isDisabled={checkIfDayDisabled(date?.dateString)} isToday={state === 'today'} isStartDay={checkIsStartDay(date?.dateString)} isEndDay={checkIsEndDay(date?.dateString)} isInRange={checkInRange(date?.dateString)} onDayPress={onDayPress}/>)} reset={showReset ? handleReset : undefined} {...props}/>);
});
