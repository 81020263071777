import { REHYDRATE } from 'redux-persist/constants';
import { LOGGED_OUT, REGISTER_USER_SUCCESS, SIGN_IN_SUCCESS, } from 'actions/types';
import { CONNECTING_SECOND_LOGIN, SET_SHOULD_SHOW_FAKE_LOADING_SCREEN, SET_SIGN_IN_SPACE, SKIPPED_PREMIUM_SCREEN, SKIPPED_PREMIUM_SCREEN_SECOND, SKIPPED_PUSH_NOTIFICATIONS, SKIPPED_SECOND_LOGIN, SKIPPED_SET_BUDGETS, UPGRADED_TO_PREMIUM, SET_SIGN_IN_NAVIGATION_KEY, VIEWED_PREMIUM_WELCOME_SCREEN, } from '../actions/types';
const initialState = {
    premiumScreenSkipped: false,
    premiumScreenSkippedSecond: false,
    pushNotificationsSkipped: false,
    secondLoginSkipped: false,
    connectingSecondLogin: false,
    setupBudgetSkipped: false,
    isSigningIn: false,
    viewedPremiumWelcomeScreen: false,
    hasUpgradedToPremium: false,
    showFakeLoadingScreen: false,
    space: undefined,
    didPostMaritalStatus: false,
    didPostDependantNumber: false,
    didPostEmploymentStatus: false,
    didPostGrossAnnualSalary: false,
};
const signInNavigation = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE: {
            if (action.payload.signInNavigation) {
                return {
                    ...action.payload.signInNavigation,
                    connectingSecondLogin: false,
                    showFakeLoadingScreen: false,
                };
            }
            return {
                ...state,
                connectingSecondLogin: false,
                showFakeLoadingScreen: false,
            };
        }
        case SET_SHOULD_SHOW_FAKE_LOADING_SCREEN: {
            return {
                ...state,
                showFakeLoadingScreen: action.showFakeLoadingScreen,
            };
        }
        case SKIPPED_PREMIUM_SCREEN: {
            return {
                ...state,
                premiumScreenSkipped: true,
            };
        }
        case SKIPPED_PREMIUM_SCREEN_SECOND: {
            return {
                ...state,
                premiumScreenSkippedSecond: true,
            };
        }
        case SKIPPED_PUSH_NOTIFICATIONS: {
            return {
                ...state,
                pushNotificationsSkipped: true,
            };
        }
        case SKIPPED_SECOND_LOGIN: {
            return {
                ...state,
                secondLoginSkipped: true,
            };
        }
        case CONNECTING_SECOND_LOGIN: {
            return {
                ...state,
                connectingSecondLogin: true,
            };
        }
        case SKIPPED_SET_BUDGETS: {
            return {
                ...state,
                setupBudgetSkipped: true,
            };
        }
        case SIGN_IN_SUCCESS: {
            return {
                ...state,
                isSigningIn: true,
            };
        }
        case REGISTER_USER_SUCCESS: {
            return {
                ...state,
                isSigningIn: false,
            };
        }
        case VIEWED_PREMIUM_WELCOME_SCREEN: {
            return {
                ...state,
                viewedPremiumWelcomeScreen: true,
            };
        }
        case UPGRADED_TO_PREMIUM: {
            return {
                ...state,
                hasUpgradedToPremium: true,
            };
        }
        case SET_SIGN_IN_SPACE:
            return {
                ...state,
                space: action.payload,
            };
        case SET_SIGN_IN_NAVIGATION_KEY:
            if (action.key && action.value !== undefined) {
                return {
                    ...state,
                    [action.key]: action.value,
                };
            }
            return state;
        case LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};
export default signInNavigation;
