export const QUESTS_GROUPS = [
    'GETTING_STARTED',
    'TRANSACTION_NINJA',
    'MASTER_ACCOUNTS',
    'LIKE_A_PRO',
    'SAVE_MONEY',
];
export const QUESTS = [
    'first_time.set_payday',
    'first_time.set_budgets',
    'first_time.total_budget',
    'first_time.check_subscriptions',
    'social.invite_1_friend',
    'first_time.add_profile_pic',
    'first_time.edit_category',
    'first_time.add_note',
    'first_time.add_tag',
    'first_time.upload_receipt',
    'first_time.rename_account',
    'first_time.search_trxs',
    'first_time.check_bank_fees',
    'enable.daily_balances',
    'first_time.use_scramble',
    'social.invite_2_friends',
    'social.invite_3_friends',
    'pro.complete_month_pro',
    'streak.10_days',
    'streak.20_days',
    'streak.50_days',
    'rewards.earn_reward',
    'social.rate_emma',
    'social.facebook_share',
    'first_time.switch_energy',
    'first_time.get_broadband_quotes',
    'first_time.open_net_worth',
    'first_time.submit_logo',
    'first_time.split_transaction',
    'first_time.rename_transaction',
    'first_time.change_transaction_date',
    'first_time.play_quiz',
    'first_time.check_report',
    'first_time.setup_fraud_detect',
];
