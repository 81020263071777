import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { View } from 'react-native';
import Animated from 'react-native-reanimated';
import StatusBar from 'design-system/StatusBar';
import { AppView } from 'design-system/AppView';
import Indicator from 'design-system/Indicator';
import { IconEdit } from 'design-system/icons/IconEdit';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import NavigationHeaderRightIconButton from 'design-system/NavigationHeaderRightIconButton';
import useColors from 'hooks/useColors';
import { setBudgetingPosition } from 'actions';
import usePrivateRoute from 'hooks/usePrivateRoute';
import { useMarginBottom } from 'hooks/useMarginBottom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectCurrentPaydayRange } from 'reducers/selectors';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { isNotLoadedState } from 'reducers/types';
import PeriodControl from 'features/analytics/components/PeriodControl';
import { useOpenCategory } from 'features/analytics/hooks/useOpenCategory';
import AnalyticsControls from 'features/analytics/components/AnalyticsControls';
import { useSyncMonthlyTotals } from 'features/analytics/hooks/useSyncMonthlyTotals';
import { AnalyticsCarousel, } from 'features/analytics/components/AnalyticsCarousel.web';
import SetupBudgetView from '../components/SetupBudgetView';
import { selectBudgetedCategories, selectCategoriesLoadingState, selectMerchantsLoadingState, selectMonthlyTotalsWithBudgets, } from '../selectors';
import AmendYourBudgetsHelp from '../components/AmendYourBudgetsHelp';
import { useLoadBudgetsAndIncomes } from '../hooks/useLoadBudgetsAndIncomes';
import { DailyAllowanceView } from '../components/DailyAllowanceView';
import BudgetingCategoryList from '../components/BudgetingCategoryList';
import BudgetingMerchantsList from '../components/BudgetingMerchantsList';
import BudgetingCarouselItem from '../components/BudgetingCarouselItem';
import useManageBudgetingModal from '../hooks/useManageBudgetingModal';
import { isWeb } from '../../../constants';
const BudgetingScreen = withAnimatedNavigationHeader(({ scrollHandler, navigation }) => {
    usePrivateRoute();
    const filteredTotals = useAppSelector(selectMonthlyTotalsWithBudgets);
    const navRightBtnRef = useRef(null);
    const [showManageBudgetingModal, isBudgetFetching] = useManageBudgetingModal(navRightBtnRef);
    const onPressManageCategories = useCallback((setup) => {
        if (setup) {
            navigation.navigate('EditBudget', { flow: 'Edit' });
            return;
        }
        showManageBudgetingModal();
    }, [showManageBudgetingModal]);
    const colors = useColors();
    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (<View ref={navRightBtnRef}>
            <NavigationHeaderRightIconButton onPress={() => onPressManageCategories()}>
              <IconEdit color={colors.buttons.primary}/>
            </NavigationHeaderRightIconButton>
          </View>),
        });
    }, [colors.buttons.primary, onPressManageCategories]);
    const categories = useAppSelector(selectBudgetedCategories);
    const dispatch = useAppDispatch();
    const position = useAppSelector((store) => store.expenses.budgetingPosition);
    const currentPaydayRange = useAppSelector(selectCurrentPaydayRange);
    const categoriesLoadingState = useAppSelector(selectCategoriesLoadingState);
    const merchantsLoadingState = useAppSelector(selectMerchantsLoadingState);
    useLoadBudgetsAndIncomes();
    useEffect(() => () => {
        // reset position
        dispatch(setBudgetingPosition(0));
    }, []);
    const renderCarouselItem = useCallback(({ item }) => (<View>
          <BudgetingCarouselItem item={item}/>
          <PeriodControl type="budgeting" maxDataIndex={filteredTotals.length - 1}/>
        </View>), [filteredTotals.length]);
    const openCategory = useOpenCategory(filteredTotals, position, 'MonthlyCategory');
    const [onEndReached, fetching] = useSyncMonthlyTotals(position);
    const openMerchant = useCallback((merchant) => {
        openCategory({ type: 'merchant', data: merchant });
    }, [openCategory]);
    const openSelectMerchant = useCallback(() => {
        navigation.navigate('SelectMerchant');
    }, []);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const paddingBottom = useMarginBottom('paddingBottom');
    if (isBudgetFetching) {
        return (<AppView>
          <StatusBar barStyle="dark-content" animated/>
          <Indicator dark includeHeaderHeight/>
        </AppView>);
    }
    return (<AppView withFrame={false}>
        <StatusBar barStyle="dark-content" animated/>
        <Animated.ScrollView scrollEventThrottle={8} onScroll={scrollHandler} contentContainerStyle={paddingBottom}>
          {filteredTotals?.length ||
            fetching.totals ||
            isNotLoadedState(categoriesLoadingState) ||
            isNotLoadedState(merchantsLoadingState) ? (<View style={paddingHorizontalStyle}>
              <AnalyticsControls month={filteredTotals[position]} payPeriod={currentPaydayRange}/>
              <AnalyticsCarousel type="budgeting" data={filteredTotals} onEndReached={onEndReached} renderItem={renderCarouselItem}/>
              <DailyAllowanceView isCurrentPeriod={position === 0} currentMonthlyTotal={filteredTotals[position]}/>

              <BudgetingCategoryList isCurrentPeriod={position === 0} onPressCategory={openCategory} onPressManageCategories={onPressManageCategories}/>

              <BudgetingMerchantsList noMarginBottom onPressMerchant={openMerchant} onPressAdd={openSelectMerchant} isCurrentPeriod={position === 0} noMarginTop={!!categories?.length}/>

              <AmendYourBudgetsHelp />
            </View>) : (<View style={paddingHorizontalStyle}>
              <SetupBudgetView />
            </View>)}
        </Animated.ScrollView>
      </AppView>);
}, ({ colors }) => ({
    title: 'Budgeting',
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
export default BudgetingScreen;
