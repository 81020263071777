import React, { memo } from 'react';
import Squircle from 'design-system/Squircle';
import { Colors } from 'design-system/values';
import TransactionLogo from 'features/transactions/components/TransactionLogo';
import AnalyticsRow from './AnalyticsRow';
import IconOtherMerchant from '../../icons/IconOtherMerchant';
const RowMerchant = ({ merchant, blurValue, onPress }) => {
    const press = () => {
        onPress({ type: 'merchant', data: merchant });
    };
    return (<AnalyticsRow displayName={merchant.displayName} total={merchant.total} count={merchant.transactionsCount} currency={merchant.currency} onPress={press} icon={merchant.id === -1 ? (<Squircle color={Colors.primary}>
            <IconOtherMerchant />
          </Squircle>) : (<TransactionLogo R44 transaction={{ category: merchant.category, merchant }}/>)} blurValue={blurValue}/>);
};
export default memo(RowMerchant);
