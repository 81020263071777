import React from 'react';
import Button from 'design-system/Button';
import Spacer from 'design-system/Spacer';
import Text from 'design-system/Text';
const ToolTipContent = ({ subtitle, onPress, learnMore, onPressLearnMore, }) => (<>
    <Spacer h={8}/>
    <Text TextThin-14 Gray centered>
      {subtitle}
      {learnMore && (<>
          {' '}
          <Text Text-14 Primary suppressHighlighting onPress={onPressLearnMore}>
            Learn more
          </Text>
        </>)}
    </Text>
    <Spacer h={40}/>
    <Button brand title="OK" onPress={onPress}/>
  </>);
export default ToolTipContent;
