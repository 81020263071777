import React from 'react';
import * as SVG from 'react-native-svg';
import { rem } from 'design-system/values';
const IconError = () => {
    const width = rem(67);
    const height = rem(66);
    return (<SVG.Svg width={width} height={height} viewBox="0 0 67 66" fill="none">
      <SVG.Path d="M33.5 66C51.7254 66 66.5 51.2254 66.5 33C66.5 14.7746 51.7254 0 33.5 0C15.2746 0 0.5 14.7746 0.5 33C0.5 51.2254 15.2746 66 33.5 66Z" fill="url(#paint0_linear)"/>
      <SVG.G>
        <SVG.Path d="M19.4504 47.7029L39.7079 66C53.672 62.9032 66.4999 51.0155 66.4999 38.3703C66.4999 38.1122 66.4999 37.8542 66.4999 37.5961L46.5693 20.9109L19.4504 47.7029Z" fill="black" fillOpacity="0.3"/>
      </SVG.G>
      <SVG.Path d="M39.3938 17.401C40.8525 15.9422 43.3531 15.9422 44.8119 17.401L47.8335 20.4226C49.2922 21.8813 49.2922 24.3819 47.8335 25.8406L24.9109 48.659C23.4522 50.1177 20.9515 50.1177 19.4928 48.659L16.4712 45.6374C15.0125 44.1787 15.0125 41.678 16.4712 40.2193L39.3938 17.401Z" fill="white"/>
      <SVG.Path d="M47.8818 40.2707C49.3405 41.7294 49.3405 44.2301 47.8818 45.6888L44.8602 48.7104C43.4015 50.1691 40.9008 50.1691 39.4421 48.7104L16.6238 25.7878C15.165 24.3291 15.165 21.8285 16.6238 20.3698L19.6454 17.3482C21.1041 15.8894 23.6047 15.8894 25.0634 17.3482L47.8818 40.2707Z" fill="white"/>
      <SVG.Defs>
        <SVG.LinearGradient id="paint0_linear" x1="47.5058" y1="51.6353" x2="-8.45599" y2="30.8284" gradientUnits="userSpaceOnUse">
          <SVG.Stop stopColor="#9C39FF"/>
          <SVG.Stop offset="1" stopColor="#8103FF"/>
        </SVG.LinearGradient>
      </SVG.Defs>
    </SVG.Svg>);
};
export default IconError;
