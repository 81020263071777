import React, { memo, useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import { Keyboard, Pressable, View } from 'react-native';
import getSymbolFromCurrency from 'currency-symbol-map';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import { CardView } from 'design-system/CardView';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import Modal from 'utils/packages/Modal';
import { muteAll, useFetchOld } from 'hooks';
import RNMaskedText from 'utils/packages/rnMaskedText';
import createStyleSheets from 'utils/createStyleSheets';
import { editSubscription } from 'features/subscriptions/actions';
import { FREQUENCY } from 'features/subscriptions/utils/frequency';
import { getRangeString } from 'features/subscriptions/screens/AdjustSubscriptionScreen';
import { isWeb } from '../../../../constants';
import SubscriptionLogo from '../SubscriptionLogo';
import SelectDateRangeModalContent from '../modal/SelectDateRangeModalContent';
import SubscriptionFrequencyModalContent from '../modal/SubscriptionFrequencyModalContent';
const bottomLineHitslop = {
    top: 0,
    bottom: rem(16),
    left: rem(16),
    right: rem(16),
};
const textInputHitSlop = {
    top: rem(16),
    bottom: rem(8),
    left: rem(16),
    right: rem(16),
};
const SubscriptionHeader = ({ id, iconUrl, currency, minAmount, maxAmount, minMonthDay, maxMonthDay, paymentFrequency, }) => {
    const isMounted = useRef(false);
    const colors = useColors();
    const styles = useStyles(styleSet);
    const [, , , fetch] = useFetchOld(muteAll);
    const [, , , fetchAmounts] = useFetchOld({
        throws: true,
    });
    const [focused, setFocused] = useState();
    const [state, setState] = useState(() => ({
        firstValue: minAmount,
        secondValue: maxAmount,
    }));
    useEffect(() => {
        if (isMounted.current) {
            setState({
                firstValue: minAmount,
                secondValue: maxAmount,
            });
        }
        else {
            isMounted.current = true;
        }
    }, [maxAmount, minAmount]);
    const timePeriod = useMemo(() => {
        if (!id) {
            return '';
        }
        return getRangeString(minMonthDay || 0, maxMonthDay || 0);
    }, [id, maxMonthDay, minMonthDay]);
    const openFrequencySelectorModal = useCallback(() => {
        Keyboard.dismiss();
        if (!id)
            return;
        const select = (frequency) => {
            if (frequency !== paymentFrequency) {
                const body = {
                    userPaymentFrequency: frequency,
                };
                fetch(editSubscription(id, body));
            }
            Modal.hide();
        };
        Modal.show(<SubscriptionFrequencyModalContent paymentFrequency={paymentFrequency || undefined} onSelect={select}/>, { increasedTopPadding: true, title: isWeb ? 'Frequency' : undefined });
    }, [id, paymentFrequency]);
    const openCalendar = useCallback(() => {
        Keyboard.dismiss();
        if (!id) {
            return;
        }
        const onFinish = ({ start, end }) => {
            if (start !== minMonthDay && start !== maxMonthDay) {
                const body = {
                    maxMonthDay: end,
                    minMonthDay: start,
                };
                fetch(editSubscription(id, body));
            }
            Modal.hide();
        };
        Modal.show(<SelectDateRangeModalContent data={{
                start: minMonthDay || undefined,
                end: maxMonthDay || undefined,
            }} onFinish={onFinish}/>, { title: isWeb ? 'Select a range' : undefined });
    }, [id, maxMonthDay, minMonthDay]);
    const onFocus = useCallback((key) => () => {
        setFocused(key);
    }, []);
    const onBlur = useCallback((key) => async () => {
        if (focused === key) {
            setFocused(undefined);
        }
        if (id) {
            switch (key) {
                case 'from': {
                    if (state.firstValue !== minAmount) {
                        try {
                            await fetchAmounts(editSubscription(id, {
                                minAmount: state.firstValue,
                            }));
                        }
                        catch (_) {
                            setState((oldState) => ({
                                ...oldState,
                                firstValue: minAmount,
                            }));
                        }
                    }
                    break;
                }
                case 'to': {
                    if (state.secondValue !== maxAmount) {
                        try {
                            await fetchAmounts(editSubscription(id, { maxAmount: state.secondValue }));
                        }
                        catch (_) {
                            setState((oldState) => ({
                                ...oldState,
                                firstValue: maxAmount,
                            }));
                        }
                    }
                    break;
                }
                default:
                    break;
            }
        }
    }, [
        focused,
        id,
        state.firstValue,
        state.secondValue,
        minAmount,
        fetchAmounts,
        maxAmount,
    ]);
    const onChangeFirstValue = useCallback((_, rawData) => {
        if (rawData !== undefined) {
            setState((oldState) => ({
                ...oldState,
                firstValue: String(rawData),
            }));
        }
    }, []);
    const onChangeSecondValue = useCallback((_, rawData) => {
        if (rawData !== undefined) {
            setState((oldState) => ({ ...oldState, secondValue: String(rawData) }));
        }
    }, []);
    const textInputOptions = useMemo(() => {
        if (!currency)
            return {
                precision: 0,
                separator: '.',
                delimiter: ',',
                unit: getSymbolFromCurrency('GBP'),
            };
        return {
            precision: 0,
            separator: '.',
            delimiter: ',',
            unit: getSymbolFromCurrency(currency),
        };
    }, [currency]);
    if (!id) {
        return null;
    }
    return (<>
      <View style={styles.logoBox}>
        <SubscriptionLogo R44 url={iconUrl}/>
      </View>
      <Spacer.H16 />
      <CardView withPadding noMarginBottom>
        <Text Secondary TextThin-16 style={styles.text} centered>
          {'Only includes payments from '}
        </Text>
        <View style={styles.row}>
          <RNMaskedText type="money" blurOnSubmit maxLength={7} hitSlop={textInputHitSlop} returnKeyType="done" keyboardType="number-pad" onBlur={onBlur('from')} value={state.firstValue} onFocus={onFocus('from')} options={textInputOptions} includeRawValueInChangeText onChangeText={onChangeFirstValue} underlineColorAndroid="transparent" selectionColor={colors.text.selection} style={[
            styles.input,
            focused && focused === 'from' ? styles.focused : {},
        ]}/>
          <Text Secondary TextThin-16 style={styles.text}>
            {' to '}
          </Text>
          <RNMaskedText type="money" maxLength={7} blurOnSubmit hitSlop={textInputHitSlop} returnKeyType="done" onBlur={onBlur('to')} keyboardType="number-pad" onFocus={onFocus('to')} value={state.secondValue} options={textInputOptions} includeRawValueInChangeText underlineColorAndroid="transparent" onChangeText={onChangeSecondValue} selectionColor={colors.text.selection} style={[
            styles.input,
            focused && focused === 'to' ? styles.focused : {},
        ]}/>
          {paymentFrequency === 30 && (<Text Secondary TextThin-16 centered>
              {' between '}
            </Text>)}
        </View>
        <View style={styles.lowerTextContainer}>
          {paymentFrequency === 30 && (<Pressable onPress={openCalendar} hitSlop={bottomLineHitslop}>
              <Text Text-16 Secondary underline>
                {timePeriod}
              </Text>
            </Pressable>)}
          <Text Secondary TextThin-16>
            {' with a '}
          </Text>
          <Pressable onPress={openFrequencySelectorModal} hitSlop={bottomLineHitslop}>
            <Text Text-16 Secondary underline>
              {paymentFrequency
            ? `${FREQUENCY[paymentFrequency].toLowerCase()} frequency`
            : '--'}
            </Text>
          </Pressable>
          <Text Secondary TextThin-16>
            .
          </Text>
        </View>
      </CardView>
    </>);
};
const styleSet = createStyleSheets((colors) => ({
    logoBox: {
        alignItems: 'center',
    },
    text: {
        textAlign: 'center',
    },
    row: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    input: {
        fontSize: rem(16),
        textAlign: 'center',
        paddingHorizontal: rem(4),
        color: colors.text.secondary,
        fontFamily: 'Montserrat-Regular',
        textDecorationColor: colors.text.secondary,
        ...(isWeb
            ? {
                width: 100,
            }
            : {
                textDecorationLine: 'underline',
            }),
    },
    focused: {
        color: colors.text.brand,
        textDecorationColor: colors.text.brand,
    },
    lowerTextContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
}));
export default memo(SubscriptionHeader);
