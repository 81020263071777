import React, { memo, useMemo, useState, useEffect, useCallback } from 'react';
import { FlatList } from 'react-native';
import { selectAllSavingAccounts, selectAllCurrentAccounts, selectDefaultZeroAmount, selectAllInvestmentWithCryptoAccounts, } from 'reducers/selectors';
import { delay } from 'utils/delay';
import useColors from 'hooks/useColors';
import { useAppSelector } from 'store/hooks';
import i18n from '../../../../i18n';
import { sections } from '../../constants';
import NetworthSpacingView from './NetworthSpacingView';
import { overviewColorMap } from '../../hooks/useBalances';
import AccountsSectionCollapsible from './AccountSectionCollapsible';
function filterPositiveAccounts(data) {
    return Object.keys(data).reduce((acc, key) => {
        if (data[key].positiveAccounts.length > 0) {
            acc[key] =
                data[key];
        }
        return acc;
    }, {});
}
const NetWorthAssetsSection = ({ accounts, onRowPress, onOpenLogins, onAddAccount, openAlternative, onOpenUpgrade, onRowLongPress, initialTitle, isUnlockedForSpace, }) => {
    const [sectionShown, setSectionShown] = useState(initialTitle ? initialTitle === 'Net worth' : true);
    const filteredPositiveData = filterPositiveAccounts(accounts);
    useEffect(() => {
        if (!sectionShown) {
            delay(() => {
                setSectionShown(true);
            }, 400);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const zero = useAppSelector(selectDefaultZeroAmount);
    const allSavingsAccounts = useAppSelector(selectAllSavingAccounts);
    const allCurrentAccounts = useAppSelector(selectAllCurrentAccounts);
    const allInvestmentAccounts = useAppSelector(selectAllInvestmentWithCryptoAccounts);
    const colors = useColors();
    const data = useMemo(() => {
        if (!accounts || !sectionShown)
            return [];
        const baseData = [
            {
                firstCTA: `+ Add a ${i18n.t('current')} Account`,
                title: i18n.t('current'),
                data: allCurrentAccounts.positiveAccounts,
                total: {
                    amount: allCurrentAccounts.positiveTotal,
                    currency: allCurrentAccounts.currency,
                },
                color: colors.text[overviewColorMap.Checking],
                onRowPress,
                onRowLongPress,
                onOpenLogins,
                onOpenUpgrade,
                onAddAccount,
            },
            {
                firstCTA: '+ Add Account',
                secondCTA: 'open pot',
                title: sections.savings,
                type: 'SAVINGS',
                data: allSavingsAccounts.positiveAccounts,
                total: {
                    amount: allSavingsAccounts.positiveTotal,
                    currency: allSavingsAccounts.currency,
                },
                color: colors.text[overviewColorMap.Savings],
                onRowPress,
                onRowLongPress,
                onOpenLogins,
                onOpenUpgrade,
                onAddAccount,
                openAlternative: allSavingsAccounts.hasPots
                    ? undefined
                    : openAlternative,
            },
            {
                firstCTA: '+ Add investment',
                secondCTA: 'start in Emma',
                title: sections.investments,
                data: allInvestmentAccounts.positiveAccounts,
                total: {
                    amount: allInvestmentAccounts.positiveTotal,
                    currency: allInvestmentAccounts.currency,
                },
                color: colors.text[overviewColorMap.Investments],
                type: 'INVESTMENT',
                onRowPress,
                onRowLongPress,
                onOpenLogins,
                onOpenUpgrade,
                onAddAccount,
                openAlternative,
            },
            {
                firstCTA: '+ Add a pension',
                title: sections.pensions,
                data: accounts['PENSION'].positiveAccounts,
                total: {
                    amount: accounts['PENSION'].positiveTotal,
                    currency: accounts['PENSION'].array[0]?.currency ?? zero.currency,
                },
                color: colors.text[overviewColorMap.Pensions],
                type: 'PENSION',
                onRowPress,
                onRowLongPress,
                onOpenLogins,
                onOpenUpgrade,
                onAddAccount,
            },
            {
                firstCTA: '+ Add real estate',
                title: sections.realEstate,
                data: accounts['REAL_ESTATE'].positiveAccounts,
                total: {
                    amount: accounts['REAL_ESTATE'].positiveTotal,
                    currency: accounts['REAL_ESTATE'].array[0]?.currency ?? zero.currency,
                },
                color: colors.text.income,
                type: 'REAL_ESTATE',
                onRowPress,
                onRowLongPress,
                onOpenLogins,
                onOpenUpgrade,
                onAddAccount,
            },
            {
                firstCTA: '+ Add a vehicle',
                title: sections.vehicles,
                data: accounts['VEHICLE'].positiveAccounts,
                total: {
                    amount: accounts['VEHICLE'].positiveTotal,
                    currency: accounts['VEHICLE'].array[0]?.currency ?? zero.currency,
                },
                color: colors.text.income,
                type: 'VEHICLE',
                onRowPress,
                onRowLongPress,
                onOpenLogins,
                onOpenUpgrade,
                onAddAccount,
            },
            {
                firstCTA: '+ Add other assets',
                title: sections.otherAssets,
                data: accounts['OTHER'].positiveAccounts,
                total: {
                    amount: accounts['OTHER'].positiveTotal,
                    currency: accounts['OTHER'].array[0]?.currency ?? zero.currency,
                },
                color: colors.text.income,
                type: 'OTHER',
                onRowPress,
                onRowLongPress,
                onOpenLogins,
                onOpenUpgrade,
                onAddAccount,
            },
            ...('LOAN' in filteredPositiveData
                ? [
                    {
                        title: sections.loans,
                        data: accounts['LOAN'].positiveAccounts,
                        total: {
                            amount: accounts['LOAN'].positiveTotal,
                            currency: accounts['LOAN'].array[0]?.currency ?? zero.currency,
                        },
                        color: colors.text[overviewColorMap.Investments],
                        onRowPress,
                        onRowLongPress,
                        onOpenLogins,
                        onOpenUpgrade,
                        onAddAccount,
                    },
                ]
                : []),
            ...('CREDITCARD' in filteredPositiveData
                ? [
                    {
                        title: sections.creditCards,
                        data: accounts['CREDITCARD'].positiveAccounts,
                        total: {
                            amount: accounts['CREDITCARD'].positiveTotal,
                            currency: accounts['CREDITCARD'].array[0]?.currency ?? zero.currency,
                        },
                        color: colors.text[overviewColorMap.Investments],
                        onRowPress,
                        onRowLongPress,
                        onOpenLogins,
                        onOpenUpgrade,
                        onAddAccount,
                    },
                ]
                : []),
            ...('HIDDEN' in filteredPositiveData
                ? [
                    {
                        title: sections.hidden,
                        data: accounts['HIDDEN'].positiveAccounts,
                        onRowPress,
                        onRowLongPress,
                        onOpenLogins,
                        onOpenUpgrade,
                        onAddAccount,
                    },
                ]
                : []),
            ...('CLOSED' in filteredPositiveData
                ? [
                    {
                        title: sections.closed,
                        data: accounts['CLOSED'].positiveAccounts,
                        onRowPress,
                        onRowLongPress,
                        onOpenLogins,
                        onOpenUpgrade,
                        onAddAccount,
                    },
                ]
                : []),
        ];
        return baseData;
    }, [
        accounts,
        allCurrentAccounts.currency,
        allCurrentAccounts.positiveAccounts,
        allCurrentAccounts.positiveTotal,
        allInvestmentAccounts.currency,
        allInvestmentAccounts.positiveAccounts,
        allInvestmentAccounts.positiveTotal,
        allSavingsAccounts.currency,
        allSavingsAccounts.hasPots,
        allSavingsAccounts.positiveAccounts,
        allSavingsAccounts.positiveTotal,
        colors.text,
        filteredPositiveData,
        onAddAccount,
        onOpenLogins,
        onOpenUpgrade,
        onRowLongPress,
        onRowPress,
        openAlternative,
        sectionShown,
        zero,
    ]);
    const renderItem = useCallback(({ item }) => (<AccountsSectionCollapsible {...item} blurAmount={!isUnlockedForSpace}/>), [isUnlockedForSpace]);
    const ListHeader = useCallback(() => <NetworthSpacingView />, []);
    return (<FlatList data={data} scrollEnabled={false} initialNumToRender={5} renderItem={renderItem} ListHeaderComponent={ListHeader}/>);
};
export default memo(NetWorthAssetsSection);
