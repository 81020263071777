import React, { memo, useCallback } from 'react';
import { View, StyleSheet, LayoutAnimation } from 'react-native';
import moment from 'moment';
import FastImageCommon from 'common/FastImageCommon';
import { Spacer, rem } from 'design-system';
import Text from 'design-system/Text';
import Amount from 'design-system/Amount';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import useColors from 'hooks/useColors';
import { IconBoldSubtract } from 'design-system/icons/IconBoldSubtract';
import PriceChange from '../row/PriceChange';
import { isWeb } from '../../../../constants';
const RowSubscriptionTransaction = ({ data, accounts, onPress, isEditing, addTxnToDelete, }) => {
    const colors = useColors();
    const onRowPressed = useCallback(() => {
        if (isEditing) {
            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
            addTxnToDelete(data.id);
        }
        else {
            onPress(data);
        }
    }, [addTxnToDelete, data, isEditing, onPress]);
    const renderAccount = useCallback(() => {
        let account = null;
        if (accounts)
            account = accounts.find((item) => item.id === data.accountId);
        const accountNumber = account
            ? account.accountNumber?.substring(account.accountNumber.length - 4)
            : null;
        let imageUrl = account ? account.iconUrl : null;
        if (account && account.iconBase64)
            imageUrl = `data:image/png;base64,${account.iconBase64}`;
        return (<View style={styles.accountRow}>
        <FastImageCommon url={imageUrl} imageStyle={styles.accountIcon}/>
        <Text NumbersThin-14 Gray>
          {accountNumber}
        </Text>
      </View>);
    }, [accounts, data.accountId]);
    const { priceChange, amount, currency, nativeAmount } = data;
    let amountToDisplay = amount;
    let currencyToDisplay = currency;
    if (nativeAmount) {
        amountToDisplay = nativeAmount.amount;
        currencyToDisplay = nativeAmount.currency;
    }
    const date = moment(data.customDate || data.bookingDate);
    let bookingDate = date.format('D MMMM YYYY');
    if (moment().year() === date.year())
        bookingDate = date.format('MMMM D');
    return (<TouchableHighlight onCard hoverOnLight activeOpacity={1} onPress={onRowPressed} underlayColor={colors.background.underlay}>
      <View style={styles.rowContainer}>
        {isEditing && (<>
            <IconBoldSubtract />
            <Spacer w={16}/>
          </>)}
        <View style={styles.leftContainer}>
          <Text Text-16 numberOfLines={1} ellipsizeMode="tail">
            {bookingDate}
          </Text>
          {priceChange && priceChange !== 0 ? (<PriceChange currency={currencyToDisplay} priceChange={priceChange}/>) : null}
        </View>
        <View style={styles.rightContainer}>
          <Amount Numbers-16 value={{ value: amountToDisplay, currency: currencyToDisplay }}/>
          {renderAccount()}
        </View>
      </View>
    </TouchableHighlight>);
};
export const ROW_SUB_TRANSACTION_HEIGHT = rem(77);
const styles = StyleSheet.create({
    rowContainer: {
        padding: rem(16),
        flexDirection: 'row',
        alignItems: 'center',
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    leftContainer: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    rightContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    accountRow: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    accountIcon: {
        width: rem(16),
        height: rem(16),
        marginEnd: rem(4),
        borderRadius: rem(8),
    },
});
export default memo(RowSubscriptionTransaction);
