import { FinancialsPeriod } from '../types';
export const filterPortfolioByTotalValue = (symbols, positionsCache) => {
    filterDataByKey(symbols, positionsCache, 'amount');
};
export const filterPortfolioByGainLoss = (symbols, positionsCache) => {
    filterDataByKey(symbols, positionsCache, 'unrealizedProfitLoss');
};
export const filterPortfolioByReturnPercentage = (symbols, positionsCache) => {
    filterDataByKey(symbols, positionsCache, 'unrealizedProfitLossPct');
};
export const filterDataByKey = (symbols, positionsCache, key) => {
    symbols.sort((b, a) => {
        const amountA = typeof positionsCache[a]?.[key] === 'number'
            ? positionsCache[a]?.[key]
            : positionsCache[a]?.[key]?.value;
        const amountB = typeof positionsCache[b]?.[key] === 'number'
            ? positionsCache[b]?.[key]
            : positionsCache[b]?.[key]?.value;
        if (amountA !== undefined && amountB !== undefined) {
            return amountA - amountB;
        }
        if (amountA !== undefined) {
            return 1;
        }
        if (amountB !== undefined) {
            return -1;
        }
        return 0;
    });
};
const SI_SYMBOL = ['', 'k', 'M', 'B', 'T', 'P', 'E'];
export const abbreviateNumber = (number) => {
    if (!number)
        return '0.0';
    // what tier? (determines SI symbol)
    const tier = Math.floor(Math.log10(Math.abs(number)) / 3) || 0;
    // if zero, we don't need a suffix
    if (tier === 0)
        return number.toFixed(2).slice(0, -1);
    // get suffix and determine scale
    const suffix = SI_SYMBOL[tier];
    const scale = 10 ** (tier * 3);
    // scale the number
    const scaled = number / scale;
    // format number and add suffix
    return scaled.toFixed(2).slice(0, -1) + suffix;
};
export const abbreviateNumberWorklet = (number) => {
    'worklet';
    if (!number)
        return '0.0';
    // what tier? (determines SI symbol)
    const tier = Math.floor(Math.log10(Math.abs(number)) / 3) || 0;
    // if zero, we don't need a suffix
    if (tier === 0)
        return number.toFixed(2).slice(0, -1);
    // get suffix and determine scale
    const suffix = SI_SYMBOL[tier];
    const scale = 10 ** (tier * 3);
    // scale the number
    const scaled = number / scale;
    // format number and add suffix
    return scaled.toFixed(2).slice(0, -1) + suffix;
};
export const abbreviateNumberWithSymbol = (number, currencySymbol = '') => {
    if (!number)
        return `${currencySymbol}0.0`;
    const sign = number < 0 ? '-' : '';
    return sign + currencySymbol + abbreviateNumber(Math.abs(number));
};
export const getPeriodKey = (selection) => {
    switch (selection) {
        case FinancialsPeriod.ANNUAL:
            return 'annual';
        case FinancialsPeriod.QUARTERLY:
            return 'quarterly';
        default:
            // This case shouldn't arise:
            return 'annual';
    }
};
// formats the decimal number to with only 1 number after dot and adds %.
export const getMarginStr = (dividend, divisor) => `${(dividend / divisor).toFixed(2).slice(0, -1)}%`;
