import React from 'react';
import { View } from 'react-native';
import useColors from 'hooks/useColors';
import useIsTablet from 'hooks/useIsTablet';
import Button from './Button';
import Spacer from './Spacer';
import Text from './Text';
import IconError from './icons/IconError';
import ModalHandle from './ModalHandle';
import { isWeb } from '../constants';
const ModalConfirmationContent = ({ title, subtitle, options, onPress, onPressCancel, containerStyle, }) => {
    const colors = useColors();
    const isTablet = useIsTablet();
    return (<View style={[
            containerStyle,
            {
                backgroundColor: options?.lightBg
                    ? colors.cards.onLight
                    : colors.cards.onDark,
            },
        ]}>
      {!options?.hideHandle && !options?.fullscreen && <ModalHandle />}
      {!options.flexed && (<>
          <IconError />
          <Spacer h={20}/>
        </>)}
      <Text Text-20 centered>
        {title}
      </Text>
      <Spacer h={options.flexed ? 16 : 4}/>
      <Text {...(options.flexed
        ? { 'TextThin-14': true }
        : { 'TextThin-16': true })} Gray centered>
        {subtitle}
      </Text>
      <Spacer h={options.flexed ? 32 : 34}/>
      <Button brandReversed title={options.confirmButtonTitle ?? 'OK'} onPress={onPress}/>
      <Spacer h={8}/>
      {options.cancelButtonTitle !== undefined ? (<Button brand onPress={onPressCancel} title={options.cancelButtonTitle}/>) : null}
      {!isTablet && !isWeb && <Spacer h={16}/>}
    </View>);
};
export default ModalConfirmationContent;
