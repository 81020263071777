import queryString from 'query-string';
import { CALL_API } from 'middleware/api';
import { Categories } from 'utils/types/navigation';
import * as constants from '../constants';
import { prepareParams } from '../utils/prepareParams';
import * as localTypes from './types';
import * as invest from '../types';
export * from './topup';
export const setReferrerId = (referrerId) => ({
    type: 'SET_REFERRER_ID',
    referrerId,
});
export const setAccountStatus = (status) => ({
    type: localTypes.SET_ACCOUNT_STATUS,
    status,
});
export const requestPhoneVerificationCode = (phoneNumber, countryCode, mode) => {
    const body = {
        phone_number: phoneNumber,
        country_code: countryCode,
        via: mode,
    };
    return {
        [CALL_API]: {
            endpoint: '/oauth/link-provider/phone/request_code',
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
            types: [
                localTypes.LINK_PHONE_GET_CODE_REQUEST,
                localTypes.LINK_PHONE_GET_CODE_SUCCESS,
                localTypes.LINK_PHONE_GET_CODE_FAILURE,
            ],
        },
    };
};
export const getTrustedContacts = () => ({
    [CALL_API]: {
        endpoint: '/trusted-contacts',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            localTypes.GET_TRUSTED_CONTACTS_REQUEST,
            localTypes.GET_TRUSTED_CONTACTS_SUCCESS,
            localTypes.GET_TRUSTED_CONTACTS_FAILURE,
        ],
    },
});
export const addTrustedContact = (data) => {
    const body = { ...data, relation: 'OTHER' };
    return {
        [CALL_API]: {
            endpoint: '/trusted-contacts',
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
            types: [
                localTypes.ADD_TRUSTED_CONTACT_REQUEST,
                localTypes.ADD_TRUSTED_CONTACT_SUCCESS,
                localTypes.ADD_TRUSTED_CONTACT_FAILURE,
            ],
            muteAlert: false,
        },
    };
};
export const editTrustedContact = (id, data) => {
    const body = { ...data, relation: 'OTHER' };
    return {
        [CALL_API]: {
            endpoint: `/trusted-contacts/${id}`,
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
            types: [
                localTypes.EDIT_TRUSTED_CONTACT_REQUEST,
                localTypes.EDIT_TRUSTED_CONTACT_SUCCESS,
                localTypes.EDIT_TRUSTED_CONTACT_FAILURE,
            ],
            muteAlert: false,
        },
    };
};
export const createTradingAccount = (props) => ({
    [CALL_API]: {
        endpoint: '/wealth/trading/account',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            trustedContactId: undefined,
            immediateFamilyExposed: props.isPoliticallyExposed,
            isAffiliatedExchangeOrFinra: props.isAffiliatedExchangeOrFinra,
            isControlPerson: props.isControlPerson,
            isPoliticallyExposed: props.isPoliticallyExposed,
            signedElectronicSignature: true,
            signedNotUSResident: true,
            signedAccountAgreement: constants.ALPACA_CUSTOMER_AGREEMENT,
            signedCustomerAgreement: constants.ALPACA_CUSTOMER_AGREEMENT,
        }),
        types: [
            localTypes.CREATE_TRADING_ACCOUNT_REQUEST,
            localTypes.CREATE_TRADING_ACCOUNT_SUCCESS,
            localTypes.CREATE_TRADING_ACCOUNT_FAILURE,
        ],
    },
});
export const getTradingAccount = () => ({
    [CALL_API]: {
        endpoint: '/wealth/trading/account',
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        types: [
            localTypes.GET_TRADING_ACCOUNT_REQUEST,
            localTypes.GET_TRADING_ACCOUNT_SUCCESS,
            localTypes.GET_TRADING_ACCOUNT_FAILURE,
        ],
    },
});
export const getConnectedAccount = () => ({
    [CALL_API]: {
        endpoint: '/wealth/trading/connected-account',
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        types: [
            localTypes.GET_CONNECTED_ACCOUNT_REQUEST,
            localTypes.GET_CONNECTED_ACCOUNT_SUCCESS,
            localTypes.GET_CONNECTED_ACCOUNT_FAILURE,
        ],
    },
});
export const setConnectedAccount = (accountId) => ({
    [CALL_API]: {
        endpoint: '/wealth/trading/connected-account',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ accountId }),
        types: ['SET_CONNECTED_ACCOUNT_REQUEST', 'SET_CONNECTED_ACCOUNT_SUCCESS', 'SET_CONNECTED_ACCOUNT_ERROR'],
        muteAlert: true,
    },
});
export const getStockMeta = (symbol) => ({
    [CALL_API]: {
        endpoint: `/wealth/trading/company-information?symbols=${symbol}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        types: [localTypes.GET_STOCK_META_REQUEST, localTypes.GET_STOCK_META_SUCCESS, localTypes.GET_STOCK_META_FAILURE],
    },
});
export const getAssetInfo = (symbols) => {
    let queryString = symbols.length ? `?symbols[]=${symbols[0]}` : '';
    for (let i = 1; i < symbols.length; i++) {
        queryString += `&symbols[]=${symbols[i]}`;
    }
    return {
        [CALL_API]: {
            endpoint: `/wealth/trading/assets-info${queryString}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            types: ['GET_ASSET_INFO_REQUEST', 'GET_ASSET_INFO_SUCCESS', 'GET_ASSET_INFO_FAILURE'],
        },
    };
};
export const getActivities = (params) => ({
    [CALL_API]: {
        endpoint: `/wealth/trading/activity?${queryString.stringify(prepareParams(params))}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        types: [localTypes.GET_ACTIVITIES_REQUEST, localTypes.GET_ACTIVITIES_SUCCESS, localTypes.GET_ACTIVITIES_FAILURE],
    },
});
export const getRecurringBuys = (assetId) => {
    const queryParams = {
        page: 1,
        perPage: 10000,
    };
    if (assetId) {
        queryParams.assetId = assetId;
    }
    return {
        [CALL_API]: {
            endpoint: `/wealth/trading/recurring?${queryString.stringify(queryParams)}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            types: ['GET_RECURRING_BUYS_REQUEST', 'GET_RECURRING_BUYS_SUCCESS', 'GET_RECURRING_BUYS_FAILURE'],
            extra: assetId,
        },
    };
};
export const deleteRecurringBuy = (id, assetId, pin) => ({
    [CALL_API]: {
        method: 'DELETE',
        endpoint: `/wealth/trading/recurring/${id}`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: ['DELETE_RECURRING_BUY_REQUEST', 'DELETE_RECURRING_BUY_SUCCESS', 'DELETE_RECURRING_BUY_FAILURE'],
        extra: { id, assetId },
        scope: 'order:write',
        muteAlert: true,
        pin,
    },
});
export const updateRecurringBuy = (id, params) => {
    const body = 'amount' in params
        ? params
        : {
            nonTradingDayPreference: params.nonTradingDayPreference,
            ...(params.buyConfig.interval === invest.RecurringBuyInterval.WEEKLY
                ? {
                    dayOfWeek: params.buyConfig.day,
                    period: invest.RecurringBuyInterval.WEEKLY,
                }
                : {
                    period: params.buyConfig.interval,
                    dayOfMonth: params.buyConfig.date,
                }),
        };
    return {
        [CALL_API]: {
            method: 'PATCH',
            endpoint: `/wealth/trading/recurring/${id}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            types: ['UPDATE_RECURRING_BUY_REQUEST', 'UPDATE_RECURRING_BUY_SUCCESS', 'UPDATE_RECURRING_BUY_FAILURE'],
            body: JSON.stringify(body),
        },
    };
};
export const getOrders = (params) => ({
    [CALL_API]: {
        endpoint: `/wealth/trading/orders?${queryString.stringify(prepareParams(params))}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [localTypes.GET_ORDERS_REQUEST, localTypes.GET_ORDERS_SUCCESS, localTypes.GET_ORDERS_FAILURE],
        extra: params,
    },
});
export const getOrder = (id) => ({
    [CALL_API]: {
        endpoint: `/wealth/trading/orders/${id}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [localTypes.GET_ORDER_REQUEST, localTypes.GET_ORDER_SUCCESS, localTypes.GET_ORDER_FAILURE],
    },
});
export const getPositions = (params) => ({
    [CALL_API]: {
        endpoint: `/wealth/trading/positions?${queryString.stringify(prepareParams(params))}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        types: [localTypes.GET_POSITIONS_REQUEST, localTypes.GET_POSITIONS_SUCCESS, localTypes.GET_POSITIONS_FAILURE],
        extra: {
            symbols: params.symbols,
        },
    },
});
export const getOrderPreview = (symbol, amountOrQuantity, operation) => ({
    [CALL_API]: {
        endpoint: '/wealth/trading/orders/preview',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(operation === 'buy'
            ? { symbol, amount: amountOrQuantity, operation }
            : { symbol, quantity: amountOrQuantity, operation }),
        types: ['GET_ORDER_PREVIEW_REQUEST', 'GET_ORDER_PREVIEW_SUCCESS', 'GET_ORDER_PREVIEW_ERROR'],
        muteAlert: true,
    },
});
export const createOrder = (symbol, amountOrQuantity, operation) => ({
    [CALL_API]: {
        endpoint: '/wealth/trading/orders',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(operation === 'buy'
            ? { symbol, amount: amountOrQuantity, operation }
            : { symbol, quantity: amountOrQuantity, operation }),
        types: ['CREATE_ORDER_REQUEST', localTypes.CREATE_ORDER_SUCCESS, 'CREATE_ORDER_FAILURE'],
        muteAlert: true,
    },
});
export const cacheOrder = (createOrderRawResponse) => ({
    type: localTypes.CREATE_ORDER_SUCCESS,
    payload: createOrderRawResponse,
    apiPayload: { url: '/wealth/trading/orders' },
});
export const cancelOrder = (orderId, pin) => ({
    [CALL_API]: {
        endpoint: `/wealth/trading/orders/${orderId}/cancel`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: ['CANCEL_ORDER_REQUEST', localTypes.CANCEL_ORDER_SUCCESS, 'CANCEL_ORDER_ERROR'],
        muteAlert: true,
        pin,
        scope: 'order:write',
        extra: { orderId },
    },
});
export const getHistoricalPrices = (params) => {
    const adjustedParams = { ...params };
    if (params.dateRange === 'RANGE_1W')
        adjustedParams.timeInterval = '30Min';
    return {
        [CALL_API]: {
            endpoint: `/wealth/trading/historical-prices?${queryString.stringify(adjustedParams)}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            types: ['GET_HISTORICAL_PRICES_REQUEST', 'GET_HISTORICAL_PRICES_SUCCESS', 'GET_HISTORICAL_PRICES_ERROR'],
        },
    };
};
export const getPortfolioChart = (params) => ({
    [CALL_API]: {
        endpoint: `/wealth/trading/portfolio/chart?${queryString.stringify(params)}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        types: ['GET_PORTFOLIO_CHART_REQUEST', 'GET_PORTFOLIO_CHART_SUCCESS', 'GET_PORTFOLIO_CHART_ERROR'],
    },
});
export const getSectors = () => ({
    [CALL_API]: {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        endpoint: '/wealth/trading/assets-categories',
        types: ['GET_SECTORS_REQUEST', 'GET_SECTORS_SUCCESS', 'GET_SECTORS_ERROR'],
    },
});
export const getTransferDetails = () => ({
    [CALL_API]: {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        endpoint: '/wealth/ledger',
        types: ['GET_TRANSFER_DETAILS_REQUEST', 'GET_TRANSFER_DETAILS_SUCCESS', 'GET_TRANSFER_DETAILS_FAILURE'],
    },
});
export const getBiggestDailyMovers = () => ({
    [CALL_API]: {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        endpoint: '/wealth/trading/biggest-movers',
        types: ['GET_BIGGEST_DAILY_MOVERS_REQUEST', 'GET_BIGGEST_DAILY_MOVERS_SUCCESS', 'GET_BIGGEST_DAILY_MOVERS_ERROR'],
    },
});
export const getStaticCollectionsStocks = (type, limit = 20) => ({
    [CALL_API]: {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        endpoint: `/wealth/trading/assets/list?type=${type}&limit=${limit}`,
        types: [
            'GET_STATIC_COLLECTION_STOCKS_REQUEST',
            'GET_STATIC_COLLECTION_STOCKS_SUCCESS',
            'GET_STATIC_COLLECTION_STOCKS_FAILURE',
        ],
        extra: { type },
    },
});
/**
 * `GET /wealth/trading/trending-stocks?interval=RANGE_1M&limit=8`
 *
 * @param limit How many stocks do you want?
 * @returns An API call to be dispatched
 */
export const getTopTrendingStocks = (limit) => ({
    [CALL_API]: {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        endpoint: `/wealth/trading/trending-stocks?interval=RANGE_1W&limit=${limit}`,
        types: ['GET_TOP_TRENDING_STOCKS_REQUEST', 'GET_TOP_TRENDING_STOCKS_SUCCESS', 'GET_TOP_TRENDING_STOCKS_ERROR'],
    },
});
export const getMostWatchedStocks = (limit) => ({
    [CALL_API]: {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        endpoint: `/wealth/trading/assets/list?type=MOST_WATCHED&limit=${limit}`,
        types: ['GET_MOST_WATCHED_STOCKS_REQUEST', 'GET_MOST_WATCHED_STOCKS_SUCCESS', 'GET_MOST_WATCHED_STOCKS_ERROR'],
    },
});
export const getMostHeldStocks = (limit) => ({
    [CALL_API]: {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        endpoint: `/wealth/trading/assets/list?type=MOST_POPULAR&limit=${limit}`,
        types: ['GET_MOST_HELD_STOCKS_REQUEST', 'GET_MOST_HELD_STOCKS_SUCCESS', 'GET_MOST_HELD_STOCKS_ERROR'],
    },
});
export const getRecentlyAddedStocks = () => ({
    [CALL_API]: {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        endpoint: '/wealth/trading/assets?sorting=-enabledAt&perPage=9',
        types: [
            'GET_RECENTLY_ADDED_STOCKS_REQUEST',
            'GET_RECENTLY_ADDED_STOCKS_SUCCESS',
            'GET_RECENTLY_ADDED_STOCKS_ERROR',
        ],
    },
});
export const getCollections = () => ({
    [CALL_API]: {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        endpoint: '/wealth/trading/assets-collections',
        types: ['GET_COLLECTIONS_REQUEST', 'GET_COLLECTIONS_SUCCESS', 'GET_COLLECTIONS_ERROR'],
    },
});
export const getRecurringBuyPreview = ({ amount, assetId, buyConfig, nonTradingDayPreference, }) => {
    const body = 'day' in buyConfig
        ? {
            assetId,
            amount,
            nonTradingDayPreference,
            dayOfWeek: buyConfig.day,
            period: buyConfig.interval,
        }
        : {
            amount,
            assetId,
            nonTradingDayPreference,
            dayOfMonth: buyConfig.date,
            period: buyConfig.interval,
        };
    return {
        [CALL_API]: {
            method: 'POST',
            muteAlert: true,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
            endpoint: '/wealth/trading/recurring/preview',
            types: [
                'GET_RECURRING_BUY_PREVIEW_REQUEST',
                'GET_RECURRING_BUY_PREVIEW_SUCCESS',
                'GET_RECURRING_BUY_PREVIEW_ERROR',
            ],
        },
    };
};
export const createRecurringBuy = ({ pin, amount, assetId, buyConfig, nonTradingDayPreference, }) => {
    const body = 'day' in buyConfig
        ? {
            assetId,
            amount,
            nonTradingDayPreference,
            dayOfWeek: buyConfig.day,
            period: buyConfig.interval,
        }
        : {
            amount,
            assetId,
            nonTradingDayPreference,
            dayOfMonth: buyConfig.date,
            period: buyConfig.interval,
        };
    return {
        [CALL_API]: {
            pin,
            method: 'POST',
            muteAlert: true,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
            endpoint: '/wealth/trading/recurring',
            types: ['CREATE_RECURRING_BUY_REQUEST', 'CREATE_RECURRING_BUY_SUCCESS', 'CREATE_RECURRING_BUY_ERROR'],
            scope: 'order:write',
        },
    };
};
export const cacheCurrentStockPrice = (symbol, value, mTimestamp, currency) => ({
    type: localTypes.CACHE_CURRENT_STOCK_PRICE,
    symbol,
    value,
    currency,
    mTimestamp,
});
export const withdraw = (amount, pin) => ({
    [CALL_API]: {
        endpoint: '/wealth/trading/withdraw-cash',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount }),
        types: ['WITHDRAW_REQUEST', 'WITHDRAW_SUCCESS', 'WITHDRAW_ERROR'],
        muteAlert: true,
        pin,
        scope: 'wallet:withdraw',
    },
});
export const getSearchResults = (searchTerm, currentPage, typeQueryParams) => {
    const queryParams = {
        fractionable: true,
        page: currentPage ?? 1,
        perPage: 40,
        sorting: 'name',
    };
    if (searchTerm) {
        queryParams.search = searchTerm;
    }
    let endpoint = `/wealth/trading/assets?${queryString.stringify(queryParams)}`;
    if (typeQueryParams) {
        Object.keys(typeQueryParams).forEach((queryParamKey) => {
            endpoint += `&${queryParamKey}=${typeQueryParams[queryParamKey]}`;
        });
    }
    return {
        [CALL_API]: {
            endpoint,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            types: ['GET_SEARCH_RESULTS_REQUEST', localTypes.GET_SEARCH_RESULTS_SUCCESS, 'GET_SEARCH_RESULTS_ERROR'],
        },
    };
};
const getStortingType = (filterCategory) => {
    switch (filterCategory) {
        case invest.AssetFilterCategory.SYMBOL:
            return 'symbol';
        case invest.AssetFilterCategory.PRICE:
            return '-sharePrice';
        case invest.AssetFilterCategory.PRICE_CHANGE:
            return '-sharePriceChange';
        default:
            return 'name';
    }
};
export const getStockGroup = (id, type, currentPage, filterCategory) => {
    const queryParams = {
        page: currentPage ?? 1,
        perPage: 40,
        sorting: getStortingType(filterCategory),
    };
    const endpoint = `/wealth/trading/assets/${type === Categories.SECTORS ? 'category' : 'collection'}/${id}?${queryString.stringify(queryParams)}`;
    return {
        [CALL_API]: {
            endpoint,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            types: ['GET_STOCK_GROUP_REQUEST', 'GET_STOCK_GROUP_SUCCESS', 'GET_STOCK_GROUP_FAILURE'],
        },
    };
};
export const getDocumentUploadUrl = (type, numberOfDocuments, addressId, isChangeOfAddress) => ({
    [CALL_API]: {
        endpoint: '/wealth/kyc/document-check',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ type, numberOfDocuments, addressId, isChangeOfAddress }),
        types: ['DOCUMENT_CHECK_REQUEST', 'DOCUMENT_CHECK_SUCCESS', 'DOCUMENT_CHECK_ERROR'],
        muteAlert: true,
    },
});
/**
 * GET `/wealth/trading/assets-prices`
 */
export const getLatestPrices = (stockSymbols) => {
    let queryString = stockSymbols.length ? `?symbols[]=${stockSymbols[0]}` : '';
    for (let i = 1; i < stockSymbols.length; i++) {
        queryString += `&symbols[]=${stockSymbols[i]}`;
    }
    return {
        [CALL_API]: {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            endpoint: `/wealth/trading/assets-prices${queryString}`,
            types: ['GET_LATEST_PRICE_INFO_REQUEST', 'GET_LATEST_PRICE_INFO_SUCCESS', 'GET_LATEST_PRICE_INFO_ERROR'],
        },
    };
};
export const cacheTradingAccount = (account) => ({
    type: 'CACHE_TRADING_ACCOUNT',
    payload: {
        account,
    },
});
export const cacheConnectedAccount = (account) => ({
    type: 'GET_CONNECTED_ACCOUNT_SUCCESS',
    payload: {
        account,
    },
});
export const cacheAccounts = (tradingAccount, connectedAccount) => ({
    type: 'CACHE_ACCOUNTS',
    tradingAccount,
    connectedAccount,
});
/**
 * Create a new watchlist.
 * `POST /wealth/trading/watchlists`
 * @returns The newly created watchlist, without assets
 */
export function createWatchlist(name, emoji) {
    return {
        [CALL_API]: {
            endpoint: '/wealth/trading/watchlists',
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, emoji }),
            types: ['CREATE_WATCHLIST_REQUEST', 'CREATE_WATCHLIST_SUCCESS', 'CREATE_WATCHLIST_ERROR'],
        },
    };
}
/**
 * `GET /wealth/trading/watchlists`
 * @returns All user watchlists without assets
 */
export function getWatchlists() {
    return {
        [CALL_API]: {
            endpoint: '/wealth/trading/watchlists',
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            types: ['GET_WATCHLISTS_REQUEST', 'GET_WATCHLISTS_SUCCESS', 'GET_WATCHLISTS_ERROR'],
        },
    };
}
export function updateWatchlist(watchlistId, params) {
    return {
        [CALL_API]: {
            endpoint: `/wealth/trading/watchlists/${watchlistId}`,
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
            types: ['UPDATE_WATCHLIST_REQUEST', 'UPDATE_WATCHLIST_SUCCESS', 'UPDATE_WATCHLIST_FAILURE'],
        },
    };
}
/**
 * Delete a watchlist.
 * `DELETE /wealth/trading/watchlists/{watchlistId}`
 * @param watchlistId The ID of the watchlist to delete. You can even delete it multiple times - no error will be raised on backend.
 * @returns nothing
 */
export function deleteWatchlist(watchlistId) {
    return {
        [CALL_API]: {
            endpoint: `/wealth/trading/watchlists/${watchlistId}`,
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
            },
            types: ['DELETE_WATCHLIST_REQUEST', 'DELETE_WATCHLIST_SUCCESS', 'DELETE_WATCHLIST_ERROR'],
            extra: { watchlistId },
        },
    };
}
/**
 * Returns the specified watchlist with assets and prices.
 * `GET /wealth/trading/watchlists/{watchlistId}`
 * @param watchlistId The ID of the watchlist.
 * @returns The specified watchlist with assets and prices.
 */
export function getWatchlist(watchlistId) {
    return {
        [CALL_API]: {
            endpoint: `/wealth/trading/watchlists/${watchlistId}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            types: ['GET_WATCHLIST_REQUEST', 'GET_WATCHLIST_SUCCESS', 'GET_WATCHLIST_ERROR'],
        },
    };
}
/**
 * Adds an asset to a watchlist and returns it, with assets and prices.
 * `PUT /wealth/trading/watchlists/{watchlistId}/assets/{symbol}`
 * @param watchlistId The ID of the watchlist.
 * @param symbol Stock's symbol to add.
 * @returns The specified watchlist with assets and prices.
 */
export function addAssetToWatchlist(watchlistId, symbol, asset) {
    return {
        [CALL_API]: {
            endpoint: `/wealth/trading/watchlists/${watchlistId}/assets/${symbol}`,
            method: 'PUT',
            headers: {
                Accept: 'application/json',
            },
            types: ['ADD_ASSET_TO_WATCHLIST_REQUEST', 'ADD_ASSET_TO_WATCHLIST_SUCCESS', 'ADD_ASSET_TO_WATCHLIST_ERROR'],
            extra: { symbol, watchlistId, asset },
        },
    };
}
export function getWatchListsOfStock(symbol) {
    return {
        [CALL_API]: {
            endpoint: `/wealth/trading/asset-watchlists?symbol=${symbol}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            types: ['GET_WATCHLIST_OF_STOCK_REQUEST', 'GET_WATCHLIST_OF_STOCK_SUCCESS', 'GET_WATCHLIST_OF_STOCK_ERROR'],
            extra: { symbol },
        },
    };
}
/**
 * Adds an asset to a watchlist and returns it, with assets and prices.
 * `DELETE /wealth/trading/watchlists/{watchlistId}/assets/{symbol}`
 * @param watchlistId The ID of the watchlist.
 * @param symbol Stock's symbol to remove.
 * @returns The specified watchlist with assets and prices.
 */
export function removeAssetFromWatchlist(watchlistId, symbol) {
    return {
        [CALL_API]: {
            endpoint: `/wealth/trading/watchlists/${watchlistId}/assets/${symbol}`,
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
            },
            types: [
                'REMOVE_ASSET_FROM_WATCHLIST_REQUEST',
                'REMOVE_ASSET_FROM_WATCHLIST_SUCCESS',
                'REMOVE_ASSET_FROM_WATCHLIST_ERROR',
            ],
            extra: { symbol, watchlistId },
        },
    };
}
export const getFreeSharesClaimable = () => ({
    [CALL_API]: {
        endpoint: '/wealth/trading/portfolio/free-shares',
        method: 'GET',
        headers: { Accept: 'application/json' },
        types: ['GET_FREE_SHARES_REQUEST', 'GET_FREE_SHARES_SUCCESS', 'GET_FREE_SHARES_ERROR'],
    },
});
export const claimFreeShare = () => ({
    [CALL_API]: {
        endpoint: '/wealth/trading/portfolio/claim-free-share',
        method: 'GET',
        headers: { Accept: 'application/json' },
        types: ['CLAIM_FREE_SHARE_REQUEST', 'CLAIM_FREE_SHARE_SUCCESS', 'CLAIM_FREE_SHARE_ERROR'],
    },
});
/**
 * GET `/wealth/trading/news/${symbol}${limit}`
 */
export const getStockNews = (symbol, limit) => ({
    [CALL_API]: {
        endpoint: `/wealth/trading/news/${symbol}${limit ? `?limit=${limit}` : ''}`,
        method: 'GET',
        headers: { Accept: 'application/json' },
        types: ['GET_STOCK_NEWS_REQUEST', 'GET_STOCK_NEWS_SUCCESS', 'GET_STOCK_NEWS_ERROR'],
        extra: { symbol, limit },
    },
});
/**
 * GET `/wealth/trading/news/${symbol}${limit}`
 */
export const getStockFinancials = (symbol, period) => {
    let periodQueryParam = 'annual';
    switch (true) {
        case period === invest.FinancialsPeriod.QUARTERLY:
            periodQueryParam = 'quarter';
            break;
        default:
            break;
    }
    return {
        [CALL_API]: {
            endpoint: `/wealth/trading/assets/symbol/${symbol}/financials?period=${periodQueryParam}&limit=4`,
            method: 'GET',
            headers: { Accept: 'application/json' },
            types: ['GET_STOCK_FINANCIALS_REQUEST', 'GET_STOCK_FINANCIALS_SUCCESS', 'GET_STOCK_FINANCIALS_FAILURE'],
            extra: { symbol, period },
        },
    };
};
export function getRecurringBuyActivities(id, paging) {
    return {
        [CALL_API]: {
            endpoint: `/wealth/trading/recurring/${id}/activity?${queryString.stringify(prepareParams({ paging }))}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            extra: { id },
            types: [
                'GET_RECURRING_BUY_ACTIVITIES_REQUEST',
                'GET_RECURRING_BUY_ACTIVITIES_SUCCESS',
                'GET_RECURRING_BUY_ACTIVITIES_FAILURE',
            ],
        },
    };
}
export function getFxFees() {
    return {
        [CALL_API]: {
            endpoint: '/wealth/trading/foreign-exchange/fees',
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            types: ['GET_FX_FEES_REQUEST', 'GET_FX_FEES_SUCCESS', 'GET_FX_FEES_FAILURE'],
        },
    };
}
export const getCostsAndCharges = (dateFrom, dateTo) => ({
    [CALL_API]: {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        endpoint: `/wealth/trading/statements/costs-and-charges?dateFrom=${dateFrom}&dateTo=${dateTo}`,
        types: ['GET_COSTS_AND_CHARGES_REQUEST', 'GET_COSTS_AND_CHARGES_SUCCESS', 'GET_COSTS_AND_CHARGES_ERROR'],
        muteAlert: false,
    },
});
export const getCapitalGainsAndIncome = (dateFrom, dateTo) => ({
    [CALL_API]: {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        endpoint: `/wealth/trading/statements/activity?dateFrom=${dateFrom}&dateTo=${dateTo}`,
        types: [
            'GET_CAPITAL_GAINS_AND_CHARGES_REQUEST',
            'GET_CAPITAL_GAINS_AND_CHARGES_SUCCESS',
            'GET_CAPITAL_GAINS_AND_CHARGES_ERROR',
        ],
        muteAlert: false,
    },
});
export const getWalletStatement = (dateFrom, dateTo) => ({
    [CALL_API]: {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        endpoint: `/wealth/ledger/wallet/statement?dateFrom=${dateFrom}&dateTo=${dateTo}`,
        types: ['GET_WALLET_STATEMENT_REQUEST', 'GET_WALLET_STATEMENT_SUCCESS', 'GET_WALLET_STATEMENT_ERROR'],
        muteAlert: false,
    },
});
export const getPotsStatement = (dateFrom, dateTo) => ({
    [CALL_API]: {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        endpoint: `/wealth/pots/statement?dateFrom=${dateFrom}&dateTo=${dateTo}`,
        types: ['GET_POTS_STATEMENT_REQUEST', 'GET_POTS_STATEMENT_SUCCESS', 'GET_POTS_STATEMENT_ERROR'],
        muteAlert: false,
    },
});
export const getRequiredNationalIdentifiers = () => ({
    [CALL_API]: {
        endpoint: '/me/national-identifiers/required',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            'GET_REQUIRED_NATIONAL_IDENTIFIERS_REQUEST',
            'GET_REQUIRED_NATIONAL_IDENTIFIERS_SUCCESS',
            'GET_REQUIRED_NATIONAL_IDENTIFIERS_FAILURE',
        ],
    },
});
export const postRequiredNationalIdentifiers = (type, country, value) => ({
    [CALL_API]: {
        endpoint: '/me/national-identifiers',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            type,
            value,
            country,
        }),
        extra: {
            type,
            value,
            country,
        },
        types: [
            'POST_REQUIRED_NATIONAL_IDENTIFIERS_REQUEST',
            'POST_REQUIRED_NATIONAL_IDENTIFIERS_SUCCESS',
            'POST_REQUIRED_NATIONAL_IDENTIFIERS_FAILURE',
        ],
    },
});
export function getAnalystInfo(symbol) {
    return {
        [CALL_API]: {
            endpoint: `/wealth/trading/assets-analysts-info?symbol=${symbol}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            types: [
                'INVEST_GET_ANALYST_INFO_REQUEST_ACTION',
                'INVEST_GET_ANALYST_INFO_SUCCESS_ACTION',
                'INVEST_GET_ANALYST_INFO_FAILURE_ACTION',
            ],
            extra: { symbol },
        },
    };
}
