import moment from 'moment';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';
import Button from 'design-system/Button';
import StatusBar from 'design-system/StatusBar';
import { AppView } from 'design-system/AppView';
import { Day } from 'design-system/calendar/Day';
import { ButtonGroup } from 'design-system/ButtonGroup';
import ScreenInfoHeader from 'design-system/ScreenInfoHeader';
import { SCROLL_INDICATOR_INSETS } from 'design-system/values';
import { Calendar } from 'design-system/calendar/Calendar';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import useColors from 'hooks/useColors';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { isWeb } from '../../constants';
const FORMAT = 'MMMM D, YYYY';
const strings = {
    selectDate: 'Select a date',
    defaultButtonTitle: 'Next',
};
export const SelectDateScreen = withAnimatedNavigationHeader(({ navigation, route, scrollHandler }) => {
    const colors = useColors();
    const { longTitle } = route.params;
    const { minDate, maxDate } = route.params.props || {};
    useLayoutEffect(() => {
        navigation.setOptions({
            title: longTitle ? '' : strings.selectDate,
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary, longTitle]);
    const [selected, setSelected] = useState(moment());
    const [buttonText, setButtonText] = useState(selected.format(FORMAT));
    const checkEnabled = useCallback((date) => {
        const m = moment(new Date(date.timestamp));
        if (!minDate && !maxDate) {
            return true;
        }
        if (minDate && maxDate) {
            return m.isBefore(maxDate) && m.isAfter(minDate);
        }
        if (minDate) {
            return m.isAfter(minDate);
        }
        return m.isBefore(maxDate);
    }, [maxDate, minDate]);
    const onDayPress = useCallback((date) => {
        if (!checkEnabled(date)) {
            return;
        }
        const m = moment.utc(date.timestamp);
        setSelected(m);
        setButtonText(m.format(FORMAT));
    }, [checkEnabled]);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const paddingBottom = useMarginBottom('paddingBottom', undefined, paddingHorizontalStyle);
    const onSubmit = useCallback(() => {
        route.params.onSubmitDate(selected.utcOffset(0, true).format());
    }, [route.params, selected]);
    return (<AppView withFrame={false}>
          <StatusBar barStyle="dark-content" animated/>
          <Animated.ScrollView contentContainerStyle={paddingBottom} onScroll={scrollHandler} scrollEventThrottle={16} scrollIndicatorInsets={SCROLL_INDICATOR_INSETS}>
            {!!longTitle && <ScreenInfoHeader title={longTitle}/>}
            <Calendar style={styles.calendar} maxDate={maxDate} dayComponent={({ date }) => (<Day key={date?.dateString} date={date} isStartDay={date
                ? selected.isSame(moment.utc(date.timestamp), 'day')
                : false} onDayPress={onDayPress} isDisabled={date ? !checkEnabled(date) : false}/>)}/>
          </Animated.ScrollView>
          <ButtonGroup type="floating">
            <Button brand title={buttonText} onPress={onSubmit}/>
          </ButtonGroup>
        </AppView>);
}, ({ colors }) => ({
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styles = StyleSheet.create({
    calendar: {
        ...(!isWeb && {
            alignSelf: 'center',
        }),
    },
});
