import React, { useCallback, useRef } from 'react';
import { View, Animated, TouchableBounce, StyleSheet, } from 'react-native';
import { Colors, rem } from 'design-system/values';
const TouchableBounceHighlight = ({ style, children, onPress, white, hitSlop, }) => {
    const underlayOpacity = useRef(new Animated.Value(0));
    const onPressIn = useCallback(() => {
        Animated.spring(underlayOpacity.current, {
            toValue: 0.07,
            velocity: 0.1,
            bounciness: 0,
            useNativeDriver: true,
        }).start();
    }, []);
    const onPressOut = useCallback(() => {
        Animated.spring(underlayOpacity.current, {
            toValue: 0,
            velocity: 0.4,
            bounciness: 0,
            useNativeDriver: true,
        }).start();
    }, []);
    return (<TouchableBounce onPress={onPress} onPressIn={onPressIn} onPressOut={onPressOut} hitSlop={hitSlop}>
      <View style={style}>
        <Animated.View style={[
            { opacity: underlayOpacity.current },
            styles.underlay,
            white && styles.white,
        ]}/>
        {children}
      </View>
    </TouchableBounce>);
};
const styles = StyleSheet.create({
    underlay: {
        ...StyleSheet.absoluteFillObject,
        borderRadius: rem(16),
        backgroundColor: Colors.label,
    },
    white: {
        backgroundColor: Colors.white,
    },
});
export default TouchableBounceHighlight;
