import React, { memo, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { LineChart } from 'react-native-svg-charts';
import moment from 'moment';
import * as shape from 'd3-shape';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import Indicator from 'design-system/Indicator';
import useColors from 'hooks/useColors';
import { formatPeriod } from 'utils/formatting';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import Point from './Point';
import Shadow from './Shadow';
import MaxLine from './MaxLine';
import { isWeb } from '../../../../constants';
import AnalyticsGraphLabel from '../AnalyticsGraphLabel';
const SHAPE_CURVE = shape.curveBasis;
const frmt = (str) => moment(str).format('D MMM');
function middleDate(a, b) {
    const diff = Math.abs(moment(a).diff(b));
    const middle = diff / 2 + Math.min(moment(a).valueOf(), moment(b).valueOf());
    return frmt(moment(middle));
}
const strokeWidth = 2;
const xAccessor = ({ index }) => index;
const yAccessor = ({ item }) => item.cumulative || 0;
const CONTENT_INSET = { top: 6, bottom: 6, right: 6 };
const startElement = { cumulative: 0 };
const LinearChart = ({ current, currentSpending, previous, previousSpending, currentFrom, currentTo, previousFrom, previousTo, currency, blurValue, }) => {
    const data = useMemo(() => {
        if (!current)
            return undefined;
        const now = moment().format('YYYY-MM-DD');
        return [startElement, ...current.filter((item) => item.date <= now)];
    }, [current]);
    const middle = useMemo(() => {
        if (!current)
            return undefined;
        return middleDate(currentFrom, currentTo);
    }, [current, currentFrom, currentTo]);
    const dataPrevious = useMemo(() => (previous ? [startElement, ...previous] : undefined), [previous]);
    const { width } = useAppFrameDimensions(isWeb);
    const colors = useColors();
    const colorCurrent = colors.gradients.apricot.colors[0];
    const colorPrevious = colors.elements.secondaryTransparentFill;
    const svgCurrent = useMemo(() => ({ stroke: colorCurrent, strokeWidth }), [colorCurrent]);
    const svgPrevious = useMemo(() => ({ stroke: colorPrevious, strokeWidth }), [colorPrevious]);
    const largestYMax = useMemo(() => [...(data ?? []), ...(dataPrevious ?? [])].reduce((prev, cur) => (cur.cumulative > prev ? cur.cumulative : prev), 0), [data, dataPrevious]);
    if (!current || !data)
        return (<View style={styles.loading}>
        <Indicator dark/>
      </View>);
    const lastItemCurrent = data ? data[data.length - 1] : undefined;
    const lastItemPrevious = dataPrevious
        ? dataPrevious[dataPrevious.length - 1]
        : undefined;
    const xMax = data.length - 1;
    const xMaxPrevious = dataPrevious ? dataPrevious.length - 1 : 0;
    const largestXMax = xMax > xMaxPrevious ? xMax : xMaxPrevious;
    return (<View>
      <View style={[
            { width: width - rem(isWeb ? 176 : 32) },
            styles.graphContainer,
        ]}>
        {/* We must use two charts or they keep drawining additional paths when the values change */}
        {dataPrevious !== undefined && (<LineChart style={StyleSheet.absoluteFill} data={dataPrevious} svg={svgPrevious} yAccessor={yAccessor} xAccessor={xAccessor} xMax={largestXMax} yMax={largestYMax} yMin={startElement.cumulative} curve={SHAPE_CURVE} contentInset={CONTENT_INSET} height={100}>
            {lastItemPrevious !== undefined && (<Point yValue={lastItemPrevious.cumulative} xValue={xMaxPrevious} fill={colorPrevious}/>)}
            {lastItemPrevious !== undefined && (<MaxLine yValue={lastItemPrevious.cumulative}/>)}
          </LineChart>)}
        <LineChart style={StyleSheet.absoluteFill} data={data} svg={svgCurrent} yAccessor={yAccessor} xAccessor={xAccessor} xMax={largestXMax} yMax={largestYMax} yMin={startElement.cumulative} curve={SHAPE_CURVE} contentInset={CONTENT_INSET}>
          <Shadow />
          {lastItemCurrent !== undefined && (<Point yValue={lastItemCurrent.cumulative} xValue={xMax} fill={colorCurrent}/>)}
        </LineChart>
      </View>
      <View style={styles.labelDateContainer}>
        <Text Text-12 color={colors.text.tertiary}>
          {frmt(currentFrom)}
        </Text>
        {middle !== undefined && (<Text Text-12 color={colors.text.tertiary}>
            {middle}
          </Text>)}
        <Text Text-12 color={colors.text.tertiary}>
          {frmt(currentTo)}
        </Text>
      </View>
      <View style={styles.labelContainer}>
        {previous && previousSpending !== undefined ? (<AnalyticsGraphLabel color={colorPrevious} value={{
                amount: previousSpending,
                currency,
            }} title={formatPeriod({ from: previousFrom, to: previousTo }, false, true)} blurValue={blurValue}/>) : null}
        <AnalyticsGraphLabel color={colorCurrent} value={{
            amount: currentSpending,
            currency,
        }} title={formatPeriod({ from: currentFrom, to: currentTo }, false, true)} blurValue={blurValue}/>
      </View>
    </View>);
};
const styles = StyleSheet.create({
    graphContainer: {
        height: rem(117),
    },
    labelDateContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: rem(16),
    },
    labelContainer: {
        padding: rem(16),
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    loading: {
        minHeight: rem(250),
        paddingBottom: rem(42),
    },
});
export default memo(LinearChart);
