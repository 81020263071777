import { CommonActions } from '@react-navigation/native';
import { checkPushNotificationsEnabled } from 'utils/packages/pushNotifications';
import checkFeatureFlag from 'utils/featureFlags';
const defaultParams = { back: false };
export const getBankConnectionsRouteForUser = ({ user, connectingSecond, }) => {
    if (user.guessedHomeCountry === 'GB') {
        return {
            name: 'ChooseBank',
            params: { ...defaultParams, personal: true, pushRoute: connectingSecond },
        };
    }
    return {
        name: 'USConsent',
        params: { back: false },
    };
};
const getBankConnectionsRoute = ({ user, connections, connectingConnection, signInNavigation, }) => {
    if (!connectingConnection && !connections.length) {
        if (user.guessedHomeCountry === 'GB') {
            return {
                params: undefined,
                name: 'ChooseBankIntro',
            };
        }
        return getBankConnectionsRouteForUser({ user });
    }
    if (!signInNavigation.connectingSecondLogin &&
        !signInNavigation.secondLoginSkipped &&
        (connections.length === 1 || connectingConnection)) {
        return {
            params: undefined,
            name: 'LinkAnotherLogin',
        };
    }
    return null;
};
const getPushNotificationsRoute = async (signInNavigation) => {
    if (!signInNavigation.pushNotificationsSkipped) {
        const pushNotificationsEnabled = await checkPushNotificationsEnabled();
        if (!pushNotificationsEnabled) {
            return {
                params: undefined,
                name: 'PushNotifications',
            };
        }
    }
    return null;
};
const getFirstSetBudgetsRoute = ({ signInNavigation, budgetBaseLimit, featureFlags, }) => {
    if (checkFeatureFlag(featureFlags, 'budgets_in_onboarding_before_choose_bank') &&
        ((!signInNavigation.isSigningIn && budgetBaseLimit === undefined) ||
            (budgetBaseLimit !== undefined && !budgetBaseLimit))) {
        return {
            name: 'SetBudgetsIntroScreen',
            params: undefined,
        };
    }
    return null;
};
const getFirstPremiumScreenRoute = ({ user, signInNavigation, isSecond, }) => {
    const name = 'OnboardingHowFreeTrialWorksScreen';
    if (user.emmaProStatus) {
        if (!user.emmaProStatus.active) {
            if (!signInNavigation.premiumScreenSkipped) {
                if (user.emmaProStatus.referrerName &&
                    user.emmaProStatus.eligibleFreeTrialDays &&
                    user.emmaProStatus.eligibleFreeTrialDays > 7) {
                    return {
                        name: 'OnboardingPremiumReferralScreen',
                        params: undefined,
                    };
                }
                return {
                    name,
                    params: {},
                };
            }
            if (isSecond &&
                !signInNavigation.premiumScreenSkippedSecond &&
                !signInNavigation.isSigningIn) {
                return {
                    params: {},
                    name: 'SecondOnboardingHowFreeTrialWorksScreen',
                };
            }
        }
        else if (user.guessedHomeCountry === 'GB' &&
            !signInNavigation.viewedPremiumWelcomeScreen &&
            signInNavigation.hasUpgradedToPremium) {
            return {
                params: { tier: user.emmaProStatus.tier },
                name: 'OnboardingPremiumSubscriptionWelcomeScreen',
            };
        }
    }
    return null;
};
export const initialRouteName = 'OnBoardingScreen';
// flow can be seen here https://www.figma.com/file/axHgXIQEADNneEgpzPdx1G/Untitled?node-id=0%3A1
export async function getNextRoute({ user, userAddresses, userAdditionalInfo, connections, connectingConnection = false, isAppStart = false, storePasscode, featureFlags, signInNavigation, budgetBaseLimit, resetPasscodeNextStep, hasCurrentRentReporting, spaces, }) {
    if (isAppStart) {
        if (user.pinLastChangedAt === null ||
            resetPasscodeNextStep === 'set_new_pin') {
            return {
                params: defaultParams,
                name: 'SetupPasscode',
            };
        }
        if (!user.pinLastChangedAt) {
            return {
                params: undefined,
                name: initialRouteName,
            };
        }
    }
    if (!isAppStart &&
        (!user.pinLastChangedAt || resetPasscodeNextStep === 'set_new_pin')) {
        return {
            name: 'SetupPasscode',
            params: defaultParams,
        };
    }
    if (!user.firstName || !user.lastName) {
        return {
            params: undefined,
            name: 'Name',
        };
    }
    if (!user.dateOfBirth) {
        return {
            params: {},
            name: 'DateOfBirth',
        };
    }
    // If the user previously authenticated with apple/google we may have their email already
    if (!user.email) {
        return {
            params: undefined,
            name: 'Email',
        };
    }
    if (checkFeatureFlag(featureFlags, 'onboarding_address') &&
        user.guessedHomeCountry === 'GB' &&
        !userAddresses?.length) {
        return {
            params: {
                skipGetAddress: isAppStart,
            },
            name: 'AddressPostCodeScreen',
        };
    }
    if (checkFeatureFlag(featureFlags, 'onboarding_kyc_v2') &&
        user.guessedHomeCountry === 'GB') {
        if (!userAdditionalInfo?.maritalStatus &&
            !signInNavigation.didPostMaritalStatus) {
            return {
                params: undefined,
                name: 'MaritalStatusScreen',
            };
        }
        if ((userAdditionalInfo?.dependantNumber === undefined ||
            userAdditionalInfo?.dependantNumber === null) &&
            !signInNavigation.didPostDependantNumber) {
            return {
                params: undefined,
                name: 'DependentsScreen',
            };
        }
        if (!userAdditionalInfo?.employmentStatus &&
            !signInNavigation.didPostEmploymentStatus) {
            return {
                params: undefined,
                name: 'EmploymentStatusScreen',
            };
        }
        if ((userAdditionalInfo?.grossAnnualSalary === undefined ||
            userAdditionalInfo?.grossAnnualSalary === null) &&
            !signInNavigation.didPostGrossAnnualSalary) {
            return {
                params: undefined,
                name: 'AnnualGrossIncomeScreen',
            };
        }
    }
    if (user.guessedHomeCountry === 'GB') {
        if ((userAdditionalInfo?.isRenting === undefined ||
            userAdditionalInfo?.isRenting === null) &&
            !hasCurrentRentReporting) {
            return {
                params: undefined,
                name: 'RentQuestionScreen',
            };
        }
        if (!userAdditionalInfo?.financialGoals?.length) {
            return {
                params: undefined,
                name: 'SetGoal',
            };
        }
    }
    else if (!userAdditionalInfo?.financialGoals?.length) {
        return {
            params: undefined,
            name: 'SetGoals',
        };
    }
    const pushNotificationsRoute = await getPushNotificationsRoute(signInNavigation);
    if (pushNotificationsRoute) {
        return pushNotificationsRoute;
    }
    if (!signInNavigation.space &&
        // Check if the user is in any other premium space with accounts
        // We can skip these steps if they are
        Object.values(spaces).find((s) => s.space.accountsCount && s.space.isPremium && !s.space.isDefault) === undefined) {
        const setBudgetsRoute = getFirstSetBudgetsRoute({
            signInNavigation,
            budgetBaseLimit,
            featureFlags,
        });
        if (setBudgetsRoute) {
            return setBudgetsRoute;
        }
        const bankConnectionsRoute = getBankConnectionsRoute({
            user,
            connections,
            connectingConnection,
            featureFlags,
            signInNavigation,
        });
        if (bankConnectionsRoute) {
            return bankConnectionsRoute;
        }
        if (signInNavigation.showFakeLoadingScreen) {
            return {
                params: undefined,
                name: 'AnimatedLoadingScreen',
            };
        }
        const firstPremiumScreenRoute = getFirstPremiumScreenRoute({
            isAppStart,
            storePasscode,
            user,
            featureFlags,
            signInNavigation,
            isSecond: false,
        });
        if (firstPremiumScreenRoute) {
            return firstPremiumScreenRoute;
        }
    }
    return {
        params: undefined,
        name: 'SuccessScreen',
    };
}
// If we call the function from one of these screens, then they are connecting
const isConnectingConnection = (routeName) => routeName === 'ChooseBank' ||
    routeName === 'Consent' ||
    routeName === 'USConsent';
const resetRoutes = [
    'Name',
    'SetGoal',
    'SetGoals',
    'Attribution',
    'SetupPasscode',
    'SuccessScreen',
    'LinkAnotherLogin',
    'DependentsScreen',
    'PushNotifications',
    'RentQuestionScreen',
    'MaritalStatusScreen',
    'AddressPostCodeScreen',
    'AnimatedLoadingScreen',
    'EmploymentStatusScreen',
    'AnnualGrossIncomeScreen',
    'OnboardingHowFreeTrialWorksScreen',
];
export const navigateToLoggedIn = async (navigation, { userAdditionalInfo, user, userAddresses, connections, featureFlags, signInNavigation, budgetBaseLimit, resetPasscodeNextStep, hasCurrentRentReporting, spaces, }) => {
    const currentRoutes = navigation.getState()?.routes;
    const currentRoute = 
    // eslint-disable-next-line no-unsafe-optional-chaining
    currentRoutes?.[currentRoutes?.length - 1]?.name || undefined;
    const route = await getNextRoute({
        user,
        userAddresses,
        userAdditionalInfo,
        connections,
        featureFlags,
        connectingConnection: isConnectingConnection(currentRoute),
        signInNavigation,
        budgetBaseLimit,
        resetPasscodeNextStep,
        hasCurrentRentReporting,
        spaces,
    });
    if (resetRoutes.includes(route.name)) {
        navigation.dispatch(CommonActions.reset({
            index: 0,
            routes: [route],
        }));
    }
    else {
        navigation?.navigate(route);
    }
};
