import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState, } from 'react';
import { View, Alert, ScrollView, StyleSheet, TouchableOpacity, } from 'react-native';
import moment from 'moment';
import emoji from 'node-emoji';
import { CommonActions } from '@react-navigation/native';
import { useHeaderHeight } from '@react-navigation/elements';
import Text from 'design-system/Text';
import Button from 'design-system/Button';
import { rem } from 'design-system/values';
import { Spacer } from 'design-system/Spacer';
import StatusBar from 'design-system/StatusBar';
import { AppView } from 'design-system/AppView';
import SafeAreaTop from 'design-system/SafeAreaTop';
import DateInput, { toDate } from 'design-system/date/DateInput';
import KeyboardAvoidingContainer from 'design-system/KeyboardAvoidingContainer';
import useColors from 'hooks/useColors';
import { useFetchOld, muteAll, muteErrorAndResultButThrows } from 'hooks';
import { changeTransactionDate } from 'actions';
import usePrivateRoute from 'hooks/usePrivateRoute';
import { useRequiredBenefitCallbackWithSpaces } from 'features/premium/hooks/useRequiredBenefitCallback';
import { isWeb } from '../../../constants';
import { useTransaction } from '../hooks/useTransaction';
import useRateAppAfterTransactionEdit from '../hooks/useRateAppAfterTransactionEdit';
const strings = {
    title: 'What date would you like to\xa0set?',
};
const EditDateScreen = ({ navigation, transaction }) => {
    usePrivateRoute();
    const [, , requesting, fetch] = useFetchOld(isWeb ? muteErrorAndResultButThrows : muteAll);
    const colors = useColors();
    const navHeaderHeight = useHeaderHeight();
    const rateApp = useRateAppAfterTransactionEdit();
    const [time, setTime] = useState(() => {
        const { bookingDate, customDate } = transaction;
        return toDate(moment(customDate || bookingDate));
    });
    useLayoutEffect(() => {
        const nameToDisplay = transaction?.customName || transaction?.counterpartName || 'Edit Date';
        navigation.setOptions({
            headerTitle: '',
            title: nameToDisplay,
            headerTintColor: colors.text.primary,
        });
    }, [
        colors.text.primary,
        transaction?.counterpartName,
        transaction?.customName,
    ]);
    const openFixedPrediction = useRequiredBenefitCallbackWithSpaces(useCallback(async () => {
        try {
            const error = () => {
                Alert.alert(emoji.emojify('Come on :face_with_rolling_eyes:'), 'This is clearly not a valid date.');
            };
            const { day, month, year } = time || {};
            if (!day ||
                !month ||
                !year ||
                Number(month) > 12 ||
                Number(day) > 31 ||
                (year && year.length < 4)) {
                error();
                return;
            }
            const date = moment([year, Number(month) - 1, day]);
            if (!date.isValid()) {
                error();
                return;
            }
            if (isWeb) {
                await fetch(changeTransactionDate(transaction, date.format('YYYY-MM-DD')));
            }
            else {
                fetch(changeTransactionDate(transaction, date.format('YYYY-MM-DD')));
            }
            if (navigation.canGoBack()) {
                navigation.goBack();
            }
            else {
                navigation.dispatch(CommonActions.reset({
                    index: 0,
                    routes: [{ name: 'Feed' }],
                }));
            }
            rateApp();
        }
        catch {
            //
        }
    }, [time, transaction, rateApp]), 'changeTransactionDate');
    const onPressRevert = useCallback(() => {
        const { bookingDate } = transaction;
        setTime(toDate(moment(bookingDate)));
    }, [transaction]);
    const isUsingOriginal = useMemo(() => {
        if (time?.year && time.month && time.day) {
            const { bookingDate, customDate } = transaction;
            const originalDate = moment(bookingDate);
            return ((customDate && originalDate.isSame(moment(customDate), 'days')) ||
                originalDate.isSame(moment([Number(time.year), Number(time.month) - 1, Number(time.day)]), 'days'));
        }
        return undefined;
    }, [time, transaction]);
    return (<AppView isInTab={isWeb}>
      <StatusBar animated barStyle="dark-content"/>
      <SafeAreaTop excludeNavHeader>
        <ScrollView scrollEnabled={false} keyboardShouldPersistTaps="handled" contentContainerStyle={styles.contentContainer}>
          <KeyboardAvoidingContainer keyboardVerticalOffset={navHeaderHeight}>
            <View style={styles.inputContainer}>
              {!!transaction && (<View style={styles.titleContainer}>
                  <Text Text-24>{strings.title}</Text>
                  <Spacer h={8}/>
                  <Text TextThin-16 Secondary style={[isUsingOriginal && styles.hidden]}>
                    The original one was{' '}
                    {moment(transaction.bookingDate).format('Do MMMM, YYYY')}
                  </Text>
                </View>)}
              {!!time && <DateInput onChange={setTime} state={time} onDark/>}
            </View>
            {isUsingOriginal !== undefined && (<View>
                <TouchableOpacity style={[
                styles.revertButton,
                isUsingOriginal && styles.hidden,
            ]} onPress={onPressRevert} disabled={isUsingOriginal}>
                  <Text Text-16 Secondary>
                    Revert to original
                  </Text>
                </TouchableOpacity>
                <Button brand title="Save" isFetching={requesting} onPress={openFixedPrediction}/>
              </View>)}
          </KeyboardAvoidingContainer>
        </ScrollView>
      </SafeAreaTop>
    </AppView>);
};
const EditDateScreenWrapper = (props) => {
    usePrivateRoute();
    const { id } = props.route.params;
    const { transaction, LoadingOr404 } = useTransaction(id);
    useEffect(() => {
        if (!transaction) {
            props.navigation.setOptions({
                title: 'Edit Date',
                headerTitle: '',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (!transaction) {
        return LoadingOr404;
    }
    return <EditDateScreen {...props} transaction={transaction}/>;
};
const styles = StyleSheet.create({
    titleContainer: {
        marginBottom: rem(64),
    },
    inputContainer: {
        flex: 1,
    },
    revertButton: {
        marginBottom: rem(16),
        alignSelf: 'center',
    },
    hidden: {
        opacity: 0,
    },
    contentContainer: {
        flexGrow: 1,
    },
});
export default EditDateScreenWrapper;
