import { useCallback } from 'react';
import { useAppStore } from 'store/hooks';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { selectFeatureFlag, selectHasSetPaydayRange } from 'reducers/selectors';
import { isWeb } from '../../../constants';
const useNavigateToAnalytics = () => {
    const navigation = useMainStackNavigation();
    const store = useAppStore();
    const navigateToAnalytics = useCallback(async () => {
        const state = store.getState();
        const hasPayday = selectHasSetPaydayRange(state);
        const { didSetPeriod } = state.deviceSettings;
        const newFlow = selectFeatureFlag(store.getState(), 'new_budgeting_funnel_v2').value ||
            isWeb;
        if (!newFlow || hasPayday || didSetPeriod || isWeb) {
            navigation.navigate('Analytics');
        }
        else {
            navigation.navigate('AnalyticsIntro');
        }
    }, []);
    return navigateToAnalytics;
};
export default useNavigateToAnalytics;
