import { DELETE_ALL_INCOMES_SUCCESS, LOGGED_OUT, SET_INCOME_SUCCESS, USER_SUCCESS, } from 'actions/types';
import { REHYDRATE } from 'redux-persist/lib/constants';
import { GET_SPACES_FAILURE, GET_SPACES_REQUEST, GET_SPACES_SUCCESS, GET_SPACE_INFO_SUCCESS, SELECTED_SPACE, } from '../constants';
export const initialState = {
    spaceInfo: {},
    selectedSpace: undefined,
    previousSpace: undefined,
    fetchingMySpaces: false,
    showSpaceNotPremiumError: false,
};
const spaces = (state = initialState, action) => {
    const errorAction = action;
    if (errorAction?.apiPayload?.status === 409 &&
        errorAction?.errorName === 'SpaceNotPremiumError') {
        return {
            ...state,
            showSpaceNotPremiumError: true,
        };
    }
    switch (action.type) {
        case REHYDRATE:
            const { spaces } = action.payload;
            if (spaces)
                return {
                    ...spaces,
                    fetchingMySpaces: false,
                };
            return {
                ...state,
            };
        case GET_SPACE_INFO_SUCCESS:
            return {
                ...state,
                spaceInfo: {
                    ...state.spaceInfo,
                    [action.payload.space.id]: {
                        ...action.payload,
                        space: {
                            ...action.payload.space,
                            accountsCount: (action.payload.accounts?.length ?? 0) +
                                (action.payload.proPreviewAccounts?.length ?? 0) +
                                (action.payload.ultimatePreviewAccounts?.length ?? 0),
                            usersCount: action.payload.users?.length ?? 0,
                        },
                    },
                },
            };
        case GET_SPACES_REQUEST: {
            return {
                ...state,
                fetchingMySpaces: true,
            };
        }
        case GET_SPACES_FAILURE:
            return {
                ...state,
                fetchingMySpaces: false,
            };
        case GET_SPACES_SUCCESS:
            const spaceInfo = action.payload.spaces.reduce((prev, curr) => {
                if (state.spaceInfo[curr.id]) {
                    return {
                        ...prev,
                        [curr.id]: {
                            ...state.spaceInfo[curr.id],
                            space: curr,
                        },
                    };
                }
                return {
                    ...prev,
                    [curr.id]: { space: curr },
                };
            }, {});
            return {
                ...state,
                spaceInfo,
                fetchingMySpaces: false,
            };
        case SELECTED_SPACE:
            return {
                ...state,
                selectedSpace: action.payload,
                previousSpace: state.selectedSpace,
                showSpaceNotPremiumError: false,
            };
        case DELETE_ALL_INCOMES_SUCCESS:
        case SET_INCOME_SUCCESS:
            const id = state.selectedSpace;
            if (id === undefined || !state.spaceInfo[id]) {
                return state;
            }
            return {
                ...state,
                spaceInfo: {
                    ...state.spaceInfo,
                    [id]: {
                        ...state.spaceInfo[id],
                        space: {
                            ...state.spaceInfo[id].space,
                            currentPaydayRange: action.type === DELETE_ALL_INCOMES_SUCCESS
                                ? undefined
                                : action.payload.income.currentPaydayRange,
                        },
                    },
                },
            };
        case USER_SUCCESS:
            if (state.selectedSpace !== undefined) {
                return state;
            }
            return { ...state, selectedSpace: action.payload.defaultSpaceId };
        case LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};
export { spaces };
