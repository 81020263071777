import { Animated } from 'react-native';
import * as types from 'actions/types';
import _ from 'lodash';
import { REHYDRATE } from 'redux-persist/constants';
const initialState = {
    groups: [],
    payScreenScrollX: new Animated.Value(0),
    isFetchingGroup: false,
    transactionGroupCache: {},
    currentGroup: undefined,
    hasLoadedGroups: false,
};
const groups = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE: {
            const { groups } = action.payload;
            if (groups)
                return {
                    ...initialState,
                }; // this reducer is not persisted
            return state;
        }
        case types.LOGGED_OUT: {
            return initialState;
        }
        case types.SET_CURRENT_GROUP:
            return {
                ...state,
                currentGroup: {
                    ...action.payload,
                    users: action.payload.users || [],
                    transactions: action.payload.transactions || [],
                },
                isFetchingGroup: true,
            };
        case types.SET_CURRENT_GROUP_BY_ID_REQUEST:
            return { ...state, isFetchingGroup: true };
        case types.SET_CURRENT_GROUP_BY_ID_SUCCESS:
            const newGroupDetails = {
                users: action.payload?.analytics?.users || [],
                transactions: action.payload?.transactions || [],
                ...action.payload?.group,
                ...action.payload?.netBalance,
            };
            return {
                ...state,
                currentGroup: newGroupDetails,
                isFetchingGroup: false,
            };
        case types.SET_CURRENT_GROUP_BY_ID_FAILURE: {
            return {
                ...state,
                isFetchingGroup: false,
            };
        }
        case types.GET_GROUPS_SUCCESS:
            return { ...state, groups: action.payload.groups, hasLoadedGroups: true };
        case types.SAVE_GROUP_TRANSACTION_SUCCESS: {
            const { payload: { transaction: { id, groupId, originalTransactionId, createdBy, split, currency, }, }, } = action;
            let amount = 0;
            Object.keys(split).forEach((uid) => {
                // this is on purpose. uid is of type string, createdBy of type number. But they are both just user ID
                // eslint-disable-next-line eqeqeq
                if (uid != createdBy) {
                    amount -= split[uid];
                }
            });
            return {
                ...state,
                transactionGroupCache: {
                    ...state.transactionGroupCache,
                    [originalTransactionId]: {
                        id,
                        spendingGroupId: groupId,
                        name: state.currentGroup?.name || '',
                        amount,
                        currency,
                        iconUrl: state.currentGroup?.iconUrl,
                    },
                },
            };
        }
        case types.DELETE_GROUP_TRANSACTION_SUCCESS: {
            const { apiPayload: { url }, } = action;
            const match = url.match(/spending-groups\/(\d+)\/transactions\/(\d+)$/);
            if (match) {
                const [, spendingGroupIdString, transactionIdString] = match;
                const transactionId = parseInt(transactionIdString, 10);
                const spendingGroupId = parseInt(spendingGroupIdString, 10);
                return {
                    ...state,
                    transactionGroupCache: _.omitBy(state.transactionGroupCache, (value) => value?.id === transactionId &&
                        value?.spendingGroupId === spendingGroupId),
                };
            }
            return state;
        }
        case types.DELETE_GROUP_SUCCESS: {
            const { apiPayload: { url }, } = action;
            const match = url.match(/\/(\d+)$/);
            if (match) {
                const [, spendingGroupIdString] = match;
                const spendingGroupId = parseInt(spendingGroupIdString, 10);
                return {
                    ...state,
                    transactionGroupCache: _.omitBy(state.transactionGroupCache, (value) => value?.spendingGroupId === spendingGroupId),
                };
            }
            return state;
        }
        case types.DELETE_GROUP_USER_SUCCESS: {
            if (action.extra.isAdmin) {
                return state;
            }
            return {
                ...state,
                groups: state.groups.filter((group) => group.group.id !== action.extra.groupId),
            };
        }
        default:
            return state;
    }
};
export default groups;
