import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal, TouchableHighlight, View } from 'react-native';
import { KeyboardAwareFlatList } from 'utils/packages/rnKeyboardAwareScrollview';
import { AppView } from 'design-system/AppView';
import { marginTop, rem } from 'design-system/values';
import SearchBar from 'design-system/SearchBar';
import StatusBar from 'design-system/StatusBar';
import Text from 'design-system/Text';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { useMarginBottom } from 'hooks/useMarginBottom';
import currencies from './currencies';
const PickCurrency = ({ visible, onDismiss, onPickCancel, onSelectedCurrency, onShow: onShowProps, }) => {
    const search = useRef(null);
    const colors = useColors();
    const [countries, setCountries] = useState(currencies);
    useEffect(() => {
        if (!visible) {
            setCountries(currencies);
        }
    }, [visible]);
    const onChangeText = useCallback((data) => {
        const textWord = data.replace(
        // eslint-disable-next-line no-useless-escape
        /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ' ');
        const results = currencies.filter((code) => {
            const wordsToMatch = textWord.toLowerCase().split(' ');
            return wordsToMatch.every((word) => code.name.toLowerCase().match(word.toLowerCase()) ||
                code.code.toLowerCase().match(word.toLowerCase()));
        });
        setCountries(results);
    }, []);
    const onSearchCancel = useCallback(() => {
        onPickCancel();
        setCountries((oldState) => ({ ...oldState, countries: currencies }));
    }, [onPickCancel]);
    const onPress = useCallback((data) => {
        onSelectedCurrency(data);
        onPickCancel();
    }, [onSelectedCurrency, onPickCancel]);
    const styles = useStyles(styleSet);
    const renderItem = useCallback(({ item }) => (<TouchableHighlight underlayColor={colors.background.underlay} style={styles.row} onPress={() => onPress(item.code)}>
        <Text Text-16>
          {item.name} ({item.code})
        </Text>
      </TouchableHighlight>), [colors.background.underlay, styles.row, onPress]);
    const paddingBottom = useMarginBottom('paddingBottom');
    const renderContent = useCallback(() => (<View style={styles.content}>
        <KeyboardAwareFlatList contentContainerStyle={paddingBottom} renderItem={renderItem} data={countries} keyboardShouldPersistTaps="handled"/>
      </View>), [styles.content, paddingBottom, renderItem, countries]);
    const onShow = useCallback(() => {
        onShowProps?.();
        search.current?.focus();
    }, [onShowProps]);
    return (<Modal onDismiss={onDismiss} onShow={onShow} animationType="slide" visible={visible}>
      <AppView style={{ paddingTop: marginTop }} type="primary">
        <StatusBar barStyle="dark-content"/>
        <SearchBar ref={search} forSearchHeader onCancel={onSearchCancel} onChangeSearchTerm={onChangeText}/>
        {renderContent()}
      </AppView>
    </Modal>);
};
const styleSet = createStyleSheets((colors) => ({
    content: {
        flex: 1,
        backgroundColor: colors.background.light,
    },
    row: {
        padding: rem(16),
    },
}));
export default PickCurrency;
