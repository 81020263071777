import React from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, { interpolate, useAnimatedStyle, useDerivedValue, withTiming, } from 'react-native-reanimated';
import Svg, { Path } from 'react-native-svg';
import { Colors, rem } from 'design-system/values';
import { AnimatedTouchableBounce } from 'design-system/AnimatedComponents';
import { IconSquareCircle } from '../icons';
const ColorSelectorItem = ({ value, selected, onPress }) => {
    const press = () => {
        onPress(value[0]);
    };
    const opacity = useDerivedValue(() => withTiming(selected ? 1 : 0), [selected]);
    const scaleStyle = useAnimatedStyle(() => ({
        transform: [{ scale: interpolate(opacity.value, [0, 1], [1, 1.25]) }],
    }));
    const opacityStyle = useAnimatedStyle(() => ({
        opacity: opacity.value,
    }));
    const shadowOpacityStyle = useAnimatedStyle(() => ({
        shadowOpacity: interpolate(opacity.value, [0, 1], [0, 0.5]),
    }));
    return (<AnimatedTouchableBounce style={[styles.selectedColor, shadowOpacityStyle]} onPress={press}>
      <View style={styles.wrapper}>
        <Animated.View style={scaleStyle}>
          <IconSquareCircle width={24} height={24} color={value[1]}/>
        </Animated.View>
        <Animated.View style={[styles.strokeContainer, opacityStyle]}>
          <Svg width={rem(32)} height={rem(32)} viewBox="0 0 32 32" fill="none">
            <Path fillRule="evenodd" clipRule="evenodd" d="M5.15337 5.15337C2.96003 7.3467 1.72973 10.8173 1.72973 16C1.72973 21.1827 2.96003 24.6533 5.15337 26.8466C7.3467 29.04 10.8173 30.2703 16 30.2703C21.1827 30.2703 24.6533 29.04 26.8466 26.8466C29.04 24.6533 30.2703 21.1827 30.2703 16C30.2703 10.8173 29.04 7.3467 26.8466 5.15337C24.6533 2.96003 21.1827 1.72973 16 1.72973C10.8173 1.72973 7.3467 2.96003 5.15337 5.15337ZM0 16C0 5.14737 5.14737 0 16 0C26.8526 0 32 5.14737 32 16C32 26.8526 26.8526 32 16 32C5.14737 32 0 26.8526 0 16Z" fill="white"/>
          </Svg>
        </Animated.View>
      </View>
    </AnimatedTouchableBounce>);
};
const styles = StyleSheet.create({
    wrapper: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    selectedColor: {
        shadowColor: Colors.gray50,
        shadowOffset: { height: 5, width: 0 },
        shadowRadius: rem(8),
        elevation: 1,
        margin: rem(12),
    },
    strokeContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        margin: rem(12),
    },
});
export default ColorSelectorItem;
