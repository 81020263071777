import moment from 'moment';
import { useState } from 'react';
import { useDateTimePicker } from './useDateTimePicker';
const defaultDobMoment = moment('2000-01-01');
const getMaximumDobMoment = (minAge) => moment().add(-minAge, 'years');
export const DOB_DISPLAY_FORMAT = 'DD/MM/YYYY';
/**
 * returns dob in DD/MM/YYYY string format
 */
const useDateTimePickerDOB = (user, minAge = 18) => {
    const [dob, setDob] = useState(() => user?.dateOfBirth
        ? moment.utc(user?.dateOfBirth).format(DOB_DISPLAY_FORMAT)
        : '');
    const maxDobObj = getMaximumDobMoment(minAge);
    const [maximumDob] = useState(maxDobObj.toDate());
    const dobMoment = dob ? moment(dob, DOB_DISPLAY_FORMAT) : undefined;
    // eslint-disable-next-line no-nested-ternary
    const initialValueObj = dobMoment
        ? dobMoment <= maxDobObj
            ? dobMoment
            : defaultDobMoment
        : defaultDobMoment;
    const { showDateTimePicker } = useDateTimePicker({
        initialValue: initialValueObj,
        maximumDate: maximumDob,
        onSubmitDate: (date) => {
            setDob(moment(date).format(DOB_DISPLAY_FORMAT));
        },
    });
    /**
     * any date sent as UTC 00:00:00 should be converted to UTC to display correctly in different timezones
     * moment.utc(dateOfBirth).format('DD/MM/YYYY')
     * ore remove TZ info first and format with moment
     */
    return { showDateTimePicker, dob };
};
export default useDateTimePickerDOB;
