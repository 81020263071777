import React, { memo, useState, useCallback, useEffect, useMemo, useRef, } from 'react';
import { FlatList, Platform, StyleSheet, View, } from 'react-native';
import { rem } from 'design-system/values';
import normalize from 'common/Normalize';
import { formatPeriod } from 'utils/formatting';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { triggerSelectorPress } from 'utils/hapticFeedback';
import CategoriesHistogramBar from './CategoriesHistogramBar';
import DashedLineWithValue from './DashedLineWithValue';
import { isWeb } from '../../../../constants';
const HEIGHT = normalize(154);
const ITEM_WIDTH = rem(81); // CategoriesHistogramBar width
const getScale = (data) => {
    const array = data.map((item) => {
        if (item.value > 0 && item.spending === 0)
            return item.value;
        return item.spending;
    });
    const maxValue = Math.max(...array);
    const scale = maxValue !== 0 ? (normalize(144) * 0.7) / maxValue : 0;
    return scale;
};
const initGraph = (data) => {
    const scale = getScale(data);
    const mapped = data.map((item) => {
        if (item.value > 0 && item.spending === 0)
            return { ...item, scaledSpending: item.value * scale };
        if (!item.spending)
            return { ...item, scaledSpending: item.spending };
        return { ...item, scaledSpending: item.spending * scale };
    });
    return mapped;
};
const scaledAverage = (data, average) => {
    const scale = getScale(data);
    return (average?.amount || 0) * scale;
};
const keyExtractor = (item, index) => item.from.toString() + index;
const noop = () => { };
const CategoriesHistogram = ({ lineGraph, selectedIndex, categoryMerchantSelected, onMonthSelected, average, }) => {
    const { width: screenWidth } = useAppFrameDimensions(isWeb);
    const graph = useMemo(() => initGraph(lineGraph), [lineGraph]);
    const averagePosition = useMemo(() => scaledAverage(lineGraph, average), [lineGraph, average]);
    const [averageAmountWidth, setAverageAmountWidth] = useState(0);
    const labelLength = useMemo(() => {
        if (graph.length === 0)
            return 0;
        return formatPeriod(graph[0], true).length;
    }, [graph]);
    const shiftToCenter = useCallback((index) => {
        if (index === -1 || index > lineGraph.length - 1) {
            return;
        }
        setTimeout(() => {
            if (list)
                list.current?.scrollToIndex({
                    index,
                    viewPosition: 0,
                    animated: true,
                });
        }, 150);
    }, [lineGraph.length]);
    const initialScrollDone = useRef(false);
    useEffect(() => {
        if (!initialScrollDone.current && selectedIndex !== 0) {
            shiftToCenter(selectedIndex);
            initialScrollDone.current = true;
        }
    }, [selectedIndex, shiftToCenter]);
    const list = useRef(null);
    const onPressBar = useCallback((index) => {
        onMonthSelected(index);
        shiftToCenter(index);
    }, [onMonthSelected, shiftToCenter]);
    const renderBar = useCallback(({ index, item }) => (<CategoriesHistogramBar onPress={onPressBar} categoryMerchantSelected={categoryMerchantSelected} selectedIndex={selectedIndex} item={item} index={index}/>), [categoryMerchantSelected, onPressBar, selectedIndex]);
    const isUserScrolling = useRef(false);
    const handleScrollBeginDrag = useCallback(() => {
        isUserScrolling.current = true;
    }, []);
    const handleMomentumScrollEnd = useCallback(() => {
        if (isUserScrolling.current) {
            triggerSelectorPress();
            isUserScrolling.current = false;
        }
    }, []);
    return (<View style={{
            height: HEIGHT,
            width: screenWidth - averageAmountWidth - rem(48),
        }}>
      <FlatList ref={list} horizontal inverted contentContainerStyle={styles.listContentContainer} showsHorizontalScrollIndicator={false} onScrollToIndexFailed={noop} renderItem={renderBar} data={graph} keyExtractor={keyExtractor} snapToAlignment="start" snapToInterval={ITEM_WIDTH} decelerationRate="fast" onScrollBeginDrag={handleScrollBeginDrag} onMomentumScrollEnd={handleMomentumScrollEnd}/>
      {!!average?.amount && (<DashedLineWithValue average={average} position={averagePosition} doubleCaption={labelLength > 10} setWidth={setAverageAmountWidth}/>)}
    </View>);
};
const styles = StyleSheet.create({
    listContentContainer: {
        justifyContent: 'flex-end',
        // For the horizontallyInverted style patch in react-native
        alignItems: Platform.select({
            android: 'flex-start',
            default: 'flex-end',
        }),
        flexGrow: 1,
    },
});
export default memo(CategoriesHistogram);
