import React, { useCallback } from 'react';
import { FlatList } from 'react-native';
import { selectAllCategories } from 'reducers/selectors';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import { isDefaultCategory } from 'hooks/useCategory';
import useStyles from 'hooks/useStyles';
import { useAppSelector } from 'store/hooks';
import createStyleSheets from 'utils/createStyleSheets';
import KeyboardCategoriesRow from './KeyboardCategoriesRow';
import EmptyComponent from './KeyboardEmpty';
import { isWeb } from '../../../../constants';
const keyExtractor = (item) => item.id;
const strings = {
    empty: 'There are no categories available in any of your transactions',
};
const KeyboardCategories = ({ onSelect, contentContainerStyle }) => {
    const categories = useAppSelector(selectAllCategories);
    const { isUnlockedForSpace: isCustomCategoriesUnlockedForSpace, onPressUpgrade } = useShouldShowUpgrade({
        benefitId: 'customCategories',
    });
    const renderItem = useCallback(({ item }) => (<KeyboardCategoriesRow item={item} onPress={() => {
            if (!isCustomCategoriesUnlockedForSpace && !isDefaultCategory(item.id)) {
                onPressUpgrade();
                return;
            }
            onSelect('KeyboardCategories', {
                ...item,
                badgeText: item.displayName,
                filterType: 'CATEGORY',
            });
        }}/>), [isCustomCategoriesUnlockedForSpace, onPressUpgrade, onSelect]);
    const styles = useStyles(styleSet);
    return (<FlatList style={styles.flatList} contentContainerStyle={[styles.contentContainerStyle, contentContainerStyle]} data={categories} renderItem={renderItem} keyExtractor={keyExtractor} ListEmptyComponent={<EmptyComponent description={strings.empty}/>}/>);
};
const styleSet = createStyleSheets((colors) => ({
    contentContainerStyle: {
        paddingBottom: isWeb ? 16 : 50,
    },
    flatList: {
        backgroundColor: colors.background.light,
        ...(isWeb && {
            padding: 8,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            backgroundColor: colors.cards.onDark,
        }),
    },
}));
export default KeyboardCategories;
