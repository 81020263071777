import React, { useEffect, useState } from 'react';
import { Animated, StyleSheet, TouchableOpacity, View, } from 'react-native';
import { Colors, rem } from './values';
export const SelectorControllable = ({ sections, style, selectorStyle, selectedTextColors, unselectedTextColors, scrollX, setSelection, }) => {
    const [width, setWidth] = useState(320);
    const [itemWidth, setItemWidth] = useState(320 / sections.length);
    const onLayout = (event) => setWidth(event.nativeEvent.layout.width);
    useEffect(() => {
        setItemWidth((width - rem(4)) / sections.length);
    }, [sections, width]);
    return (<View style={[styles.container, style]} onLayout={onLayout}>
      <Animated.View style={[
            styles.selector,
            selectorStyle,
            {
                width: itemWidth,
                transform: [
                    {
                        translateX: scrollX?.interpolate({
                            inputRange: [0, 1],
                            outputRange: [0, itemWidth],
                        }),
                    },
                ],
            },
        ]}/>
      {sections.map((value, index) => (<TouchableOpacity style={[styles.item, { width: itemWidth }]} onPress={() => setSelection(index)} key={value}>
          <View style={styles.textContainer}>
            <Animated.Text style={[
                styles.text,
                {
                    color: selectedTextColors[index],
                    opacity: scrollX?.interpolate({
                        inputRange: [index - 0.3, index, index + 0.3],
                        outputRange: [0, 1, 0],
                    }),
                },
            ]} maxFontSizeMultiplier={2}>
              {value}
            </Animated.Text>
          </View>
          <View style={styles.textContainer}>
            <Animated.Text style={[
                styles.text,
                {
                    color: unselectedTextColors
                        ? unselectedTextColors[index]
                        : 'white',
                    opacity: scrollX?.interpolate({
                        inputRange: [index - 0.3, index, index + 0.3],
                        outputRange: [1, 0, 1],
                    }),
                },
            ]} maxFontSizeMultiplier={2}>
              {value}
            </Animated.Text>
          </View>
        </TouchableOpacity>))}
    </View>);
};
const styles = StyleSheet.create({
    container: {
        width: '100%',
        borderRadius: rem(20),
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: Colors.white20,
        paddingVertical: rem(2),
    },
    selector: {
        position: 'absolute',
        left: rem(2),
        top: rem(2),
        bottom: rem(2),
        borderRadius: rem(28),
        backgroundColor: Colors.white,
    },
    item: {
        height: rem(36),
        borderRadius: rem(28),
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        textAlign: 'center',
        fontFamily: 'Montserrat-Regular',
        fontSize: rem(14),
        width: '100%',
    },
    textContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
export default SelectorControllable;
