import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { useCallback } from 'react';
import { useAppStore } from 'store/hooks';
import { selectRating, selectLastReviewSessionCount } from 'reducers/selectors';
import { delay } from 'utils/delay';
import { LAST_RATE_TIME, HAS_RATED_APP_FLAG } from '../constants';
import useModalRating from './useModalRating';
// We aren't using the session logic currently but let's continue to keep it for future adjustments
const minSessions = 0;
const reviewEverySessions = 0;
const threeMonthsInMillis = 3 * 30 * 24 * 60 * 60 * 1000;
/**
 * Use this hook to start the review flow.
 * It will start if the user has reached a minimum number of sessions
 * and they have not been presented with the review flow before.
 * @returns Callback returning a boolean stating if the review flow was started
 */
const useAppRating = () => {
    const store = useAppStore();
    const { requestReview } = useModalRating();
    const checkSessionsAndShowRating = useCallback(async () => {
        const userSessions = selectRating(store.getState());
        const lastReviewSessionCount = selectLastReviewSessionCount(store.getState());
        const nextReviewSessionCount = lastReviewSessionCount
            ? lastReviewSessionCount + reviewEverySessions
            : 0;
        if (userSessions > minSessions && userSessions > nextReviewSessionCount) {
            delay(() => requestReview());
            return true;
        }
        return false;
    }, [requestReview, store]);
    const rateApp = React.useCallback(async () => {
        const review = await AsyncStorage.getItem(HAS_RATED_APP_FLAG);
        const lastRateTime = await AsyncStorage.getItem(LAST_RATE_TIME);
        const now = Date.now();
        if (review === 'true' && lastRateTime === null) {
            // uses that already rated the app before LAST_RATE_TIME is added
            // set the time and leave it
            await AsyncStorage.setItem(LAST_RATE_TIME, now.toString());
            return false;
        }
        if (lastRateTime === null ||
            now - parseInt(lastRateTime, 10) > threeMonthsInMillis) {
            await AsyncStorage.setItem(LAST_RATE_TIME, now.toString());
            return checkSessionsAndShowRating();
        }
        return false;
    }, [checkSessionsAndShowRating]);
    return rateApp;
};
export default useAppRating;
