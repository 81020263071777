import React from 'react';
import { StyleSheet, View } from 'react-native';
import TitledView from 'design-system/TitledView';
import { Chip } from 'design-system/Chip';
import DayOfTheWeekPicker from 'design-system/day-of-the-week-picker/DayOfTheWeekPicker';
import { useAppSelector } from 'store/hooks';
import { selectFeatureFlag } from 'reducers/selectors';
import { isWeb } from '../../../constants';
const chips = [
    {
        id: 1,
        text: '1st',
    },
    {
        id: 2,
        text: '2nd',
    },
    {
        id: 3,
        text: '3rd',
    },
    {
        id: 5,
        text: 'Last',
    },
];
const EveryMonthView = ({ selectedDay, onSelectDay, weekNumber, setWeekNumber, }) => {
    const newFunnel = useAppSelector((store) => selectFeatureFlag(store, 'new_budgeting_funnel_v2').value) || isWeb;
    const onTheWeekChips = React.useMemo(() => chips.map((co, i) => (<Chip key={co.id} text={co.text} id={co.id} {...(newFunnel
        ? { BrandOutline: weekNumber === co.id }
        : { Brand: weekNumber === co.id })} type={newFunnel ? 'squared' : 'rounded'} style={[styles.chip, i === chips.length - 1 && styles.lastChip]} onPress={() => {
            setWeekNumber(co.id);
        }}/>)), [newFunnel, setWeekNumber, weekNumber]);
    return (<View style={styles.container}>
      <TitledView title="On the">
        <View style={styles.chipsContainer}>{onTheWeekChips}</View>
      </TitledView>
      <DayOfTheWeekPicker selectedDay={selectedDay} onSelectDay={onSelectDay}/>
    </View>);
};
const styles = StyleSheet.create({
    container: {},
    chipsContainer: {
        flexDirection: 'row',
    },
    chip: {
        flex: 1,
    },
    lastChip: {
        marginEnd: 0,
    },
});
export default EveryMonthView;
