import { CommonActions } from '@react-navigation/native';
import { getAdditionalUserInfo, getAttributionData, getBankConnections, getFeatureFlags, getIncomes, getReferalCode, getUser, getUserAddresses, resetError, } from 'actions';
import { getFxFees } from 'actions/invest';
import { getBudgets } from 'features/budgeting/actions';
import { selectHasFxFeesFeature } from 'features/invest/reducers/selectors';
import { getPotsAccount, getPotsInformation } from 'features/pots/actions';
import { ThrowableFetchError, muteAll, muteAllButThrows, useFetchOld, } from 'hooks';
import { useCallback, useState } from 'react';
import { useAppDispatch, useAppStore } from 'store/hooks';
import Facebook from 'utils/facebook';
import checkFeatureFlag from 'utils/featureFlags';
import { getRentReporting } from 'features/rent-reporting/actions';
import { selectHasRentReporting } from 'features/rent-reporting/selectors';
import { selectListAccounts } from 'reducers/selectors';
import { getMySpaces } from 'features/spaces/actions';
import { sendReferralCode } from 'features/inviteFriend/actions';
import { UPDATE_KEYCHAIN_USER } from 'actions/types';
import { useLoginStackNavigation } from 'utils/types/navigationV6';
import { isWeb } from '../../constants';
export class LoadInitialDataError extends Error {
    constructor(e) {
        super(e instanceof ThrowableFetchError
            ? e.fetchError.errorMessage
            : 'Something went wrong, please try again.');
    }
}
export const useLoadInitialData = () => {
    const navigation = useLoginStackNavigation();
    const [, , , fetch] = useFetchOld(muteAll);
    const [, , , fetchUser] = useFetchOld(muteAllButThrows);
    const [, , , fetchAddress] = useFetchOld(muteAllButThrows);
    const [, , , fetchBankConnections] = useFetchOld(muteAllButThrows);
    const [, , , fetchFeaturesFlags] = useFetchOld(muteAllButThrows);
    const [, , , fetchAdditionalUserInfo] = useFetchOld(muteAllButThrows);
    const [, , , fetchSpaces] = useFetchOld(muteAllButThrows);
    const [isLoadingInitialData, setLoadingInitialData] = useState(false);
    const store = useAppStore();
    const dispatch = useAppDispatch();
    return {
        isLoadingInitialData,
        loadInitialData: useCallback(async (onSuccess, onError) => {
            dispatch(resetError());
            dispatch({
                type: 'SET_LAST_LOGGED_IN_TIMESTAMP_ACTION',
            });
            if (isWeb) {
                navigation.dispatch(CommonActions.reset({
                    index: 0,
                    routes: [{ name: 'Feed' }],
                }));
            }
            else {
                try {
                    setLoadingInitialData(true);
                    await Promise.all([
                        fetchUser(getUser()),
                        fetchAddress(getUserAddresses()),
                        fetchBankConnections(getBankConnections()),
                        fetchFeaturesFlags(getFeatureFlags()),
                        fetchAdditionalUserInfo(getAdditionalUserInfo()),
                    ]);
                    dispatch({ type: UPDATE_KEYCHAIN_USER });
                    const hasAccounts = selectListAccounts(store.getState()).length > 0;
                    if (!hasAccounts) {
                        // If the user has no accounts we need to load spaces to see if they do there
                        // This is so we can skip bank selection if they are in a space with accounts
                        await fetchSpaces(getMySpaces());
                    }
                    if (!isWeb) {
                        if (checkFeatureFlag(store.getState().utils.featureFlags, 'budgets_in_onboarding_before_choose_bank')) {
                            dispatch(getBudgets());
                            dispatch(getIncomes());
                        }
                        const hasRentReporting = selectHasRentReporting(store.getState());
                        const { isRenting } = store.getState().user.userAdditionalInfo;
                        if (hasRentReporting &&
                            (isRenting === null || isRenting === undefined)) {
                            await fetch(getRentReporting({
                                withRentTransactions: false,
                                isActive: true,
                            }));
                        }
                        const isGBUser = selectHasFxFeesFeature(store.getState());
                        if (isGBUser) {
                            dispatch(getFxFees());
                            dispatch(getPotsAccount());
                            dispatch(getPotsInformation());
                        }
                        const code = await getReferalCode();
                        if (code) {
                            dispatch(sendReferralCode(code));
                        }
                        Facebook.setAdvertiserTrackingEnabled(true);
                        dispatch(getAttributionData());
                    }
                    await onSuccess({
                        user: store.getState().user,
                        utils: store.getState().utils,
                    });
                }
                catch (e) {
                    onError(new LoadInitialDataError(e));
                }
                finally {
                    setLoadingInitialData(false);
                }
            }
        }, [
            fetchAdditionalUserInfo,
            fetchAddress,
            fetchBankConnections,
            fetchFeaturesFlags,
            fetchSpaces,
            fetchUser,
        ]),
    };
};
