import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Number } from './Number';
import TouchableScale from './TouchableScale';
import { Colors, rem } from './values';
import IconMoneyInputDelete from './icons/IconMoneyInputDelete';
const MoneyInputPinPad = ({ captions, onInput, color = Colors.white, style, }) => (<View style={[styles.container, style]}>
    {captions.map((value, index) => {
        if (value.length === 0) {
            return (<View key={`${value}#${index * 2}`} style={styles.elementContainer}/>);
        }
        if (value === '<') {
            return (<View key={`${value}#${index * 2}`} style={styles.elementContainer}>
            <TouchableScale testID="deleteBtn" style={styles.button} onPress={() => onInput(value)}>
              <IconMoneyInputDelete color={color}/>
            </TouchableScale>
          </View>);
        }
        return (<View key={`${value}#${index * 2}`} style={styles.elementContainer}>
          <TouchableScale style={styles.button} onPress={() => onInput(value)}>
            <Number style={[styles.number, { color }]}>{value}</Number>
          </TouchableScale>
        </View>);
    })}
  </View>);
const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    elementContainer: {
        alignItems: 'center',
        width: '33%',
    },
    button: {
        height: rem(80),
        width: rem(80),
        alignItems: 'center',
        justifyContent: 'center',
    },
    number: {
        backgroundColor: 'transparent',
        fontSize: rem(26),
        fontWeight: '800',
    },
});
export default memo(MoneyInputPinPad);
