import React, { memo, useCallback, useImperativeHandle, useMemo } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { rem } from 'design-system/values';
import SearchTransactionUpgradeBanner, { SEARCH_UPGRADE_BANNER_HEIGHT, } from 'features/search/components/SearchTransactionUpgradeBanner';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import KeyboardAccounts from './KeyboardAccounts';
import KeyboardCategories from './KeyboardCategories';
import KeyboardDates from './KeyboardDates';
import KeyboardMerchants from './KeyboardMerchants';
import KeyboardTags from './KeyboardTags';
import { colorMap, keyboardToFilterMap } from './colors';
import { TOOLBAR_BUTTONS, TOOLBAR_PADDING_VERTICAL, calculateSize } from './config';
import useKeyboard from './hooks/useKeyboard';
import styleSet from './styles';
import ToolbarButton from './icons/ToolbarButton';
import { isWeb } from '../../../../constants';
import { useKeyboardHeightWeb } from './hooks/useKeyboardHeightWeb';
const HIT_SLOP = { top: 10, bottom: 10, left: rem(4), right: rem(4) };
const SearchToolbar = React.forwardRef(({ refSearchBar, refAmountLowerInput, refAmountUpperInput, activeFilter, onSelectFilter, onRemoveFilter, onToggleAmountFilter, isUnlocked, onPressUpgrade, }, ref) => {
    const webKeyboardHeight = useKeyboardHeightWeb();
    const { paddingHorizontalStyle, width: frameWidth } = useAppFrameDimensions(isWeb);
    const { keyboardHeight, activeKeyboard, showSpecialKeyboard, hideSpecialKeyboard, keyboardHideFromScroll } = useKeyboard(activeFilter, onSelectFilter, onRemoveFilter, onToggleAmountFilter, refSearchBar);
    const onClickFilter = useCallback((keyboardId, params) => {
        onSelectFilter(params);
        if (isWeb || keyboardId !== 'KeyboardDates') {
            hideSpecialKeyboard();
            refSearchBar.current?.focus();
        }
    }, [onSelectFilter, hideSpecialKeyboard, refSearchBar]);
    useImperativeHandle(ref, () => ({
        keyboardHideFromScroll,
        showSpecialKeyboard,
    }), [keyboardHideFromScroll, showSpecialKeyboard]);
    const colors = useColors();
    const styles = useStyles(styleSet);
    const toolbarButtons = useMemo(() => {
        const size = isWeb ? 36 : calculateSize(frameWidth);
        return (<View style={[styles.toolbarContainer, { width: frameWidth }]}>
          {TOOLBAR_BUTTONS.map((button) => {
                let active = button.keyboardName === activeKeyboard;
                const filterType = keyboardToFilterMap[button.keyboardName];
                if (filterType && activeFilter[filterType]) {
                    active = true;
                }
                const bgColor = filterType ? colors.gradients[colorMap[filterType]].colors[0] : colors.background.light;
                const inactiveBgColor = isWeb ? colors.cards.onDark : colors.background.light;
                return (<ToolbarButton key={button.keyboardName} size={size} active={active} bgColor={bgColor} inactiveBgColor={inactiveBgColor} hitSlop={HIT_SLOP} icon={<button.Icon />} onPress={() => {
                        showSpecialKeyboard(button.keyboardName);
                        if (button.keyboardName === 'KeyboardAmountLower') {
                            refAmountLowerInput.current?.focus();
                        }
                        if (button.keyboardName === 'KeyboardAmountUpper') {
                            refAmountUpperInput.current?.focus();
                        }
                    }}/>);
            })}
        </View>);
    }, [
        frameWidth,
        styles.toolbarContainer,
        activeKeyboard,
        activeFilter,
        colors.gradients,
        colors.background.light,
        colors.cards.onDark,
        showSpecialKeyboard,
        refAmountLowerInput,
        refAmountUpperInput,
    ]);
    const specialKeyboard = useMemo(() => {
        if (!activeKeyboard) {
            return null;
        }
        switch (activeKeyboard) {
            case 'KeyboardCategories':
                return <KeyboardCategories onSelect={onClickFilter} contentContainerStyle={paddingHorizontalStyle}/>;
            case 'KeyboardTags':
                return <KeyboardTags onSelect={onClickFilter} contentContainerStyle={paddingHorizontalStyle}/>;
            case 'KeyboardMerchants':
                return <KeyboardMerchants onSelect={onClickFilter} contentContainerStyle={paddingHorizontalStyle}/>;
            case 'KeyboardDates':
                let dateFilter = null;
                if (activeFilter && Object.keys(activeFilter).length) {
                    dateFilter = activeFilter.DATES;
                }
                return <KeyboardDates activeDateFilter={dateFilter} onClickFilter={onClickFilter}/>;
            case 'KeyboardAccounts':
                return <KeyboardAccounts onSelect={onClickFilter} contentContainerStyle={paddingHorizontalStyle}/>;
            default:
                break;
        }
        return null;
    }, [activeKeyboard, onClickFilter, paddingHorizontalStyle, activeFilter]);
    const specialKeyboardWeb = useMemo(() => {
        if (!activeKeyboard) {
            return null;
        }
        switch (activeKeyboard) {
            case 'KeyboardCategories':
                return (<View style={[styles.keyboardWrapper, paddingHorizontalStyle]}>
              <KeyboardCategories onSelect={onClickFilter}/>
            </View>);
            case 'KeyboardTags':
                return (<View style={[styles.keyboardWrapper, paddingHorizontalStyle]}>
              <KeyboardTags onSelect={onClickFilter}/>
            </View>);
            case 'KeyboardMerchants':
                return (<View style={[styles.keyboardWrapper, paddingHorizontalStyle]}>
              <KeyboardMerchants onSelect={onClickFilter}/>
            </View>);
            case 'KeyboardDates':
                let dateFilter = null;
                if (activeFilter && Object.keys(activeFilter).length) {
                    dateFilter = activeFilter.DATES;
                }
                return (<View style={[styles.keyboardWrapper, paddingHorizontalStyle]}>
              <KeyboardDates activeDateFilter={dateFilter} onClickFilter={onClickFilter}/>
            </View>);
            case 'KeyboardAccounts':
                return (<View style={[styles.keyboardWrapper, paddingHorizontalStyle]}>
              <KeyboardAccounts onSelect={onClickFilter}/>
            </View>);
            default:
                break;
        }
        return null;
    }, [activeKeyboard, styles.keyboardWrapper, paddingHorizontalStyle, onClickFilter, activeFilter]);
    const size = calculateSize(frameWidth);
    const showWithoutKeyboard = Platform.OS === 'ios' && Platform.isPad && specialKeyboard;
    if (!isWeb && !keyboardHeight && !showWithoutKeyboard) {
        if (!isUnlocked) {
            return (<View style={styles.toolbarWrap}>
            <SearchTransactionUpgradeBanner isKeyboardVisible={false} onPress={onPressUpgrade}/>
          </View>);
        }
        return null;
    }
    const toolbarHeight = (() => {
        if (isWeb) {
            if (activeKeyboard === 'KeyboardCategories' ||
                activeKeyboard === 'KeyboardMerchants' ||
                activeKeyboard === 'KeyboardTags' ||
                activeKeyboard === 'KeyboardDates' ||
                activeKeyboard === 'KeyboardAccounts') {
                return webKeyboardHeight;
            }
            return 84;
        }
        if (keyboardHeight < 100 && showWithoutKeyboard) {
            return 300;
        }
        return keyboardHeight + (isUnlocked ? 0 : SEARCH_UPGRADE_BANNER_HEIGHT);
    })();
    return (<View style={[
            styles.toolbarWrap,
            {
                height: toolbarHeight,
            },
        ]}>
        {!isWeb && !isUnlocked && <SearchTransactionUpgradeBanner isKeyboardVisible onPress={onPressUpgrade}/>}
        <View style={styles.toolbarWrapInner}>
          <ScrollView style={{ height: size + 2 * TOOLBAR_PADDING_VERTICAL }} contentContainerStyle={[{ height: size + 2 * TOOLBAR_PADDING_VERTICAL }, paddingHorizontalStyle]} showsHorizontalScrollIndicator={false} scrollEnabled={false} keyboardShouldPersistTaps="always" horizontal>
            {toolbarButtons}
          </ScrollView>
        </View>
        {isWeb ? specialKeyboardWeb : specialKeyboard}
      </View>);
});
export default memo(SearchToolbar);
