import { REHYDRATE } from 'redux-persist/constants';
import { LOGGED_OUT } from 'actions/types';
import { GET_PAYMENTS_LIMITS_SUCCESS, } from '../actions/paymentLimits';
const initialState = {
    limits: undefined,
};
const paymentLimits = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE:
            if (action.payload?.paymentLimits) {
                const paymentLimits = action.payload?.paymentLimits;
                return {
                    ...state,
                    ...initialState,
                    ...paymentLimits,
                    hasFetched: false,
                };
            }
            return state;
        case GET_PAYMENTS_LIMITS_SUCCESS: {
            return {
                ...initialState,
                limits: action.payload,
                hasFetched: true,
            };
        }
        case LOGGED_OUT: {
            return initialState;
        }
        default:
            return state;
    }
};
export default paymentLimits;
