import React, { memo, useMemo } from 'react';
import { View } from 'react-native';
import SectionHeader from 'design-system/SectionHeader';
import Amount from 'design-system/Amount';
import { rem } from 'design-system/values';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import RowSubscription from './row/RowSubscription';
import SwitchingButton from './row/SwitchingButton';
import { isWeb } from '../../../constants';
const CommittedSpendingRow = ({ item, onOpenCompare, openSubscription, guessedHomeCountry, }) => {
    const styles = useStyles(styleSet);
    const borderStyles = useMemo(() => [
        item.isFirstInSection && styles.firstItem,
        item.isLastInSection && styles.lastItem,
    ], [
        item.isFirstInSection,
        styles.firstItem,
        styles.lastItem,
        item.isLastInSection,
    ]);
    if (item.type === 'header') {
        return (<SectionHeader horizontalMargins button={<Amount Secondary Numbers-16 value={{ value: item.total, currency: item.currency }}/>} style={styles.sectionHeader}>
        {item.title}
      </SectionHeader>);
    }
    const { data } = item;
    return (<View style={[styles.item, borderStyles]}>
      <RowSubscription switchingButton={<SwitchingButton data={data} guessedHomeCountry={guessedHomeCountry} onOpenCompare={onOpenCompare}/>} onPress={openSubscription} isCommitted isTransaction isExcluded={data.isInternal || undefined} data={data}/>
    </View>);
};
export default memo(CommittedSpendingRow);
const styleSet = createStyleSheets((colors) => ({
    sectionHeader: {
        marginTop: rem(24),
        marginBottom: rem(16),
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    firstItem: {
        ...(isWeb && {
            paddingTop: rem(8),
        }),
        borderTopLeftRadius: rem(16),
        borderTopRightRadius: rem(16),
    },
    lastItem: {
        ...(isWeb && {
            paddingBottom: rem(8),
        }),
        borderBottomLeftRadius: rem(16),
        borderBottomRightRadius: rem(16),
    },
    item: {
        marginHorizontal: rem(16),
        backgroundColor: colors.cards.onDark,
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
}));
