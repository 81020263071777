import React from 'react';
import { Platform } from 'react-native';
import Animated, { Extrapolate, interpolate, useAnimatedStyle, } from 'react-native-reanimated';
import LinearGradient from 'react-native-linear-gradient';
import useHeaderHeight from 'hooks/useHeaderHeight';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
const NavigationHeaderExtended = ({ children, scrollY, }) => {
    const styles = useStyles(styleSet);
    const colors = useColors();
    const headerHeight = useHeaderHeight();
    const headerBackground = useAnimatedStyle(() => ({
        shadowOpacity: interpolate(scrollY.value, [0, 40], [0, 1]),
    }));
    const mask = useAnimatedStyle(() => ({
        opacity: interpolate(scrollY.value, [0, 40], [0, 1], Extrapolate.CLAMP),
    }));
    const androidShadowOpacity = useAnimatedStyle(() => ({
        opacity: interpolate(scrollY.value, [0, 40], [0, 1], Extrapolate.CLAMP),
    }));
    return (<Animated.View style={[
            styles.background,
            headerBackground,
            { paddingTop: headerHeight },
        ]}>
      <Animated.View style={[styles.mask, mask]}/>
      {children}
      {Boolean(Platform.OS === 'android') && (<Animated.View style={androidShadowOpacity}>
          <LinearGradient pointerEvents="none" style={[styles.androidShadow]} colors={[
                colors.shadows.androidShadowGradientStart,
                colors.shadows.androidShadowGradientEnd,
            ]}/>
        </Animated.View>)}
    </Animated.View>);
};
const styleSet = createStyleSheets((colors) => ({
    mask: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: colors.background.light,
    },
    background: {
        position: 'absolute',
        left: 0,
        right: 0,
        zIndex: 1,
        ...colors.shadows.card,
    },
    androidShadow: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 10,
    },
}));
export default NavigationHeaderExtended;
