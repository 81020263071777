import { rem } from 'design-system/values';
import { Platform } from 'react-native';
import createStyleSheets from 'utils/createStyleSheets';
import { TOOLBAR_PADDING_VERTICAL } from './config';
import { isWeb } from '../../../../constants';
export const MARGIN_HORIZONTAL = (() => rem(16))();
export const CLEAR_TOUCH_ZONE_WIDTH = (() => rem(56))();
export const CANCEL_MARGIN = (() => rem(12))();
export const SEARCH_BAR_HEIGHT = (() => rem(48))();
export const SEARCH_BAR_MARGIN_VERTICAL = (() => rem(12))();
export const SEARCH_BAR_TOTAL_HEIGHT = SEARCH_BAR_HEIGHT + 2 * SEARCH_BAR_MARGIN_VERTICAL;
export const SEARCH_TOTAL_SPENT_MAX_HEIGHT = rem(40);
export const SEARCH_TOTAL_SPENT_HEIGHT = (() => rem(39))();
const BORDER_RADIUS = (() => rem(12))();
const styleSet = createStyleSheets((colors) => ({
    header: {
        top: 0,
        left: 0,
        right: 0,
        position: 'absolute',
        backgroundColor: isWeb ? colors.background.dark : colors.cards.onDark,
        ...colors.shadows.headerAnimatedShadow,
    },
    searchBar: {
        height: SEARCH_BAR_HEIGHT,
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: BORDER_RADIUS,
        marginLeft: MARGIN_HORIZONTAL,
        marginVertical: SEARCH_BAR_MARGIN_VERTICAL,
        backgroundColor: isWeb ? colors.cards.onDark : colors.background.dark,
    },
    scrollViewContentContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: rem(16),
    },
    withSearchIcon: {
        paddingLeft: 0,
    },
    searchInput: {
        minWidth: 140,
        fontFamily: 'Montserrat-Light',
        fontSize: rem(16),
        color: colors.text.primary,
        padding: 0,
        ...Platform.select({
            web: {
                outlineStyle: 'none',
                caretColor: colors.text.selection,
            },
        }),
    },
    closeIcon: {
        width: CLEAR_TOUCH_ZONE_WIDTH,
        height: SEARCH_BAR_HEIGHT,
        backgroundColor: isWeb ? colors.cards.onDark : colors.background.dark,
        borderTopRightRadius: BORDER_RADIUS,
        borderBottomRightRadius: BORDER_RADIUS,
        alignItems: 'center',
        justifyContent: 'center',
    },
    cancelContainer: {
        position: 'absolute',
        bottom: SEARCH_BAR_MARGIN_VERTICAL,
        height: SEARCH_BAR_HEIGHT,
        justifyContent: 'center',
    },
    toolbarWrap: {
        backgroundColor: isWeb ? colors.background.dark : colors.cards.onLight,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
    },
    toolbarWrapInner: {
        borderTopWidth: 1,
        borderColor: colors.borders.divider,
    },
    toolbarContainer: {
        flexDirection: 'row',
        paddingVertical: TOOLBAR_PADDING_VERTICAL,
        paddingHorizontal: rem(16),
        justifyContent: 'space-between',
        ...(isWeb && { alignItems: 'center' }),
    },
    noTransactionsMainContainer: {
        flex: 1,
        justifyContent: 'center',
    },
    noTransactionsContainer: {
        alignItems: 'center',
    },
    noTransactionsTitleContainer: {
        width: rem(220),
        marginBottom: rem(8),
    },
    noTransactionsTagContainer: {
        borderRadius: rem(20),
        backgroundColor: colors.cards.onDark,
    },
    noTransactionsTag: {
        height: rem(32),
        paddingHorizontal: rem(22),
        borderRadius: rem(20),
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    row: {
        flexDirection: 'row',
    },
    searchHeaderRow: {
        flexDirection: 'row',
    },
    totalSpentContainer: {
        height: SEARCH_TOTAL_SPENT_HEIGHT,
        paddingHorizontal: rem(16),
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    keyboardWrapper: {
        flex: 1,
        marginHorizontal: rem(16),
        backgroundColor: colors.background.dark,
    },
    hoverOnDark: {
        backgroundColor: colors.control.hoverOnDark,
        borderRadius: 20,
    },
}));
export default styleSet;
