import React, { useCallback, useEffect, useLayoutEffect, useRef, useState, } from 'react';
import { Linking, NativeModules, Platform, StatusBar, StyleSheet, View, } from 'react-native';
import { WebView } from 'utils/packages/webview';
import { NavigationTextButton } from 'design-system';
import { useHeaderHeight } from 'hooks';
import { selectPasscodeVisible, selectUser } from 'reducers/selectors';
import { useAppSelector } from 'store/hooks';
import Amplitude from 'utils/amplitude';
import { delay } from 'utils/delay';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import getSupportUrl from '../utils/getSupportUrl';
const { KeyboardHandler } = NativeModules;
const FOCUSED_EVENT = 'FOCUSED';
const INJECTED_JAVASCRIPT = `
function focus () {
  const textArea = document.getElementsByTagName("TextArea");
  if(textArea.length) {
    setTimeout(() => {
      textArea[0].focus();
      window.ReactNativeWebView.postMessage("${FOCUSED_EVENT}");

      setTimeout(() => {
        const scrollView = document.getElementById("cc-1n10").children[0];
        scrollView.scrollTop = scrollView.scrollHeight;
      }, 500);
    }, 0);
  } else {
    setTimeout(() => {
      focus()
    }, 1000);
  }
}

focus();
`;
const BLUR_KEYBOARD = `
  document.activeElement.blur();
`;
const SupportModal = ({ navigation, route }) => {
    const user = useAppSelector(selectUser);
    const isLoggedIn = useAppSelector((store) => store.user.isLoggedIn);
    const isPasscodeVisible = useAppSelector(selectPasscodeVisible);
    const headerHeight = useHeaderHeight();
    const webViewRef = useRef(null);
    useEffect(() => {
        if (Platform.OS === 'android') {
            webViewRef.current?.requestFocus();
        }
        Amplitude.logEvent('Support.Open');
    }, []);
    const { isUnlockedForSpace, onPressUpgrade } = useShouldShowUpgrade({
        benefitId: 'prioritySupport',
    });
    useLayoutEffect(() => {
        const { isWaitingScreen } = route.params;
        const active = isUnlockedForSpace;
        const openPriority = () => {
            webViewRef.current?.injectJavaScript(BLUR_KEYBOARD);
            delay(() => {
                onPressUpgrade();
            });
            Amplitude.logEvent('Support.openPremium');
        };
        const hasPriority = !(!active &&
            isLoggedIn &&
            !isWaitingScreen &&
            !isPasscodeVisible);
        navigation.setOptions({
            title: 'Support',
            headerTransparent: true,
            headerRight: () => hasPriority === false ? (<NavigationTextButton text="Priority" onPress={openPriority} color={{ White: true }}/>) : null,
        });
    }, [
        isLoggedIn,
        isPasscodeVisible,
        isUnlockedForSpace,
        onPressUpgrade,
        route.params,
        user,
    ]);
    const email = user ? user.email : null;
    const { nickName, url } = getSupportUrl(user);
    const object = { uri: url };
    const [loadFinished, setLoadFinished] = useState(false);
    useEffect(() => {
        if (loadFinished) {
            setTimeout(() => {
                webViewRef.current?.injectJavaScript(`
      if ($crisp) {
        $crisp.push(["set", "user:nickname", ['${nickName}']]);
      }

      true`);
            }, 1000);
        }
    }, [nickName, loadFinished]);
    useEffect(() => {
        if (loadFinished) {
            setTimeout(() => {
                webViewRef.current?.injectJavaScript(`
      if ($crisp) {
        $crisp.push(["set", "user:email", ['${email}' || '']]);
      }

      true`);
            }, 1000);
        }
    }, [email, loadFinished]);
    const onMessage = useCallback((event) => {
        if (event.nativeEvent.data === FOCUSED_EVENT) {
            if (Platform.OS === 'android') {
                KeyboardHandler.openKeyboard();
            }
            if (email) {
                webViewRef.current?.injectJavaScript(`
          if ($crisp) {
            $crisp.push(["set", "user:nickname", ['${nickName}']]);
            $crisp.push(["set", "user:email", ['${email}' || '']]);
          }
        `);
            }
            else {
                webViewRef.current?.injectJavaScript(`
          if ($crisp) {
            $crisp.push(["set", "user:nickname", ['${nickName}']]);
          }
          `);
            }
        }
    }, [email, nickName]);
    return (<View style={styles.wrap}>
      <StatusBar barStyle="light-content" animated/>
      <View style={[styles.header, { height: headerHeight }]}/>
      <WebView ref={webViewRef} bounces={false} autoManageStatusBarEnabled={false} source={object} originWhitelist={['*']} onMessage={onMessage} scalesPageToFit javaScriptEnabled startInLoadingState hideKeyboardAccessoryView automaticallyAdjustContentInsets keyboardDisplayRequiresUserAction={false} thirdPartyCookiesEnabled={false} injectedJavaScript={INJECTED_JAVASCRIPT} onShouldStartLoadWithRequest={(event) => {
            if (event.url !== url && event.url !== 'about:blank') {
                Linking.canOpenURL(event.url).then((supported) => {
                    if (supported) {
                        Linking.openURL(event.url).catch(() => { });
                    }
                });
                return false;
            }
            return true;
        }} onLoadEnd={() => {
            setLoadFinished(true);
        }}/>
    </View>);
};
const styles = StyleSheet.create({
    wrap: {
        flex: 1,
    },
    header: {
        backgroundColor: 'rgb(127,44,255)',
    },
});
export default SupportModal;
