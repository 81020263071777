import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, } from 'react';
import { FlatList, StyleSheet, View, } from 'react-native';
import { addTransactionToCache, getTransactions, isFetchingBankTransactions, } from 'actions';
import { Indicator, Spacer } from 'design-system';
import Button, { HEIGHT } from 'design-system/Button';
import { SCROLL_INDICATOR_INSETS, rem } from 'design-system/values';
import Amplitude from 'utils/amplitude';
import { getTransactionsFlatlistData, } from 'utils/getTransactions';
import { useReSelector } from 'utils/hooksApi';
import { FlashList } from '@shopify/flash-list';
import ListEmptyView from 'design-system/ListEmptyView';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import useEstimatedListSize from 'hooks/useEstimatedListSize';
import { useMarginBottom } from 'hooks/useMarginBottom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import ListLoader from 'design-system/ListLoader';
import useOpenSubscription from 'features/subscriptions/hooks/useOpenSubscription';
import useAdvancedEditTransactions from '../hooks/useAdvancedEditTransactions';
import TransactionRow from './TransactionRow';
import { isWeb } from '../../../constants';
/**
 * Height of the section header
 * Needs to be the height of the smallest row
 */
const estimatedItemSize = rem(48);
const getItemType = (item) => {
    if ('sectionTitle' in item) {
        return 'sectionHeader';
    }
    if ('prediction' in item) {
        return 'subscriptionRow';
    }
    return 'row';
};
const keyExtractor = (item) => item.id?.toString();
/**
 * needs to be used with
 * dispatch(setTransactionsRequesting);
 * before the screen mounts because of flatlist issue
 * that triggers onReachEnd on mount
 */
const TransactionsList = forwardRef(({ data, navigation, onScrollY, contentContainerStyle, children, ListHeaderComponent, upcoming, openSubscriptions, from, to, accountId: id, accountIdsStr, showExcludedTransactions = true, reducedTopPadding, }, ref) => {
    const dispatch = useAppDispatch();
    const initialFetched = useRef(false);
    useEffect(() => {
        const dateFrom = from || null;
        const dateTo = to || null;
        dispatch(isFetchingBankTransactions(id || 'ALL', { from, to }, showExcludedTransactions, accountIdsStr));
        dispatch(getTransactions(id, null, null, dateFrom || undefined, dateTo || undefined, null, 1, null, null, !showExcludedTransactions, accountIdsStr));
        initialFetched.current = true;
    }, [accountIdsStr, dispatch, from, id, showExcludedTransactions, to]);
    const { selected, isEditing, selectedObj, onEdit, onSelected, openEditModal, } = useAdvancedEditTransactions(navigation);
    const store = useReSelector((store) => ({
        transactions: store.transactions.transactionsArray,
        pageCount: store.transactions.pageCount,
        page: store.transactions.page,
        isFetching: store.transactions.isFetching,
        isRefreshing: store.transactions.isRefreshing,
        lastAccount: store.transactions.lastAccount,
    }));
    const shouldLoadSecondPage = useRef(false);
    const loadMore = useCallback(() => {
        const { isRefreshing, pageCount, page, transactions } = store;
        if (!isRefreshing &&
            pageCount &&
            page &&
            page <= pageCount &&
            initialFetched.current) {
            dispatch(getTransactions(id, null, null, from, to, null, page + 1, null, null, !showExcludedTransactions, accountIdsStr));
        }
        else if (isRefreshing && page === 1 && transactions.length === 60) {
            shouldLoadSecondPage.current = true;
        }
    }, [accountIdsStr, from, id, showExcludedTransactions, store, to]);
    useEffect(() => {
        if (!store.isRefreshing && shouldLoadSecondPage.current) {
            loadMore();
            shouldLoadSecondPage.current = false;
        }
    }, [loadMore, store.isRefreshing]);
    const onEndReached = useCallback(() => {
        loadMore();
    }, [loadMore]);
    // useEffect(() => {
    //   onEditCallbackChanged(onEdit);
    // }, [onEditCallbackChanged, onEdit]);
    useImperativeHandle(ref, () => ({
        onEdit,
    }), [onEdit]);
    const onCompare = useCallback((routeWithParams) => {
        navigation.navigate(routeWithParams);
        Amplitude.logEvent('Transactions.openCompare', {
            type: routeWithParams.name,
        });
    }, []);
    const openItem = useCallback((item) => {
        if ('sectionTitle' in item)
            return;
        const { isPending } = item;
        if (isEditing) {
            if (isPending)
                return;
            onSelected(item);
            return;
        }
        dispatch(addTransactionToCache(item));
        navigation.navigate('Item', {
            id: item.id,
        });
    }, [isEditing, onSelected]);
    const { isUnlockedForSpace: isRecurringPaymentsUnlocked } = useShouldShowUpgrade({
        benefitId: 'recurringPayments',
    });
    const { isUnlockedForSpace: isSearchUnlockedForSpace, onPressUpgrade: onPressUpgradeForSearch, } = useShouldShowUpgrade({
        benefitId: 'search',
    });
    const openSubscription = useOpenSubscription();
    const guessedHomeCountry = useAppSelector((store) => store.user.user.guessedHomeCountry);
    const isEditButtonVisible = isEditing && selected.length !== 0;
    const { paddingHorizontalStyle, width, paddingHorizontal } = useAppFrameDimensions(isWeb);
    const renderRow = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ item, index }) => {
        const isFirstItem = index === 0;
        return (<TransactionRow item={item} openItem={openItem} onCompare={onCompare} isEditing={isEditing} isSelected={!!selectedObj[item.id]} openSubscription={openSubscription} openSubscriptions={openSubscriptions} guessedHomeCountry={guessedHomeCountry} showUpgradeButton={'showUpgradeButton' in item && item.showUpgradeButton} onPressUpgradeForSearch={onPressUpgradeForSearch} blurSubscriptionValues={!isRecurringPaymentsUnlocked} style={isFirstItem && reducedTopPadding
                ? styles.reducedPaddingTop
                : undefined}/>);
    }, [
        guessedHomeCountry,
        isEditing,
        isRecurringPaymentsUnlocked,
        onCompare,
        onPressUpgradeForSearch,
        openItem,
        openSubscription,
        openSubscriptions,
        reducedTopPadding,
        selectedObj,
    ]);
    const advancedUpcomingSubs = useMemo(() => {
        if (upcoming?.length) {
            const upcomingIndexLength = upcoming.length - 1;
            const advancedUpcoming = upcoming.map((eachUpcoming, index) => ({
                ...eachUpcoming,
                isFirstInSection: index === 0,
                sectionKey: 'Upcoming transactions',
                isLastInSection: index === upcomingIndexLength,
            }));
            return [
                {
                    id: 'Upcoming',
                    hasSeeAll: true,
                    sectionTitle: 'Upcoming transactions',
                },
                ...advancedUpcoming,
            ];
        }
        return [];
    }, [upcoming]);
    const sectionedData = useMemo(() => {
        if (id && store.isFetching && !store.transactions.length)
            return [];
        const flashListData = getTransactionsFlatlistData(store.transactions, !isSearchUnlockedForSpace);
        return [...advancedUpcomingSubs, ...flashListData];
    }, [
        advancedUpcomingSubs,
        id,
        isSearchUnlockedForSpace,
        store.isFetching,
        store.transactions,
    ]);
    const onScroll = useCallback((event) => {
        onScrollY(event.nativeEvent.contentOffset.y);
    }, [onScrollY]);
    const emptyOrLoading = useMemo(() => (<View>
          {store.isFetching ? (<View style={!!id && styles.withHeaderIndicatorMarginTop}>
              {id ? <Indicator dark/> : <ListLoader />}
            </View>) : (<>
              <Spacer.H16 />
              <ListEmptyView onCard title="No transactions" subTitle="There are no transactions in your account"/>
            </>)}
        </View>), [id, store.isFetching]);
    const estimatedListSize = useEstimatedListSize(store.transactions);
    const paddingBottom = useMarginBottom('paddingBottom', isEditButtonVisible ? HEIGHT + rem(16) : undefined);
    const lastItem = sectionedData[sectionedData.length - 1];
    const hasUpgradeButton = lastItem && 'showUpgradeButton' in lastItem && lastItem.showUpgradeButton;
    const customStyles = useMemo(() => ({
        ...(hasUpgradeButton && !isEditButtonVisible ? {} : paddingBottom),
        ...contentContainerStyle,
        ...paddingHorizontalStyle,
        // This is a workaround on web because the scroll bar is counted as part of the width
        // which messes up the investment account graph header
        ...(isWeb && {
            width,
            paddingHorizontal: 0,
            marginLeft: id ? 0 : paddingHorizontal,
            alignSelf: id ? 'center' : 'auto',
        }),
    }), [
        hasUpgradeButton,
        isEditButtonVisible,
        paddingBottom,
        contentContainerStyle,
        paddingHorizontalStyle,
        width,
        id,
        paddingHorizontal,
    ]);
    const extraData = useMemo(() => [
        selected,
        openItem,
        isEditing,
        onCompare,
        onSelected,
        data?.needsReauth,
        data?.needsReconsent,
        openSubscription,
        openSubscriptions,
        guessedHomeCountry,
    ], [
        data?.needsReauth,
        data?.needsReconsent,
        guessedHomeCountry,
        isEditing,
        onCompare,
        onSelected,
        openItem,
        openSubscription,
        openSubscriptions,
        selected,
    ]);
    const baseProps = {
        extraData,
        onScroll,
        data: sectionedData,
        renderItem: renderRow,
        scrollEventThrottle: 16,
        onEndReachedThreshold: 5,
        keyExtractor,
        onEndReached,
        ListEmptyComponent: emptyOrLoading,
        contentContainerStyle: customStyles,
        ListHeaderComponent,
        scrollIndicatorInsets: SCROLL_INDICATOR_INSETS,
    };
    return (<>
        {isWeb ? (<FlatList {...baseProps}/>) : (<FlashList {...baseProps} getItemType={getItemType} estimatedItemSize={estimatedItemSize} estimatedListSize={estimatedListSize}/>)}
        {isEditButtonVisible ? (<Button brand floating title={`Edit (${selected.length}) Transactions`} onPress={openEditModal}/>) : (children)}
      </>);
});
const styles = StyleSheet.create({
    extraMarginTop: {
        marginTop: '70%',
    },
    withHeaderIndicatorMarginTop: {
        marginTop: '45%',
    },
    reducedPaddingTop: {
        paddingTop: rem(16),
    },
});
export default memo(TransactionsList);
