import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import { Colors, rem } from 'design-system/values';
import IconDecrease from 'features/subscriptions/icons/IconDecrease';
import IconIncrease from 'features/subscriptions/icons/IconIncrease';
import { selectIsScrambled } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { formatAmount } from 'utils/formatting';
const PriceChange = ({ priceChange, currency }) => {
    const scrambled = useAppSelector(selectIsScrambled);
    if (!priceChange) {
        return null;
    }
    if (priceChange > 0) {
        return (<View style={styles.container}>
        <IconIncrease style={styles.icon}/>
        <Text TextThin-14 Gray style={[styles.description]}>
          Increased by{' '}
          <Text Text-14 Red>
            {formatAmount(Math.abs(priceChange), currency, 2, scrambled)}
          </Text>
        </Text>
      </View>);
    }
    return (<View style={styles.container}>
      <IconDecrease style={styles.icon}/>
      <Text TextThin-14 Gray style={[styles.description]}>
        Decreased by{' '}
        <Text Text-14 Green>
          {formatAmount(Math.abs(priceChange), currency, 2, scrambled)}
        </Text>
      </Text>
    </View>);
};
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        marginEnd: rem(8),
    },
    description: {
        color: Colors.label,
    },
});
export default memo(PriceChange);
