import React from 'react';
import { ClipPath, Defs, G, Path, Rect, Svg } from 'react-native-svg';
import { Colors, rem } from 'design-system/values';
const defaultColor = Colors.red;
const IconIncrease = ({ color = defaultColor, style }) => {
    const width = rem(16);
    const height = rem(16);
    return (<Svg width={width} height={height} style={style} viewBox="0 0 16 16" fill="none">
      <Path d="M15.7452 10.0382C15.3722 13.022 13.022 15.3722 10.0382 15.7452C8.68467 15.9144 7.31533 15.9144 5.96178 15.7452C2.97799 15.3722 0.627754 13.022 0.254779 10.0382C0.0855872 8.68467 0.0855873 7.31533 0.25478 5.96178C0.627754 2.97799 2.97799 0.627752 5.96179 0.254778C7.31533 0.0855858 8.68467 0.0855859 10.0382 0.254778C13.022 0.627753 15.3722 2.97799 15.7452 5.96178C15.9144 7.31533 15.9144 8.68467 15.7452 10.0382Z" fill={color}/>
      <G clipPath="url(#clip0_9765_17027)">
        <Path d="M8.754 4.25845C8.79267 4.298 8.958 4.44735 9.094 4.58646C9.94933 5.40205 11.3493 7.52966 11.7767 8.64325C11.8453 8.81237 11.9907 9.23994 12 9.46839C12 9.68729 11.952 9.89596 11.8547 10.0951C11.7187 10.3433 11.5047 10.5424 11.252 10.6515C11.0767 10.7218 10.552 10.8309 10.5427 10.8309C9.96867 10.94 9.036 11 8.00533 11C7.02333 11 6.12867 10.94 5.546 10.8507C5.53667 10.8404 4.88467 10.7313 4.66133 10.612C4.25333 10.3931 4 9.96551 4 9.50794L4 9.46839C4.01 9.17038 4.26333 8.54369 4.27267 8.54369C4.70067 7.49011 6.032 5.41159 6.91667 4.57623C6.91667 4.57623 7.144 4.34096 7.286 4.23868C7.49 4.0791 7.74267 4 7.99533 4C8.27733 4 8.54 4.08933 8.754 4.25845Z" fill="white"/>
      </G>
      <Defs>
        <ClipPath id="clip0_9765_17027">
          <Rect width="8" height="8" fill="white" transform="translate(4 4)"/>
        </ClipPath>
      </Defs>
    </Svg>);
};
export default IconIncrease;
