import React from 'react';
import { IconEmma } from 'design-system/icons';
import SubscriptionEditTypeModal from 'features/subscriptions/components/modal/SubscriptionEditTypeModalContent';
const CreateSubscriptionModal = ({ onPressConfirm, onCancel, }) => {
    const pressedConfirm = () => {
        onPressConfirm();
    };
    const pressedCancel = () => {
        onCancel();
    };
    return (<SubscriptionEditTypeModal icon={<IconEmma />} confirm={"Yes, let's do it"} title="Recurring Payment" subTitle="Would you like to mark this as a recurring payment?" onConfirm={pressedConfirm} onCancel={pressedCancel}/>);
};
export default CreateSubscriptionModal;
