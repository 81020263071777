import { REHYDRATE } from 'redux-persist/constants';
import * as types from '../actions/types';
const initialState = {
    errorsData: [],
    errorsHistory: [], // used to keep a track of errors for RetrySpinner
};
const error = (state = initialState, action) => {
    if (action.type === REHYDRATE) {
        const errorData = action.payload.error;
        if (errorData)
            return { ...initialState };
        return state;
    }
    // Successfull call, we keep the state as it is
    if (action.type && action.type.includes('SUCCESS')) {
        return state;
    }
    // Erase all errors
    if (action.type === types.RESET_ERROR) {
        return {
            ...state,
            errorsData: [],
        };
    }
    // Erase specific error from history
    if (action.type === types.CLEAR_HISTORY_ERROR) {
        const newErrorsHistory = state.errorsHistory.filter((item) => item !== action.errorToClear);
        return {
            ...state,
            errorsHistory: newErrorsHistory,
        };
    }
    // Erase all errors
    if (action.type === types.LOGGED_OUT) {
        return initialState;
    }
    // Not an error, we keep the state as it is
    if (!action.error) {
        return state;
    }
    // Create an history of all api Errors for the session
    const errorsHistory = state.errorsHistory || [];
    if (!errorsHistory.includes(action.type)) {
        errorsHistory.push(action.type);
    }
    // Add new error to the list of errors
    return {
        ...state,
        errorsData: [...state.errorsData, action],
        errorsHistory,
    };
};
export default error;
