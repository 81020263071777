import { useCallback, useEffect } from 'react';
import { Keyboard, Platform } from 'react-native';
import { useAnimatedStyle, useSharedValue, withTiming, runOnJS } from 'react-native-reanimated';
import { delay } from 'utils/delay';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { MARGIN_HORIZONTAL, CANCEL_MARGIN } from '../styles';
const useAnimatedStyles = (onCancel, onClearSearch, refSearchBar, delayFocusTime) => {
    const cancelShownProgress = useSharedValue(0);
    const cancelWidth = useSharedValue(0);
    const { width } = useAppFrameDimensions();
    useEffect(() => {
        delay(() => {
            cancelShownProgress.value = withTiming(1, { duration: 150 });
            refSearchBar.current?.focus();
        }, delayFocusTime ?? 350);
    }, [cancelShownProgress, delayFocusTime, refSearchBar]);
    const searchBarAnimatedStyle = useAnimatedStyle(() => ({
        width: width - MARGIN_HORIZONTAL * 2 - cancelShownProgress.value * (CANCEL_MARGIN + cancelWidth.value),
    }), [width]);
    const cancelAnimatedStyle = useAnimatedStyle(() => ({
        left: width - cancelShownProgress.value * (cancelWidth.value + MARGIN_HORIZONTAL),
    }), []);
    const onCancelLayout = useCallback((event) => {
        if (!cancelWidth.value) {
            cancelWidth.value = event.nativeEvent.layout.width;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleCancel = useCallback(async () => {
        const cleanup = async () => {
            Keyboard.dismiss();
            await onCancel();
            onClearSearch();
        };
        if (Platform.OS === 'ios') {
            cancelShownProgress.value = withTiming(0, { duration: 150 }, () => {
                runOnJS(cleanup)();
            });
        }
        else {
            await cleanup();
            cancelShownProgress.value = 0;
        }
    }, [onCancel, onClearSearch, cancelShownProgress]);
    return [searchBarAnimatedStyle, cancelAnimatedStyle, cancelWidth, onCancelLayout, handleCancel];
};
export default useAnimatedStyles;
