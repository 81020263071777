import React, { useLayoutEffect } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { Indicator } from 'design-system/Indicator';
import { SettingsItem, SettingsItemSwitch } from 'design-system/SettingsItem';
import { SettingsSection } from 'design-system/SettingsSection';
import { AppView } from 'design-system/AppView';
import { rem } from 'design-system/values';
import * as Icons from 'design-system/icons';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import { useScrambleMode } from 'hooks';
import useColors from 'hooks/useColors';
import { useSmartRulesNavigation } from 'hooks/useSmartRulesNavigation';
import { useAppSelector } from 'store/hooks';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import usePrivateRoute from 'hooks/usePrivateRoute';
import { selectFeatureFlag } from 'reducers/selectors';
import { SecuritySettingsSection } from './SecuritySettingsSection';
import { DeleteAccountSettingsSection } from './DeleteAccountSettingsSection';
import { HelpButton } from './AccountSettingsScreen';
import { isWeb } from '../../../constants';
const strings = {
    headerTitle: 'Account',
    personalDetails: {
        title: 'Personal Details',
    },
    scrambleMode: {
        title: 'Scramble Mode',
        description: 'Hide your actual amount',
    },
    dataExport: {
        title: 'Export Your Data',
    },
    fraudDetection: {
        title: 'Fraud Detection',
        description: 'Monitor your emails and phone numbers on the dark web',
    },
    smartRules: {
        title: 'Smart Rules',
    },
    web: {
        title: 'Emma for Web',
    },
    restorePurchases: {
        title: 'Restore in-app purchases',
    },
    bankLogins: {
        title: 'Bank Logins',
    },
};
export const AccountSettingsList = withAnimatedNavigationHeader(({ navigation, onScroll }) => {
    usePrivateRoute();
    const userId = useAppSelector((state) => state.user.user.id);
    useLayoutEffect(() => {
        if (!isWeb) {
            navigation.setOptions({
                headerRight: () => <HelpButton />,
            });
        }
    }, []);
    const colors = useColors();
    const onPressSmartRules = useSmartRulesNavigation();
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const onExportYourData = () => navigation.navigate('ExportingPeriod');
    const { setScrambled, scrambled } = useScrambleMode();
    const webQRCodeScanner = useAppSelector((store) => selectFeatureFlag(store, 'web_qr_code_scanner_in_settings').value);
    if (userId === -1) {
        return (<AppView type="secondary" withFrame={false}>
          <Indicator dark/>
        </AppView>);
    }
    return (<AppView withFrame={false}>
        <ScrollView onScroll={onScroll} style={paddingHorizontalStyle} scrollEventThrottle={16}>
          <SettingsSection noMarginTop style={styles.marginTop}>
            <SettingsItem title={strings.personalDetails.title} onPress={() => navigation.navigate('PersonalDetails')} icon={<Icons.IconProfile />}/>
            <SecuritySettingsSection />
            {!isWeb && webQRCodeScanner && (<SettingsItem title={strings.web.title} onPress={() => {
                navigation.navigate('WebQRCodeScanner');
            }} icon={<Icons.IconScan alt colorKey="brand"/>}/>)}
          </SettingsSection>

          {!isWeb && (<>
              <SettingsSection noMarginTop style={styles.marginTop}>
                <SettingsItemSwitch value={scrambled} title={strings.scrambleMode.title} description={strings.scrambleMode.description} onValueChange={setScrambled} icon={<Icons.IconMagicWand />}/>
              </SettingsSection>

              <SettingsSection noMarginTop style={styles.marginTop}>
                <SettingsItem title={strings.dataExport.title} onPress={onExportYourData} icon={<Icons.IconDownload />}/>
                <SettingsItem title={strings.smartRules.title} onPress={onPressSmartRules} icon={<Icons.IconLightBulb alt gradientColors={colors.gradients.plus.colors}/>}/>
                <SettingsItem title={strings.bankLogins.title} onPress={() => navigation.navigate('Logins', {})} icon={<Icons.IconBank />}/>
              </SettingsSection>

              <DeleteAccountSettingsSection />
            </>)}
        </ScrollView>
      </AppView>);
}, ({ colors }) => ({
    title: strings.headerTitle,
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styles = StyleSheet.create({
    marginTop: {
        marginTop: rem(16),
    },
});
