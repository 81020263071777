import React, { useCallback } from 'react';
import { View, FlatList } from 'react-native';
import Animated, { withTiming, useDerivedValue, useAnimatedStyle, } from 'react-native-reanimated';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import CategoryItem from './CategoryItem';
import { EMOJI_CATEGORIES as Categories } from './types';
const CategoryContainer = ({ width, emojisArr, itemSize, flatListRef, selectedCategory, setSelectedCategory, isMomentumScrolling, }) => {
    const categoryItemWidth = (width - rem(32)) / Categories.length;
    const styles = useStyles(styleSet);
    const listOffset = useDerivedValue(() => withTiming(selectedCategory === undefined
        ? 0
        : Categories.indexOf(selectedCategory) *
            categoryItemWidth), [selectedCategory]);
    const listOffsetStyle = useAnimatedStyle(() => ({
        transform: [{ translateX: listOffset.value }],
    }));
    const renderCategoryItem = useCallback(({ item }) => (<View style={[
            {
                width: categoryItemWidth,
            },
            styles.eachCatContainer,
        ]}>
        <CategoryItem category={item} scrollToIndex={() => {
            if (isMomentumScrolling.current)
                return;
            const colIndex = emojisArr.findIndex((column) => column[0].category === item);
            if (colIndex > -1) {
                setTimeout(() => {
                    flatListRef.current?.scrollToOffset({
                        animated: false,
                        offset: colIndex * itemSize,
                    });
                });
                setSelectedCategory(item);
            }
        }} selectedCategory={selectedCategory}/>
      </View>), [
        categoryItemWidth,
        styles.eachCatContainer,
        selectedCategory,
        isMomentumScrolling,
        emojisArr,
        setSelectedCategory,
        flatListRef,
        itemSize,
    ]);
    return (<View>
      <Animated.View style={[
            styles.selectorContainer,
            { width: categoryItemWidth },
            listOffsetStyle,
        ]}>
        <View style={styles.selector}/>
      </Animated.View>
      <FlatList horizontal data={Categories} scrollEnabled={false} renderItem={renderCategoryItem} showsHorizontalScrollIndicator={false}/>
    </View>);
};
export default CategoryContainer;
const styleSet = createStyleSheets((colors) => ({
    selectorContainer: {
        top: 0,
        left: 0,
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
    },
    selector: {
        height: rem(30),
        width: rem(30),
        alignItems: 'center',
        borderRadius: rem(15),
        justifyContent: 'center',
        backgroundColor: colors.background.dark,
    },
    eachCatContainer: {
        alignItems: 'center',
    },
}));
