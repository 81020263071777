import moment from 'moment';
import { REHYDRATE } from 'redux-persist/constants';

import { SKIP_PASSCODE } from 'config/debug';
import { NET_SCORE_SUCCESS } from 'features/net-promoter-score/actions/types';
import {
  REVERSE_TRIAL_ACKNOWLEDGE_END_SUCCESS,
  REVERSE_TRIAL_ACKNOWLEDGE_START_SUCCESS,
  REVERSE_TRIAL_ACTIVATE_SUCCESS,
  REVERSE_TRIAL_CANCEL_SUCCESS,
  REVERSE_TRIAL_CONVERSION_EVENT_SUCCESS,
  REVERSE_TRIAL_DELETE_FUNNEL_START_SUCCESS,
  SET_UP_STRIPE_INTENT_CONFIRM_SUCCESS,
} from 'features/premium/actions/types';
import { SELECTED_SPACE } from 'features/spaces/constants';
import * as types from '../actions/types';

export const initialState = {
  user: {
    id: -1,
    firstName: '',
    email: '',
    connections: [],
    guessedHomeCountry: '',
    emmaProStatus: { active: false },
    lastName: '',
    urlHandle: '',
  },
  userAdditionalInfo: {},
  isEmmaProVisible: false,
  emmaProPlusScreenTier: null,
  promoProductId: null,
  isInviteVisible: false,
  isLoggedIn: false,
  isPasscodeOn: true,
  isWaiting: false,
  isOnboarding: false,
  isCategorizingStatus: false,
  pinLastChangedAt: null,
  isSyncingManual: false,
  lastChange: false,
  statusMessage: null,
  connectionsError: null,
  actionIsRequired: false,
  isFetching: false,
  passCode: true,
  passCodeFailed: null,
  isTouchActive: true,
  connections: [],
  error: null,
  errorMessage: null,
  preferences: {},
  categories: [],
  customCategories: [],
  incomes: [],
  rating: 0,
  isFetchingLogin: false,
  verifyingEmail: null,
  emmaUsers: { emails: [], numbers: [] },
  lastSession: { time: null, state: null },
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      const { user } = action.payload;
      const returnState = JSON.parse(JSON.stringify(initialState));
      if (user)
        return Object.assign(returnState, user, {
          isEmmaProOffer: false,
          isInviteVisible: false,
          isPasscodeOn: __DEV__ ? !SKIP_PASSCODE : true,
          isSupportOpen: false,
          isEmmaProVisible: false,
          emmaProPlusScreenTier: null,
          promoProductId: null,
          lastSession: { time: null, state: null },
          isFetching: false,
          /**
           * clearing the keys that were there in the past
           * moved to addressReducer
           *
           * START
           */
          selectedAddress: undefined,
          userAddresses: undefined,
          addressesList: undefined,
          /**
           * clearing the keys that were there in the past
           * moved to addressReducer
           *
           * END
           */
          userAdditionalInfo:
            user.userAdditionalInfo ?? returnState.userAdditionalInfo,
          categories: user.categories ?? [],
          customCategories: user.customCategories ?? [],
          incomes: [],
          error: null,
          errorMessage: null,
          verifyingEmail: user.verifyingEmail ?? returnState.verifyingEmail,
          isFetchingLogin: false,
          pinLastChangedAt: user?.pinLastChangedAt,
          emmaUsers: { emails: [], numbers: [] },
          preferences: {},
          user: user.user ?? returnState.user,
        });
      return state;

    case types.POST_ADDITIONAL_USER_INFO_SUCCESS:
      return {
        ...state,
        userAdditionalInfo: action.payload.userAdditionalInfo,
      };
    case types.SET_LAST_SESSION:
      return {
        ...state,
        lastSession: { time: action.payload.time, state: action.payload.state },
      };
    case types.DELETE_INCOME_SUCCESS:
      return {
        ...state,
        incomes: state.incomes.filter(({ id }) => id !== action.extra),
      };
    case types.SET_PRIMARY_INCOME_SUCCESS:
      return {
        ...state,
        incomes: state.incomes.map((item) => {
          if (action.extra === item.id) {
            return { ...item, isPrimary: true };
          }
          return { ...item, isPrimary: false };
        }),
      };
    case types.DELETE_ALL_INCOMES_SUCCESS:
      return { ...state, user: { ...state.user, currentPaydayRange: null } };
    case types.SET_INCOME_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          currentPaydayRange: action.payload.income.currentPaydayRange,
        },
        incomes: state.incomes
          ? state.incomes.map((income) =>
              income.isPrimary && action.payload.income.isPrimary
                ? action.payload.income
                : income
            )
          : [action.payload.income],
      };
    case types.GET_INCOME_REQUEST:
      return { ...state, isFetching: true };
    case types.GET_INCOME_FAILURE:
      return { ...state, isFetching: false };
    case types.GET_INCOME_SUCCESS:
      return { ...state, incomes: action.payload.incomes, isFetching: false };
    case types.SESSION_COUNT:
      return { ...state, rating: state.rating + 1 };
    case types.IS_FETCHING_USER:
      return { ...state, isFetching: true };
    case types.USER_FAILURE:
      return { ...state, isFetching: false };
    case types.GET_ADDITIONAL_USER_INFO_SUCCESS:
      return {
        ...state,
        userAdditionalInfo: action.payload.userAdditionalInfo,
      };

    case types.POST_USER_NATIONALITIES_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          nationalities: action.extra.countries,
        },
      };
    }
    case types.USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isFetching: false,
        // resetPasscodeNextStep: 'set_new_pin',
        resetPasscodeNextStep: action.payload.isPinResettable
          ? 'set_new_pin'
          : undefined,
      };
    case types.REGISTER_USER_REQUEST:
      return { ...state, isFetching: true };
    case types.REGISTER_USER_FAILURE:
      return { ...state, isFetching: false };
    case types.REGISTER_USER_SUCCESS:
      return { ...state, isFetching: false, user: action.payload };
    case types.IS_EMMA_PRO_MODAL_OPEN:
      return {
        ...state,
        isEmmaProVisible: action.payload.visible,
        promoProductId: action.payload.promoProductId,
        emmaProPlusScreenTier:
          action.payload.tierView || state.emmaProPlusScreenTier,
      };
    case types.PASSCODE_ON:
      return { ...state, isPasscodeOn: true };
    case types.PASSCODE_OFF:
      return { ...state, isPasscodeOn: false };
    case types.SET_SETUP_PASSCODE:
      return { ...state, passCodeFailed: null };
    case types.SET_PASSCODE_SUCCESS:
      return {
        ...state,
        passCode: true,
        passCodeFailed: null,
        isPasscodeOn:
          action.payload.turnPasscodeOff || action.extra.turnPasscodeOff
            ? false
            : state.isPasscodeOn,
        isResettingPasscode: false,
        resetPasscodeNextStep: null,
        user: {
          ...state.user,
          pinLastChangedAt: action.payload.pinLastChangedAt,
        },
      };
    case types.SET_PASSCODE_IN_KEYCHAIN_SUCCESS:
      return {
        ...state,
        pinLastChangedAt: action.payload.pinLastChangedAt,
      };
    case types.VERIFY_PASSCODE_SUCCESS:
      return {
        ...state,
        passCode: true,
        passCodeFailed: null,
        user: {
          ...state.user,
          pinLastChangedAt: action.payload.pinLastChangedAt,
        },
      };
    case types.SET_PASSCODE_FAILURE:
      return { ...state, passCodeFailed: true };
    case types.SET_WAITING_STATUS:
      return { ...state, isWaiting: action.payload };
    case types.SET_FIRST_BOARDING:
      return { ...state, isOnboarding: action.payload };
    case types.SET_CONNECTIONS_STATUS:
      return { ...state, isCategorizingStatus: action.payload };
    case types.PASSCODE_SETUP_FAILED:
      return { ...state, passCodeFailed: true };
    case types.LOGIN_REQUEST:
      return { ...state, isFetchingLogin: true };
    case types.LOGIN_REQUEST_COMPLETED:
      return { ...state, isFetchingLogin: false };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isPasscodeOn: false, // If the user had a passcode, the sign in process would've handled this
        isLoggedIn: true,
        error: null,
        errorMessage: null,
      };
    case types.RESET_ERROR:
      return {
        ...state,
        error: null,
        errorMessage: null,
        isFetching: false,
        isFetchingLogin: false,
      };
    case types.LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: 1,
        errorMessage: action.payload,
      };
    case types.SET_TOUCH_ID:
      return { ...state, isTouchActive: action.payload };
    case types.SYNC_SUCCESS:
      return {
        ...state,
        isSyncingManual: action.payload.isSyncingManual,
        statusMessage: action.payload.statusMessage,
        lastChange: action.payload.lastSuccessfulSync,
      };
    case types.SYNC_ALL_REQUEST:
      return { ...state };
    case types.SYNC_ALL_FAILURE:
      return { ...state };
    case types.SYNC_ALL_SUCCESS:
      return {
        ...state,
        isSyncingManual: action.payload.isSyncingManual,
        statusMessage: action.payload.statusMessage,
        actionIsRequired: action.payload.actionIsRequired,
        connectionsError: action.payload.errorMessage,
        lastChange: action.payload.lastChange,
      };
    case types.CONNECTIONS_REQUEST:
      return { ...state };
    case types.CONNECTION_SUCCESS: {
      const newConnection = action.payload;
      return {
        ...state,
        isFetching: false,
        error: null,
        errorMessage: null,
        statusMessage: newConnection.statusMessage,
        isSyncingManual: newConnection.isSyncingManual,
        lastChange: newConnection.lastSuccessfulSync,
        connections: state.connections.map((connection) =>
          connection.id === newConnection ? newConnection : connection
        ),
      };
    }
    case types.RECONSENT_CONNECTIONS_SUCCESS: {
      return {
        ...state,
        connections: state.connections.map((connection) =>
          action.extra.includes(connection.id)
            ? {
                ...connection,
                needsReconsent: false,
              }
            : connection
        ),
      };
    }
    case types.CONNECTIONS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        errorMessage: null,
        actionIsRequired: action.payload.actionIsRequired,
        statusMessage: action.payload.statusMessage,
        connectionsError: action.payload.errorMessage,
        isSyncingManual: action.payload.isSyncingManual,
        lastChange: action.payload.lastChange,
        connections: action.payload.bankConnections,
      };
    }
    case types.SET_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        connections: state.connections.map((connection) =>
          connection.id === action.payload.bankConnectionId ||
          (connection.id === -1 && !action.payload.bankConnectionId)
            ? {
                ...connection,
                accounts: connection.accounts.map((acc) =>
                  acc.id === action.payload.id ? action.payload : acc
                ),
              }
            : connection
        ),
      };
    case types.CONNECTION_FAILURE:
    case types.CONNECTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: 1,
        errorMessage: action.payload,
      };
    case types.SET_ACCOUNT_NAME_SUCCESS:
    case types.CLOSE_ACCOUNT_SUCCESS:
    case types.SET_OVERDRAFT_SUCCESS:
      return {
        ...state,
        connections: state.connections.map((connection) => ({
          ...connection,
          accounts: connection.accounts.map((account) => {
            if (account.id === action.payload.id) {
              return { ...account, ...action.payload };
            }
            return account;
          }),
        })),
      };
    case types.IS_FETCHING_BANK_CONNECTIONS:
      return { ...state, isFetching: true };
    case types.HIDE_FAILURE:
      return { ...state, error: 1, errorMessage: action.payload };
    case types.HIDE_SUCCESS:
      let id = null;
      if (action.extra.length === 1) id = action.extra[0].id;
      else return state;

      const patchArrayWithHidden = (array) =>
        array?.map((account) => {
          if (account.id === id) {
            return { ...account, isHidden: action.extra[0].isHidden };
          }
          return account;
        }) || [];

      return {
        ...state,
        hidden: id,
        connections: state.connections.map((connection) => ({
          ...connection,
          accounts: patchArrayWithHidden(connection.accounts),
          ultimatePreviewAccounts: patchArrayWithHidden(
            connection.ultimatePreviewAccounts
          ),
          proPreviewAccounts: patchArrayWithHidden(
            connection.proPreviewAccounts
          ),
        })),
      };
    case types.PREFERENCES_REQUEST:
      return { ...state, isFetching: true };
    case types.UPDATE_PREFERENCES_SUCCESS:
    case types.PREFERENCES_SUCCESS:
      return { ...state, preferences: action.payload, isFetching: false };
    case types.PREFERENCES_FAILURE:
      return { ...state, isFetching: false };
    case types.LINK_NUMBER_COMPLETE:
      return {
        ...state,
        user: { ...state.user, phoneNumber: action.payload.phoneNumber },
      };
    case types.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload.categories,
        customCategories: action.payload.customCategories,
        isFetching: false,
        error: null,
        errorMessage: null,
      };
    case types.UPDATE_CATEGORY_SUCCESS: {
      if (!state.customCategories || !action.payload.category) return state;
      return {
        ...state,
        customCategories: state.customCategories.map((cat) =>
          cat.id === action.payload.category.id ? action.payload.category : cat
        ),
      };
    }
    case types.GET_CATEGORIES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: 1,
        errorMessage: action.payload,
      };
    case types.GET_CATEGORIES_REQUEST:
      return { ...state, isFetching: true };
    case types.DELETE_CONNECTION_SUCCESS:
      return {
        ...state,
        connections: state.connections.filter(
          ({ id }) => id !== action.payload.bankConnectionId
        ),
      };
    case types.DELETE_FAILURE:
      return { ...state, isFetching: false };
    case types.SET_PUSH_NOTIFICATIONS:
      return { ...state, isPushNotificationsActive: action.payload };
    case types.LOGGED_OUT:
      return initialState;
    case types.REQUEST_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        verifyingEmail: action.extra.newEmail,
        isResettingPasscode: false,
      };
    case types.RESET_PASSCODE_SUCCESS:
      if (action.payload.nextStep === 'email_otp') {
        return {
          ...state,
          verifyingEmail: state.user.email,
          resetPasscodeNextStep: action.payload.nextStep,
          isResettingPasscode: new Date().toISOString(),
        };
      }
      if (action.payload.nextStep === 'set_new_pin') {
        return {
          ...state,
          resetPasscodeNextStep: action.payload.nextStep,
          verifyingEmail: null,
        };
      }
      return { ...state };
    case types.SUBMIT_EMAIL_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        verifyingEmail: null,
        user: {
          ...state.user,
          email: action.extra.newEmail,
          isEmailVerified: true,
        },
      };
    case NET_SCORE_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          latestFeedbackDate: moment().format(),
          latestFeedbackRating: action.extra.rating || null,
        },
      };
    }
    case types.CHOOSE_RETAINED_CONNECTIONS_SUCCESS: {
      return {
        ...state,
        connections: state.connections.map((connection) => ({
          ...connection,
          deactivatedOverQuota: !action.extra.includes(connection.id),
        })),
      };
    }
    case REVERSE_TRIAL_ACTIVATE_SUCCESS:
    case REVERSE_TRIAL_CANCEL_SUCCESS:
    case REVERSE_TRIAL_ACKNOWLEDGE_START_SUCCESS:
    case REVERSE_TRIAL_ACKNOWLEDGE_END_SUCCESS:
    case REVERSE_TRIAL_DELETE_FUNNEL_START_SUCCESS:
    case REVERSE_TRIAL_CONVERSION_EVENT_SUCCESS:
    case SET_UP_STRIPE_INTENT_CONFIRM_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          emmaProStatus: {
            ...state.user.emmaProStatus,
            ...action.payload.premiumStatus,
            pastDue:
              action.type === SET_UP_STRIPE_INTENT_CONFIRM_SUCCESS
                ? null
                : state.user.emmaProStatus?.pastDue,
          },
        },
      };
    }
    case SELECTED_SPACE:
      return {
        ...state,
        customCategories: [],
      };
    case 'SUBSCRIBE_VIA_STRIPE_SUCCESS':
    case 'CANCEL_STRIPE_SUBSCRIPTION_SUCCESS':
    case 'CHANGE_SUBSCRIPTION_VIA_STRIPE_SUCCESS':
    case 'REACTIVATE_STRIPE_SUBSCRIPTION_SUCCESS':
    case 'REFUND_STRIPE_SUBSCRIPTION_SUCCESS': {
      return {
        ...state,
        user: {
          ...state.user,
          emmaProStatus: {
            ...state.user.emmaProStatus,
            ...action.payload.premiumSubscriptionStatus,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default user;
