import React, { memo, useRef, useMemo, useEffect, useLayoutEffect, useState, } from 'react';
import Animated, { withTiming, useSharedValue, useAnimatedStyle, } from 'react-native-reanimated';
import { View } from 'react-native';
import { CardView, rem } from 'design-system';
import LineChart from 'design-system/LineChart/LineChart';
import useLineGraphData from 'design-system/LineChart/useLineGraphData';
import ChartLoadingOverlay from 'design-system/LineChart/ChartLoadingOverlay';
import TimeFrameController from 'design-system/LineChart/TimeFrameController';
import { delay } from 'utils/delay';
import useStyles from 'hooks/useStyles';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import createStyleSheets from 'utils/createStyleSheets';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { getPointsFromData } from 'features/accounts/hooks/useOverviewChart';
import AccountTransactionsScreenTitle from '../AccountTransactionsScreenTitle';
import { isWeb } from '../../../../constants';
const PADDING_HORIZONTAL = rem(16);
const DEFAULT_PERIOD = '3M';
const PERIODS = ['1W', '1M', '3M', '6M', '1Y'];
const GRAPH_HEIGHT = rem(298);
const CHART_HEIGHT = rem(243);
const ACTUAL_CHART_HEIGHT = CHART_HEIGHT - rem(24 * 2);
const AccountsInvestmentHeader = ({ data, investmentBalances, }) => {
    const styles = useStyles(styleSet);
    const opacity = useSharedValue(0);
    const navigation = useMainStackNavigation();
    const [renderGraph, setRenderGraph] = React.useState(false);
    const cache = useRef({
        '1W': null,
        '1M': null,
        '3M': null,
        '6M': null,
        '1Y': null,
    });
    const { portfolioDataSet, setPortfolioDataSet, selectedPeriod, setSelectedPeriod, requesting, setRequesting, selectedInfoObj, } = useLineGraphData(PERIODS, DEFAULT_PERIOD);
    useEffect(() => {
        if (cache.current[selectedPeriod]?.length) {
            setRequesting(false);
            setPortfolioDataSet((oldDataSet) => ({
                ...oldDataSet,
                ...cache.current,
            }));
        }
        else if (investmentBalances?.periods) {
            const data = (() => {
                switch (selectedPeriod) {
                    case '1M': {
                        if (investmentBalances?.periods.month?.length) {
                            return getPointsFromData(investmentBalances.periods.month);
                        }
                        return null;
                    }
                    case '6M': {
                        if (investmentBalances?.periods.sixmonths?.length) {
                            return getPointsFromData(investmentBalances.periods.sixmonths);
                        }
                        return null;
                    }
                    case '1Y': {
                        if (investmentBalances?.periods.year?.length) {
                            return getPointsFromData(investmentBalances.periods.year);
                        }
                        return null;
                    }
                    case '1W': {
                        if (investmentBalances?.periods.week?.length) {
                            return getPointsFromData(investmentBalances.periods.week);
                        }
                        return null;
                    }
                    default:
                        if (investmentBalances?.periods.threemonths?.length) {
                            return getPointsFromData(investmentBalances.periods.threemonths);
                        }
                        return null;
                }
            })();
            if (data) {
                cache.current[selectedPeriod] = data;
                setRequesting(false);
                setPortfolioDataSet((oldData) => ({
                    oldData,
                    [selectedPeriod]: data,
                }));
            }
        }
    }, [investmentBalances, selectedPeriod, setPortfolioDataSet, setRequesting]);
    const [selectedInfoObjState, setSelectedInfoObjState] = useState();
    React.useEffect(() => {
        // eslint-disable-next-line no-undef
        requestAnimationFrame(() => {
            delay(() => {
                setRenderGraph(true);
                opacity.value = withTiming(1);
            }, 300);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useLayoutEffect(() => {
        navigation.setOptions({
            headerTitle: () => (<AccountTransactionsScreenTitle data={data} selectedInfoObj={selectedInfoObj} selectedInfoObjState={selectedInfoObjState}/>),
        });
    }, [data, selectedInfoObj, selectedInfoObjState]);
    const animatedStyles = useAnimatedStyle(() => ({
        opacity: opacity.value,
    }), [opacity]);
    const points = useMemo(() => {
        if (portfolioDataSet[selectedPeriod]) {
            return portfolioDataSet[selectedPeriod];
        }
        return [];
    }, [portfolioDataSet, selectedPeriod]);
    const { width } = useAppFrameDimensions(isWeb);
    const graph = useMemo(() => {
        if (!renderGraph)
            return null;
        return (<Animated.View style={animatedStyles} pointerEvents="box-none">
        <LineChart points={points} width={width - rem(32)} height={ACTUAL_CHART_HEIGHT} selectedInfoObj={selectedInfoObj} chartWrapperHeight={CHART_HEIGHT} setSelectedInfoObj={setSelectedInfoObjState}/>
      </Animated.View>);
    }, [animatedStyles, points, renderGraph, selectedInfoObj, width]);
    return (<CardView noMarginBottom>
      <View style={styles.graphContainer}>
        <View style={styles.graphTop}>
          <View style={styles.chartContainer}>{graph}</View>
          <View style={styles.flex}/>
          <ChartLoadingOverlay requesting={requesting}/>
        </View>
        <TimeFrameController periods={PERIODS} selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod} paddingHorizontal={PADDING_HORIZONTAL}/>
      </View>
    </CardView>);
};
const styleSet = createStyleSheets(() => ({
    graphContainer: {
        height: GRAPH_HEIGHT,
        paddingBottom: rem(2),
    },
    indicator: {
        marginBottom: rem(16),
    },
    flex: {
        flex: 1,
    },
    chartContainer: {
        height: CHART_HEIGHT,
    },
    graphTop: {
        height: CHART_HEIGHT,
    },
}));
export default memo(AccountsInvestmentHeader);
