import React, { memo, useMemo, useState, useEffect, useCallback } from 'react';
import { FlatList } from 'react-native';
import { delay } from 'utils/delay';
import useColors from 'hooks/useColors';
import { useAppSelector } from 'store/hooks';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { loanTypeReasonValue } from 'features/save/screens/monevo/models';
import { selectIsGBUser, selectDefaultZeroAmount } from 'reducers/selectors';
import { sections } from '../../constants';
import NetworthSpacingView from './NetworthSpacingView';
import { overviewColorMap } from '../../hooks/useBalances';
import AccountsSectionCollapsible from './AccountSectionCollapsible';
import i18n from '../../../../i18n';
function filterNegativeAccounts(data) {
    return Object.keys(data).reduce((acc, key) => {
        if (data[key].negativeAccounts.length > 0) {
            acc[key] =
                data[key];
        }
        return acc;
    }, {});
}
const NetWorthDebtSection = ({ accounts, onRowPress, onOpenLogins, onAddAccount, openAlternative, onOpenUpgrade, onRowLongPress, initialTitle, isUnlockedForSpace = true, }) => {
    const navigation = useMainStackNavigation();
    const isGBUser = useAppSelector(selectIsGBUser);
    const zero = useAppSelector(selectDefaultZeroAmount);
    const filteredNegativeData = filterNegativeAccounts(accounts);
    const [sectionShown, setSectionShown] = useState(initialTitle ? initialTitle === 'Net worth' : true);
    useEffect(() => {
        if (!sectionShown) {
            delay(() => {
                setSectionShown(true);
            }, 400);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const loanTotal = useMemo(() => accounts['LOAN']
        ? {
            amount: accounts['LOAN'].negativeTotal,
            currency: accounts['LOAN'].array[0]?.currency ?? zero.currency,
        }
        : zero, [accounts, zero]);
    const creditCardTotal = useMemo(() => accounts['CREDITCARD']
        ? {
            amount: accounts['CREDITCARD'].negativeTotal,
            currency: accounts['CREDITCARD'].array[0]?.currency ?? zero?.currency,
        }
        : zero, [accounts, zero]);
    const colors = useColors();
    const data = useMemo(() => {
        if (!accounts || !sectionShown)
            return [];
        const baseData = [
            {
                firstCTA: '+ Add a new card',
                secondCTA: isGBUser ? 'find the best' : undefined,
                title: sections.creditCards,
                data: accounts['CREDITCARD']
                    ? accounts['CREDITCARD'].negativeAccounts
                    : [],
                total: creditCardTotal,
                color: colors.text[overviewColorMap.Debt],
                type: 'CREDITCARD',
                reverse: true,
                onRowPress,
                onRowLongPress,
                onOpenLogins,
                onOpenUpgrade,
                onAddAccount,
                openAlternative: () => {
                    navigation.navigate('UserInfo', {
                        flow: 'monevo',
                        type: 'CREDIT_CARD',
                        loan_purpose: loanTypeReasonValue.CREDIT_CARD,
                    });
                },
            },
            {
                firstCTA: '+ Add a loan',
                secondCTA: 'apply for one',
                title: sections.loans,
                data: accounts['LOAN'] ? accounts['LOAN'].negativeAccounts : [],
                total: loanTotal,
                color: colors.text[overviewColorMap.Debt],
                type: 'LOAN',
                reverse: true,
                onRowPress,
                onRowLongPress,
                onOpenLogins,
                onOpenUpgrade,
                onAddAccount,
                openAlternative,
            },
        ];
        const extra = [];
        if ('CHECKING' in filteredNegativeData) {
            extra.push({
                title: i18n.t('current'),
                data: accounts['CHECKING'].negativeAccounts,
                total: {
                    amount: accounts['CHECKING'].negativeTotal,
                    currency: accounts['CHECKING'].array[0]?.currency ?? zero.currency,
                },
                color: colors.text[overviewColorMap.Debt],
                onRowPress,
                onRowLongPress,
                onOpenLogins,
                onOpenUpgrade,
                onAddAccount,
            });
        }
        if ('SAVINGS' in filteredNegativeData) {
            extra.push({
                title: sections.savings,
                type: 'SAVINGS',
                data: accounts['SAVINGS'].negativeAccounts,
                total: {
                    amount: accounts['SAVINGS'].negativeTotal,
                    currency: accounts['SAVINGS'].array[0]?.currency ?? zero.currency,
                },
                color: colors.text[overviewColorMap.Debt],
                onRowPress,
                onRowLongPress,
                onOpenLogins,
                onOpenUpgrade,
                onAddAccount,
            });
        }
        if ('ALL_INVESTMENT' in filteredNegativeData) {
            extra.push({
                title: sections.investments,
                data: accounts['ALL_INVESTMENT'].negativeAccounts,
                total: {
                    amount: accounts['ALL_INVESTMENT'].negativeTotal,
                    currency: accounts['ALL_INVESTMENT'].array[0]?.currency ?? zero.currency,
                },
                color: colors.text[overviewColorMap.Debt],
                type: 'INVESTMENT',
                onRowPress,
                onRowLongPress,
                onOpenLogins,
                onOpenUpgrade,
                onAddAccount,
                openAlternative,
            });
        }
        if ('PENSION' in filteredNegativeData) {
            extra.push({
                title: sections.pensions,
                data: accounts['PENSION'].negativeAccounts,
                total: {
                    amount: accounts['PENSION'].negativeTotal,
                    currency: accounts['PENSION'].array[0]?.currency ?? zero.currency,
                },
                color: colors.text[overviewColorMap.Debt],
                onRowPress,
                onRowLongPress,
                onOpenLogins,
                onOpenUpgrade,
                onAddAccount,
            });
        }
        if ('REAL_ESTATE' in filteredNegativeData) {
            extra.push({
                title: sections.realEstate,
                data: accounts['REAL_ESTATE'].negativeAccounts,
                total: {
                    amount: accounts['REAL_ESTATE'].negativeTotal,
                    currency: accounts['REAL_ESTATE'].array[0]?.currency ?? zero.currency,
                },
                color: colors.text[overviewColorMap.Debt],
                onRowPress,
                onRowLongPress,
                onOpenLogins,
                onOpenUpgrade,
                onAddAccount,
            });
        }
        if ('VEHICLE' in filteredNegativeData) {
            extra.push({
                title: sections.vehicles,
                data: accounts['VEHICLE'].negativeAccounts,
                total: {
                    amount: accounts['VEHICLE'].negativeTotal,
                    currency: accounts['VEHICLE'].array[0]?.currency ?? zero.currency,
                },
                color: colors.text[overviewColorMap.Debt],
                onRowPress,
                onRowLongPress,
                onOpenLogins,
                onOpenUpgrade,
                onAddAccount,
            });
        }
        if ('OTHER' in filteredNegativeData) {
            extra.push({
                title: sections.otherAssets,
                data: accounts['OTHER'].negativeAccounts,
                total: {
                    amount: accounts['OTHER'].negativeTotal,
                    currency: accounts['OTHER'].array[0]?.currency ?? zero.currency,
                },
                color: colors.text[overviewColorMap.Debt],
                onRowPress,
                onRowLongPress,
                onOpenLogins,
                onOpenUpgrade,
                onAddAccount,
            });
        }
        return [
            ...extra,
            ...baseData,
            ...('HIDDEN' in filteredNegativeData
                ? [
                    {
                        title: sections.hidden,
                        data: accounts['HIDDEN'].negativeAccounts,
                        onRowPress,
                        onRowLongPress,
                        onOpenLogins,
                        onOpenUpgrade,
                        onAddAccount,
                    },
                ]
                : []),
            ...('CLOSED' in filteredNegativeData
                ? [
                    {
                        title: sections.closed,
                        data: accounts['CLOSED'].negativeAccounts,
                        onRowPress,
                        onRowLongPress,
                        onOpenLogins,
                        onOpenUpgrade,
                        onAddAccount,
                    },
                ]
                : []),
        ];
    }, [
        accounts,
        colors.text,
        creditCardTotal,
        filteredNegativeData,
        isGBUser,
        loanTotal,
        onAddAccount,
        onOpenLogins,
        onOpenUpgrade,
        onRowLongPress,
        onRowPress,
        openAlternative,
        sectionShown,
        zero.currency,
    ]);
    const renderItem = useCallback(({ item }) => (<AccountsSectionCollapsible {...item} blurAmount={!isUnlockedForSpace}/>), [isUnlockedForSpace]);
    const ListHeader = useCallback(() => <NetworthSpacingView />, []);
    return (<FlatList data={data} scrollEnabled={false} initialNumToRender={2} renderItem={renderItem} ListHeaderComponent={ListHeader}/>);
};
export default memo(NetWorthDebtSection);
