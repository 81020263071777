import React, { useState } from 'react';
import { FlatList } from 'react-native';
import TouchableBounce from 'utils/packages/TouchableBounce';
import moment from 'moment';
import { rem } from 'design-system/values';
import Text from 'design-system/Text';
import { options as LIST_FREQUENCY } from 'design-system/dateFilter/constants';
import createStyleSheets from 'utils/createStyleSheets';
import { formatPeriodMoment } from 'utils/formatting';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import useDateFilter from 'design-system/dateFilter';
import { isWeb } from '../../../../constants';
const keyExtractor = (item) => item;
const KeyboardDates = ({ activeDateFilter, onClickFilter }) => {
    const [show, hide] = useDateFilter();
    const [selectedPeriod, setSelectedPeriod] = useState(activeDateFilter?.title);
    const [weekly, monthly, quarterly, yearly, beginningOfTime, custom] = LIST_FREQUENCY;
    const onSave = (data) => {
        if (!data.type) {
            return;
        }
        setSelectedPeriod(data.type);
        hide();
        let startDate = null;
        let endDate = null;
        let title = '';
        let badgeText = '';
        switch (data.type) {
            case weekly: // weekly
                if ('from' in data && 'to' in data) {
                    startDate = data.from;
                    endDate = data.to;
                    title = weekly;
                    badgeText = formatPeriodMoment({ from: startDate, to: endDate });
                }
                break;
            case monthly: // monthly
                if ('year' in data && 'month' in data) {
                    const { year, month } = data;
                    startDate = moment([year, month]).startOf('month');
                    endDate = moment([year, month]).endOf('month');
                    title = monthly;
                    badgeText = `${moment().month(month).format('MMMM')} ${year}`;
                }
                break;
            case quarterly: // quarterly
                if ('year' in data && 'quarter' in data) {
                    const { year, quarter } = data;
                    startDate = moment().year(year).quarter(quarter).startOf('quarter');
                    endDate = moment().year(year).quarter(quarter).endOf('quarter');
                    title = quarterly;
                    badgeText = `Q${quarter} ${year}`;
                }
                break;
            case yearly: // yearly
                if ('year' in data) {
                    const { year } = data;
                    startDate = moment().year(year).startOf('year');
                    endDate = moment().year(year).endOf('year');
                    title = yearly;
                    badgeText = `Year ${year}`;
                }
                break;
            case custom: // custom
                if ('from' in data && 'to' in data && data.to !== undefined) {
                    startDate = data.from;
                    endDate = data.to;
                }
                else if ('from' in data) {
                    startDate = moment(data.from).startOf('day');
                    endDate = moment(data.from).endOf('day');
                }
                title = custom;
                if (startDate && endDate) {
                    badgeText = formatPeriodMoment({ from: startDate, to: endDate });
                }
                break;
            default:
                break;
        }
        if (startDate && endDate) {
            const value = { startDate, endDate, title };
            onClickFilter('KeyboardDates', {
                ...value,
                badgeText,
                filterType: 'DATES',
            });
        }
    };
    const styles = useStyles(styleSet);
    const colors = useColors();
    const onPressPeriod = (period) => {
        if (period === beginningOfTime) {
            if (activeDateFilter?.title === beginningOfTime)
                return;
            setSelectedPeriod(period);
            const value = { startDate: moment('2000-01-01'), endDate: moment(), title: beginningOfTime };
            onClickFilter('KeyboardDates', {
                ...value,
                badgeText: beginningOfTime,
                filterType: 'DATES',
            });
            return;
        }
        show(onSave, period);
    };
    const renderItem = ({ item, index }) => {
        const isLeftItem = index % 2 === 0;
        const marginRight = isLeftItem ? rem(16) : undefined;
        return (<TouchableBounce style={[
                styles.optionWrapper,
                {
                    ...(selectedPeriod === item ? { borderColor: colors.text.brand } : { borderColor: 'transparent' }),
                    marginRight,
                },
            ]} hoverStyle={styles.hoverOnLight} onPress={() => onPressPeriod(item)}>
        <Text Text-14 {...(selectedPeriod === item ? { Primary: true } : { Secondary: true })}>
          {item}
        </Text>
      </TouchableBounce>);
    };
    return (<FlatList numColumns={2} data={LIST_FREQUENCY} scrollEnabled={false} renderItem={renderItem} style={styles.list} columnWrapperStyle={styles.wrapper} keyExtractor={keyExtractor}/>);
};
const styleSet = createStyleSheets((colors) => ({
    list: {
        backgroundColor: colors.cards.onDark,
        paddingHorizontal: rem(16),
        paddingTop: rem(8),
        ...(isWeb && { borderTopLeftRadius: 16, borderTopRightRadius: 16 }),
    },
    wrapper: { flex: 1 },
    optionWrapper: {
        flex: 1,
        backgroundColor: colors.background.dark,
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: rem(45),
        borderRadius: rem(12),
        marginVertical: rem(8),
        paddingVertical: rem(12),
        paddingHorizontal: rem(16),
    },
    hoverOnLight: {
        backgroundColor: colors.control.hoverOnLight,
    },
}));
export default KeyboardDates;
