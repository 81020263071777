import React, { useEffect, useRef } from 'react';
import { Animated } from 'react-native';
export const FadingContainer = ({ value = 0, duration = 250, delay = 0, auto, onFaded, style, children, }) => {
    const opacity = useRef(new Animated.Value(value)).current;
    useEffect(() => {
        const animation = Animated.timing(opacity, {
            toValue: value,
            delay,
            duration,
            useNativeDriver: true,
        });
        animation.start((result) => {
            onFaded?.(result.finished);
        });
        return () => {
            animation.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
    useEffect(() => {
        if (auto) {
            const animation = Animated.timing(opacity, {
                toValue: 1,
                delay,
                duration,
                useNativeDriver: true,
            });
            animation.start();
            return () => {
                animation.reset();
            };
        }
        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <Animated.View style={[style, { opacity }]}>{children}</Animated.View>;
};
export default FadingContainer;
