import React from 'react';
import { View } from 'react-native';
import Text from 'design-system/Text';
import Amount from 'design-system/Amount';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import { CardView } from 'design-system/CardView';
import UpgradeCard from 'design-system/UpgradeCard';
import IconCircle from 'design-system/icons/IconCircle';
import IconPieChart from 'design-system/icons/IconPieChart';
import AnalyticsControls from 'features/analytics/components/AnalyticsControls';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import IconTransactionCategory from 'features/transactions/icons/IconTransactionCategory';
import { selectIsScrambled } from 'hooks';
import { isDefaultCategory, useCategoryColors } from 'hooks/useCategory';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { useAppSelector } from 'store/hooks';
import createStyleSheets from 'utils/createStyleSheets';
import { BudgetingCircleProgressBar } from './BudgetingCircleProgressBar';
import BudgetingGraphLabel from './BudgetingGraphLabel';
import SetupBudgetCard from './SetupBudgetCard';
const CATEGORY_ICON_SIZE = rem(32);
const MonthlyCategoryHeader = ({ data, periodStartDate, periodEndDate, onPressSetup, type, }) => {
    const gradientColors = useCategoryColors(data);
    const scrambled = useAppSelector(selectIsScrambled);
    const styles = useStyles(styleSet);
    const colors = useColors();
    const benefitId = type === 'merchant' ? 'merchantBudgets' : 'customCategories';
    const { isUnlockedForSpace, onPressUpgrade } = useShouldShowUpgrade({
        benefitId,
    });
    if (!isUnlockedForSpace &&
        (type === 'merchant' || !isDefaultCategory(data.id.toString()))) {
        return (<>
        <Spacer h={16}/>
        <UpgradeCard title="See your spending" description={`Upgrade your plan to continue budgeting on your ${type === 'merchant' ? 'merchants' : 'custom categories'}`} icon={<IconPieChart />} noMarginBottom onPressUpgrade={onPressUpgrade}/>
      </>);
    }
    if (!data.budgetLimit) {
        return (<View>
        <View style={styles.container}>
          <IconCircle width={64} height={64} style={styles.icon} color={colors.cards.onDark}>
            <IconTransactionCategory category={data} width={CATEGORY_ICON_SIZE} height={CATEGORY_ICON_SIZE}/>
          </IconCircle>
          <Amount Numbers-20 centered value={{ value: Math.abs(data.total), currency: data.currency }} style={styles.amountTitle}/>
          <Text TextThin-14 centered Secondary style={styles.subtitle}>
            {data.total <= 0 ? 'Spending' : 'Income'}
          </Text>
        </View>
        <SetupBudgetCard noMarginBottom onPress={onPressSetup} title="Set up a budget" subTitle="Keep track of your spending"/>
      </View>);
    }
    return (<View>
      <AnalyticsControls payPeriod={{ from: periodStartDate, to: periodEndDate }}/>
      <CardView withPadding noMarginBottom>
        <BudgetingCircleProgressBar totalBudget={data.budgetLimit || 0} spending={data.total} scrambled={scrambled} currency={data.currency} gradientColors={gradientColors}/>
        <BudgetingGraphLabel title="Spending" color={gradientColors[0]} amount={{
            currency: data.currency,
            amount: data.total < 0 ? -data.total : 0,
        }}/>
      </CardView>
    </View>);
};
const styleSet = createStyleSheets(() => ({
    container: {
        alignItems: 'center',
    },
    icon: {
        marginTop: rem(14),
        marginBottom: rem(22),
    },
    amountTitle: {
        marginBottom: rem(4),
    },
    subtitle: {
        marginBottom: rem(16),
    },
}));
export default MonthlyCategoryHeader;
