import { URL } from 'utils/packages/rnUrlPolyfill';
const validProtocols = ['emma:', 'https:'];
function isValidProtocol(protocol) {
    return validProtocols.includes(protocol);
}
export var DeepLinkType;
(function (DeepLinkType) {
    DeepLinkType["NONE"] = "NONE";
    DeepLinkType["LOGIN_SUCCESS"] = "LOGIN_SUCCESS";
    DeepLinkType["LOGIN_FAIL"] = "LOGIN_FAIL";
    DeepLinkType["LOGIN_ABORT"] = "LOGIN_ABORT";
    DeepLinkType["EMAIL_VERIFY"] = "EMAIL_VERIFY";
    DeepLinkType["PAYMENT_SUCCESS"] = "PAYMENT_SUCCESS";
    DeepLinkType["PAYMENT_FAIL"] = "PAYMENT_FAIL";
    DeepLinkType["PAYMENT_ABORT"] = "PAYMENT_ABORT";
    DeepLinkType["APP_LINK"] = "APP_LINK";
    DeepLinkType["WEB_SIGN_IN"] = "WEB_SIGN_IN";
})(DeepLinkType || (DeepLinkType = {}));
/**
 * Parses emma:// and https://emma-app.com/ deeplinks
 * If this function grows too large, extract feature-specific handlers into modules
 *
 * @param deepLink string
 * @returns DeepLinkData
 */
export const parseDeepLink = (deepLink) => {
    const [protocol, rest] = deepLink.split('//');
    if (!isValidProtocol(protocol)) {
        // log error
        return {
            type: DeepLinkType.NONE,
        };
    }
    let url;
    try {
        url = new URL(
        // URL() parses urls with custom (like emma://) and regular (like https://) protocols differently.
        // This converts emma:// links to https:// ones for uniform parsing
        protocol !== 'https:' ? `https://emma-app.com/${rest ?? ''}` : deepLink);
    }
    catch (e) {
        // log error
        return {
            type: DeepLinkType.NONE,
        };
    }
    const { pathname, searchParams } = url;
    if (pathname === '/dashboard/email/verify') {
        const token = searchParams.get('token');
        if (token) {
            return {
                type: DeepLinkType.EMAIL_VERIFY,
                token,
            };
        }
        return {
            type: DeepLinkType.NONE,
        };
    }
    // Login callbacks
    if (pathname === '/loginsuccess' && protocol === 'emma:') {
        return {
            type: DeepLinkType.LOGIN_SUCCESS,
        };
    }
    if (pathname === '/loginfail' && protocol === 'emma:') {
        const error = searchParams.get('error');
        const userMessage = searchParams.get('userMessage');
        return {
            type: DeepLinkType.LOGIN_FAIL,
            error,
            userMessage,
        };
    }
    if (pathname === '/loginabort' && protocol === 'emma:') {
        return {
            type: DeepLinkType.LOGIN_ABORT,
        };
    }
    // Payment callbacks
    if (pathname === '/paymentsuccess' && protocol === 'emma:') {
        return {
            type: DeepLinkType.PAYMENT_SUCCESS,
        };
    }
    if (pathname === '/paymentfail' && protocol === 'emma:') {
        return {
            type: DeepLinkType.PAYMENT_FAIL,
        };
    }
    if (pathname === '/paymentabort' && protocol === 'emma:') {
        return {
            type: DeepLinkType.PAYMENT_ABORT,
        };
    }
    if (pathname === '/web-sign-in' && protocol === 'emma:') {
        const clientId = searchParams.get('clientId');
        if (clientId) {
            return {
                type: DeepLinkType.WEB_SIGN_IN,
                clientId,
            };
        }
    }
    return {
        routeStr: url.pathname + url.search,
        type: DeepLinkType.APP_LINK,
    };
};
