import { useCallback } from 'react';
import { budgetingPeriodChanged, deleteAllIncomes, fetchAnalyticsBudgetingData, forceRefreshAnalytics, getFeed, setIncome, } from 'actions';
import { muteErrorAndResult, useFetchOld } from 'hooks';
import { Frequencies } from 'reducers/types';
import { useAppDispatch, useAppStore } from 'store/hooks';
import { uniqBy } from 'lodash';
import { selectFeatureFlag } from 'reducers/selectors';
import { toBudgetApiPayload } from '../utils';
import { rollingWarningAlert } from '../utils/rollingWarningAlert';
import { getBudgets, saveBudgets } from '../actions';
import { isWeb } from '../../../constants';
const isCategoryBudget = (budget) => budget.type === 'category';
const isMerchantBudget = (budget) => budget.type === 'merchant';
export const useSaveBudget = () => {
    const dispatch = useAppDispatch();
    const [, , fetching, fetch] = useFetchOld(muteErrorAndResult);
    const [, , fetchingPeriod, fetchPeriod] = useFetchOld(muteErrorAndResult);
    const store = useAppStore();
    const save = useCallback(async (monthlyBudget, shouldRollover, budgets, period, resetRollingAccumulated) => {
        const currentBudgets = store.getState().budgeting.budgets;
        const currentMonthly = currentBudgets.find((budget) => budget.type === 'overall' && budget.key === 'overall.monthly');
        const budgetKeyCurrentBudgetMap = {};
        currentBudgets.forEach((budget) => {
            budgetKeyCurrentBudgetMap[budget.key] = budget;
        });
        const modifiedBudgets = budgets
            .filter((budget) => {
            // Excluding the monthly budget since we'll be building it manually from parameters
            if (!isCategoryBudget(budget)) {
                return false;
            }
            const existingBudgetLimit = budgetKeyCurrentBudgetMap[budget.key]?.totalLimit;
            if (budget.totalLimit === null) {
                // If the existing budget limit is 0, we don't need to null it
                return existingBudgetLimit !== 0 && existingBudgetLimit !== null;
            }
            return budget.totalLimit !== existingBudgetLimit;
        })
            .map((budget) => ({
            ...budget,
            // This is needed to check not to show the rolling budget popups for new entries
            isNew: !budgetKeyCurrentBudgetMap[budget.key]?.totalLimit,
            // Editing the monthly limit for a category should reset the rolling amount
            baseLimit: budget.totalLimit,
            resetRollingAccumulated: true,
        }));
        // Any budget rollovers that don't match the current state will need updating, not just the modified budgets
        const rolloversDontMatch = currentBudgets.filter((budget) => ((isCategoryBudget(budget) ||
            // There was a bug that meant we have some merchant budgets with rollover set, we want to clear them if this is the case
            (isMerchantBudget(budget) && Boolean(budget.shouldRollover))) &&
            Boolean(budget.shouldRollover) !== shouldRollover) ||
            (resetRollingAccumulated && budget.rollingAccumulatedLimit));
        const withRollovers = uniqBy([...modifiedBudgets, ...rolloversDontMatch], (b) => b.key).map((budget) => ({
            key: budget.key,
            type: budget.type,
            baseLimit: budget.baseLimit || null,
            totalLimit: budget.totalLimit || null,
            shouldRollover: shouldRollover ?? budget.shouldRollover ?? false,
            resetRollingAccumulated: Boolean(resetRollingAccumulated) || budget.resetRollingAccumulated,
        }));
        const modifiedMonthlyBudget = monthlyBudget !== currentMonthly?.totalLimit
            ? monthlyBudget
            : undefined;
        const modifiedBudgetsObject = withRollovers.reduce((prev, budget) => ({
            ...prev,
            [budget.key]: {
                baseLimit: (budget.resetRollingAccumulated
                    ? budget.totalLimit
                    : budget.baseLimit) ?? null,
                shouldRollover: budget.shouldRollover,
                resetRollingAccumulated: budget.resetRollingAccumulated ?? false,
            },
        }), {});
        if (monthlyBudget !== null &&
            modifiedBudgets.find((budget) => budget.resetRollingAccumulated &&
                budget.shouldRollover &&
                !budget.isNew)) {
            const userOkToClearRolling = await rollingWarningAlert();
            if (!userOkToClearRolling) {
                return null;
            }
        }
        const modifiedMonthlyBudgetObject = {};
        if (modifiedMonthlyBudget !== undefined) {
            modifiedMonthlyBudgetObject.baseLimit = modifiedMonthlyBudget;
            // Editing the total limit for a month should also reset the rolling amount
            modifiedMonthlyBudgetObject.resetRollingAccumulated = true;
        }
        if (Boolean(currentMonthly?.shouldRollover) !== shouldRollover) {
            modifiedMonthlyBudgetObject.shouldRollover = shouldRollover;
        }
        if (resetRollingAccumulated && currentMonthly?.rollingAccumulatedLimit) {
            modifiedMonthlyBudgetObject.resetRollingAccumulated = true;
        }
        const didMonthlyBudgetChange = Object.keys(modifiedMonthlyBudgetObject).length > 0;
        const [budgetsResult, periodResult] = await Promise.all([
            withRollovers.length > 0 || didMonthlyBudgetChange
                ? fetch(saveBudgets(didMonthlyBudgetChange
                    ? {
                        ...modifiedBudgetsObject,
                        'overall.monthly': {
                            baseLimit: currentMonthly?.baseLimit ?? null,
                            shouldRollover: currentMonthly?.shouldRollover ?? false,
                            resetRollingAccumulated: false,
                            ...modifiedMonthlyBudgetObject,
                        },
                    }
                    : modifiedBudgetsObject))
                : undefined,
            period
                ? fetchPeriod(period.frequency === Frequencies.Monthly
                    ? deleteAllIncomes()
                    : setIncome({ ...toBudgetApiPayload(period), isPrimary: true }))
                : undefined,
        ]);
        if (monthlyBudget === null &&
            !(selectFeatureFlag(store.getState(), 'new_budgeting_funnel_v2')
                .value || isWeb)) {
            // Budgeting has been disabled. we also must clear out the analytics reducer as we need to reload totals
            dispatch(deleteAllIncomes());
        }
        if (period !== undefined) {
            dispatch(forceRefreshAnalytics(false));
            dispatch(budgetingPeriodChanged());
            dispatch(fetchAnalyticsBudgetingData());
        }
        if (resetRollingAccumulated || rolloversDontMatch.length > 0) {
            dispatch(fetchAnalyticsBudgetingData());
        }
        if (monthlyBudget === null || period !== undefined) {
            dispatch(getFeed());
        }
        return [budgetsResult, periodResult];
    }, [fetchPeriod, store]);
    const saveBudgetByMerchants = useCallback(async (rollover, baseLimit, merchantId, color) => {
        const newBudget = {
            [`merchant.${merchantId}`]: {
                baseLimit: baseLimit || null,
                color: color || null,
                shouldRollover: rollover,
                resetRollingAccumulated: false,
            },
        };
        const result = await fetch(saveBudgets(newBudget));
        return result;
    }, []);
    const get = useCallback(async () => fetch(getBudgets()), []);
    return [fetching || fetchingPeriod, save, get, saveBudgetByMerchants];
};
