import { Dimensions, Platform, PixelRatio, StyleSheet } from 'react-native';
import normalize from 'common/Normalize';
import Phone from 'utils/dimensions';
import { isWeb } from '../constants';
const { width: screenWidth, height: screenHeight } = Dimensions.get('window');
// Rooet Em size
export const rem = (value) => {
    if (isWeb) {
        return value;
    }
    return PixelRatio.roundToNearestPixel(normalize(value / 1.25));
};
// Width scaled pixel, calculated as a 1/375 fraction of a screen width.
// 375 is mocks width in Figma.
export const wsp = (value, dynamicScreenWidth) => PixelRatio.roundToNearestPixel((value * (dynamicScreenWidth || screenWidth)) / 375);
export const SIDEBAR_WIDTH = rem(180);
export const SIDEBAR_WIDTH_LANDSCAPE = rem(180);
export const PAD_SIDE_WIDTH_RATIO = 0.35;
export const PAD_SIDE_WIDTH_RATIO_LANDSCAPE = 0.5;
export const PAD_TAB_SIDE_WIDTH_RATIO = 0.2;
export const PAD_TAB_SIDE_WIDTH_RATIO_LANDSCAPE = 0.4;
const ratio = screenHeight / screenWidth;
/**
 * A conditional function that can be used to render elements based on screen size,
 * mostly limited by height, and fine-tuned for iOS.
 * For example, on a screen there is a caption that is not so important and can be omitted if space is not enough.
 * Usually it happens for phones with big margins (like iPhone SE).
 *
 * Use it like this: if (a(false, true)) { render something that fits everywhere except smallest screens like iPhone 5 }
 * Or like this: marginTop: a(rem(20), rem(30), rem(30), rem(50), rem(50)) - to render bigger margin for an element inside a scrollview, for example, where you cannot set flex: 1
 *
 * @param {type} T - just any type
 * @param {T} small - what to return for iPhone 5 and short screens
 * @param {T} regular - what to return for iPhone 6 and most androids, or to all other screens if only 2 params are set
 * @param {T} big - what to return for iPhone 6 Plus or iPhone XS Max if 5th param was not set
 * @param {T} X - what to return specifically for iPhone X and alike, and iPhone 12 mini as well
 * @param {T} XMax - what to return specifically for iPhone XS Max and alike
 */
export function a(small, regular, big, X, XMax) {
    switch (true) {
        case screenHeight === 896 || screenHeight === 926 || screenHeight === 932 /* 14 Pro Max */: {
            if (XMax !== undefined) {
                return XMax;
            }
            return big !== undefined ? big : regular;
        }
        case screenHeight === 812 || screenHeight === 844 || screenHeight === 852 /* 14 Pro */:
            return X !== undefined ? X : regular;
        case screenWidth <= 360 || ratio < 1.66:
            return small;
        case screenWidth < 414:
            return regular;
        default:
            return big !== undefined ? big : regular;
    }
}
// Primary colors
const primary = '#962DFF';
const primary5 = '#FAF5FF';
const primary85 = '#A64DFF';
const primary70 = '#B66CFF';
const primary50 = 'rgba(150, 45, 255, .5)';
const primary20 = 'rgba(150, 45, 255, .2)';
const primary10 = '#F5EAFF';
const secondary = '#43C6C9';
const secondary85 = '#5FCFD1';
const secondary70 = '#7BD7D9';
const secondary50 = '#A1E2E4';
const secondary20 = '#D9F4F4';
const secondary10 = '#ECF9FA';
const debt = '#74768C';
const savings = '#FB79DA';
// Shades of gray
const black = '#17102A';
const background = '#F5F6F9';
const gray05 = '#F5F6F9';
const gray = '#6E7191';
const gray5 = '#6E71910D';
const gray50 = '#6E719180';
const gray70 = '#9A9CB2';
// Green & Red
const red = '#FF4572'; // Error messages, negative balance
const red25 = '#FF457240'; // Error messages, negative balance
const green = '#1BD8A9'; // Success messages, positive balance
const green25 = '#1BD8A840'; // Success messages, positive balance
const altGreen = '#16C1B7';
const blue = '#46BCF8';
/**
 * @deprecated Use `useColors()` or `createStyleSheets` in combination with `useStyles`.
 */
export const Colors = {
    black20: 'rgba(0, 0, 0, 0.2)',
    black60: 'rgba(0,0,0, 0.6)',
    transparent: 'rgba(0, 0, 0, 0)',
    /**
     * For screen backgrounds, use `background.light` in new color sets,
     * For cards, use `cards.onLight` or `cards.onDark`, depending on how it looks in light mode.
     * For `headerTintColor`, use `text.white`, when the header is rendered on top of brand gradient.
     */
    white: 'rgb(255,255,255)',
    white0: 'rgba(255,255,255, 0)',
    white10: 'rgba(255,255,255, 0.1)',
    /**
     * Use `background.transparent` in new color sets.
     */
    white20: 'rgba(255,255,255, 0.2)',
    white30: 'rgba(255,255,255, 0.3)',
    white40: 'rgba(255,255,255, 0.4)',
    white50: 'rgba(255,255,255, 0.5)',
    white60: 'rgba(255,255,255, 0.6)',
    white80: 'rgba(255,255,255, 0.8)',
    successGreen: '#27E3B4',
    altGreen,
    /**
     * For text color, use `text.primary` in new color sets.
     * For icons, use `elements.primary`.
     */
    black,
    text: black,
    offBlack: black,
    text30: 'rgba(48,53,57, 0.3)',
    /**
     * Use `elements.brand` for icons
     */
    primary,
    /**
     * For text color, use `text.textOnBrand` in new color sets
     * For screen background, use `background.brand`
     */
    brand: primary,
    primary5,
    /**
     * Use `buttons.brandLight` in new color sets
     */
    primary10,
    primary20,
    primary50,
    primary70,
    primary85,
    /**
     * Use `${text.brand}40` in new color sets
     */
    selectionColor: `${primary}40`,
    secondary,
    secondary10,
    secondary20,
    secondary50,
    secondary70,
    /**
     * @deprecated Use `green` gradient
     */
    secondary85,
    brand10: 'rgba(150, 45, 255, 0.1)',
    /**
     * Use `text.income`
     */
    blue: '#46BCF8',
    pink: 'rgb(235,90,148)',
    orange: '#FF9F7F',
    group: '#23B8BB',
    /**
     * Use `text.secondary` in new color sets
     */
    gray,
    /**
     * Use `cards.transparentOnDark` in new color sets
     */
    gray5,
    gray05,
    gray50,
    /**
     * Use `elements.secondaryLight` in new color sets
     */
    gray70,
    grayTint: '#A3B2C5',
    grayBlue: '#110942',
    lightGray: '#F2F4F7',
    /**
     * Use `text.debt` for accounts
     */
    lighterGray: '#6E719180',
    /**
     * For screen backgrounds, use `background.dark` in new color sets,
     * For cards, use `cards.onDark`.
     */
    lightestGray: background,
    /**
     * For screen backgrounds, use `background.dark` in new color sets,
     * for everything else, use `cards.onLight`.
     */
    backgroundGray: background,
    altBackground: '#F3F3FC',
    background: '#FDFDFE',
    /**
     * Use `text.secondary` in new color sets
     */
    label: gray,
    red,
    red25,
    green,
    green25,
    lightGreen: '#31D8BD',
    lightGrayButton: '#F4F6FA',
    /**
     * Use `background.underlay` in new color sets
     */
    underlay: '#17102A04',
    /**
     * Use `text.debt`
     */
    debt,
    /**
     * Use `text.feed`
     */
    savings,
    /**
     * Use `text.positive`
     */
    investments: green,
    /**
     * Use `text.income`
     */
    checking: blue,
    netWorth: primary,
};
export const whiteGradient = {
    colors: ['rgba(255, 255, 255, 0.0729)', '#FFF', 'rgba(255, 255, 255, 0)'],
    locations: [0, 0.5, 1],
    useAngle: true,
    angle: 90,
};
export const altGreenGradient = {
    colors: ['#06F1D8', '#02CFB7'],
    locations: [0, 1],
    useAngle: true,
    angle: 180,
};
export const limeGradient = {
    colors: ['#BCF977', '#DBF023'],
    locations: [0, 1],
    useAngle: true,
    angle: 180,
};
export const peachGradient = {
    colors: ['#FF91A6', '#FFA6B0'],
    locations: [0, 1],
    useAngle: true,
    angle: 180,
};
export const altOrangeGradient = {
    colors: ['#FFA977', '#FF8A8C'],
    locations: [0, 1],
    useAngle: true,
    angle: 180,
};
/**
 * @deprecated Use `darkPurple` gradient
 */
export const purpleGradient = {
    colors: ['#9C39FF', '#8103FF'],
    locations: [0.2688, 0.9723],
    useAngle: true,
    angle: 290,
};
export const altRedGradient = {
    colors: ['#FF5B83', '#FF2674'],
    locations: [0, 1],
    useAngle: true,
    angle: 180,
};
export const deepPinkGradient = {
    colors: ['#FF5191', '#FF55B1'],
    locations: [0, 1],
    useAngle: true,
    angle: 180,
};
/**
 * @deprecated Use `gradients.lightPink`
 */
export const pinkGradient = {
    colors: ['#F365D9', '#F365D9'],
    locations: [0, 1],
    useAngle: true,
    angle: 180,
};
/**
 * @deprecated Use `gradients.plus`
 */
export const goldGradient = {
    colors: ['#FFE28E', '#FFB36C'],
    locations: [0, 1],
    useAngle: true,
    angle: 180,
};
export const turquoiseGradient = {
    colors: ['#22EEEE', '#32E9E4'],
    locations: [0, 1],
    useAngle: true,
    angle: 90,
};
/**
 * @deprecated Use `gradients.red`
 */
export const redGradient = {
    colors: ['#FF2775', '#FF2E77'],
    locations: [0, 1],
    useAngle: true,
    angle: 180,
};
/**
 * @deprecated Use `gradients.darkPurple`
 */
export const darkPurpleGradient = {
    colors: ['#7C4EFF', '#6F3DFF'],
    locations: [0, 1],
    useAngle: true,
    angle: 180,
};
/**
 * @deprecated Use `gradients.darkPink`
 */
export const darkPinkGradient = {
    colors: ['#D65EFF', '#E778F9'],
    locations: [0, 1],
    useAngle: true,
    angle: 180,
};
export const greenGradient = {
    colors: ['#04DEC5', '#04DEC5'],
    locations: [0, 1],
    useAngle: true,
    angle: 180,
};
export const yellowGradient = {
    colors: ['#FFCC69', '#FFE17B'],
    locations: [0, 1],
    useAngle: true,
    angle: 180,
};
export const yellowNewGradient = {
    colors: ['#FFE28E', '#FFB36C'],
    locations: [0, 1],
    useAngle: true,
    angle: 180,
};
/**
 * @deprecated Use `gradients.apricot`
 */
export const apricotGradient = {
    colors: ['#FF8584', '#FF7872'],
    locations: [0, 1],
    useAngle: true,
    angle: 180,
};
export const orangeGradient = {
    colors: ['#FFA778', '#FF9287'],
    locations: [0, 1],
    useAngle: true,
    angle: 180,
};
export const lightPurpleGradient = {
    colors: ['#A64AFE', '#CD68F1'],
    locations: [0, 1],
    useAngle: true,
    angle: 90,
};
/**
 * @deprecated Use `gradients.brandGradient` or `gradients.ultimate`
 */
export const brandPurpleGradient = {
    colors: ['#860DFF', '#9C39FF'],
    locations: [0, 1],
    useAngle: true,
    angle: 90,
};
export const linearPurpleGradient = {
    colors: ['#6C38FF', '#8F67FF'],
    locations: [0, 1],
    useAngle: true,
    angle: 180,
};
export const lightPinkGradient = {
    colors: ['#F56CD9', '#FF82DA'],
    locations: [0, 1],
    useAngle: true,
    angle: 180,
};
export const skyBlueGradient = {
    colors: ['#30CEFF', '#69D2FF'],
    locations: [0, 1],
    useAngle: true,
    angle: 180,
};
export const orangePlusLightGradient = {
    angle: 90,
    useAngle: true,
    locations: [0, 1],
    colors: ['#FFCA76', '#FFC470', '#FFB968'],
};
export const CategoryColors = {
    Orange: ['#FFB593', '#FFC690'],
    LightPink: ['#F56CD9', '#FF82DA'],
    Yellow: ['#FFCC69', '#FFE17B'],
    SkyBlue: ['#30CEFF', '#69D2FF'],
    Green: ['#35D9BB', '#5FE79B'],
    Peach: ['#FF91A6', '#FFA6B0'],
    DarkPurple: ['#7726DD', '#9B17D9'],
    Lime: ['#BCF977', '#DBF023'],
    DeepPink: ['#FF5191', '#FF55B1'],
    Turquoise: ['#1BCBCB', '#32E9E4'],
    BrandPurple: [purpleGradient.colors[1], purpleGradient.colors[0]],
};
export const CategoryColorsCustom = [
    ['rgb(124, 78, 255)', '#6F3DFF'],
    ['rgb(166, 74, 254)', '#CD68F1'],
    ['rgb(245, 108, 217)', CategoryColors.LightPink[1]],
    ['rgb(255, 145, 166)', CategoryColors.Peach[1]],
    ['rgb(255, 39, 117)', '#FF2E77'],
    ['rgb(255, 167, 120)', '#FF9287'],
    ['rgb(255, 204, 105)', CategoryColors.Yellow[1]],
    ['rgb(188, 249, 119)', CategoryColors.Lime[1]],
    ['rgb(34, 238, 238)', CategoryColors.Turquoise[1]],
    ['rgb(4, 222, 197)', '#04DEC5'],
];
export const pinkNewGradient = {
    colors: ['#FC8BD6', '#F263D8'],
    locations: [0, 1],
    useAngle: true,
    angle: 92,
};
/**
 * @deprecated
 */
export const purpleToBlueGradient = {
    colors: ['rgb(166,86,255)', 'rgb(61,173,255)'],
    locations: [0, 1],
};
export const headerShadowGradient = {
    colors: ['#24104512', '#24104502', '#24104500'],
    locations: [0, 0.8, 1],
    useAngle: true,
    angle: 180,
};
/**
 * @deprecated - use styles created with createStyleSheets, and shadows group of colors there
 */
export const shadows = StyleSheet.create({
    button: {
        ...Platform.select({
            ios: {
                shadowOpacity: 1,
                shadowColor: '#0E279940',
                shadowOffset: { width: 8, height: 20 },
                shadowRadius: 36,
            },
            android: {
                elevation: 1,
            },
        }),
    },
    small: {
        ...Platform.select({
            ios: {
                shadowColor: '#13306812',
                shadowOpacity: 1,
                shadowOffset: { width: 10, height: 15 },
                shadowRadius: 40,
            },
            android: {
                elevation: 15,
            },
        }),
    },
    card: {
        ...Platform.select({
            ios: {
                shadowColor: '#13306812',
                shadowOpacity: 1,
                shadowOffset: { width: 10, height: 15 },
                shadowRadius: 40,
            },
        }),
    },
    floatingButton: {
        ...Platform.select({
            ios: {
                shadowColor: '#6b73a04d',
                shadowOpacity: 1,
                shadowOffset: { width: 0, height: 20 },
                shadowRadius: 20,
            },
            android: {
                elevation: 10,
            },
        }),
    },
});
export const hitSlop = {
    top: rem(16),
    bottom: rem(16),
    left: rem(12),
    right: rem(12),
};
// Small hitslop
export const hitSlopS = {
    top: rem(10),
    bottom: rem(10),
    left: rem(10),
    right: rem(10),
};
export const SCROLL_INDICATOR_INSETS = { right: 1 };
export const marginTop = a(20, 20, 20, 44, 44);
export const marginBottom = Phone.isIphoneX() ? 34 : rem(16);
export const headerHeight = Platform.OS === 'ios' ? a(64, 64, 62, 88, 88) : normalize(80);
// todo this comes from the old pay feature, we can probably refactor and remove
const correction = Phone.isTallScreen() ? 1 : 0.8;
export const correct = (size) => rem(size) * correction;
