import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import { Chip } from 'design-system/Chip';
import { rem } from 'design-system/values';
import { DaysOfTheWeek } from 'design-system/day-of-the-week-picker/values';
import { useAppSelector } from 'store/hooks';
import { selectFeatureFlag } from 'reducers/selectors';
import { isWeb } from '../../constants';
const strings = {
    dayOfTheWeek: 'Day of the week',
    [DaysOfTheWeek.Monday]: 'Mon',
    [DaysOfTheWeek.Tuesday]: 'Tue',
    [DaysOfTheWeek.Wednesday]: 'Wed',
    [DaysOfTheWeek.Thursday]: 'Thu',
    [DaysOfTheWeek.Friday]: 'Fri',
};
const DayOfTheWeekPicker = ({ selectedDay, onSelectDay, }) => {
    const newFunnel = useAppSelector((store) => selectFeatureFlag(store, 'new_budgeting_funnel_v2').value) || isWeb;
    const chipsContent = useMemo(() => [
        [DaysOfTheWeek.Monday, DaysOfTheWeek.Tuesday, DaysOfTheWeek.Wednesday],
        [DaysOfTheWeek.Thursday, DaysOfTheWeek.Friday],
    ].map((days, index) => {
        const chips = days.map((d, i) => (<Chip id={d} key={strings[d]} text={strings[d]} {...(newFunnel
            ? { BrandOutline: selectedDay === d }
            : { Brand: selectedDay === d })} type={newFunnel ? 'squared' : 'rounded'} style={[styles.chip, i === days.length - 1 && styles.lastChip]} onPress={(cp) => {
                onSelectDay?.(cp.id);
            }}/>));
        return (<View style={styles.chipsContainer} key={String(index)}>
            {chips}
          </View>);
    }), [newFunnel, onSelectDay, selectedDay]);
    return (<View>
      <Text Text-16 Gray>
        {strings.dayOfTheWeek}
      </Text>
      {chipsContent}
    </View>);
};
const styles = StyleSheet.create({
    chipsContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingTop: rem(16),
    },
    chip: {
        flex: 1 / 3,
    },
    lastChip: {
        marginEnd: 0,
    },
});
export default DayOfTheWeekPicker;
