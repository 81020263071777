import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, createStore } from 'redux';
import { persistStore, autoRehydrate, createTransform } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import startupSaga from 'sagas';
import { initialState } from 'features/spaces/reducers/spaces';
import reducers from '../reducers';
import { isWeb } from '../constants';
import { apiMiddleware } from '../middleware/api';
import { errorMiddleware } from '../middleware/error';
import { analyticsMiddleware } from '../middleware/analytics';
const isDebuggingInChrome = __DEV__ && !!window.navigator?.userAgent;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const logger = createLogger({
    predicate: () => isDebuggingInChrome,
    collapsed: true,
    duration: true,
});
const saga = createSagaMiddleware();
const middlewares = [
    thunk,
    apiMiddleware,
    analyticsMiddleware,
    errorMiddleware,
    saga,
];
// flipper redux debugger
if (__DEV__) {
    const createDebugger = require('redux-flipper').default;
    middlewares.push(createDebugger());
}
const createEMMAStore = applyMiddleware(...middlewares)(createStore);
function configureStore(onComplete) {
    const store = autoRehydrate()(createEMMAStore)(reducers, undefined, 
    // @ts-expect-error TODO fix
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
    persistStore(store, {
        whitelist: isWeb
            ? ['spaces']
            : [
                'addressReducer',
                'accounts',
                'user',
                'feed',
                'utils',
                'payments',
                'spaces',
                'stripe',
                'beneficiaries',
                'invest',
                'pots',
                'contacts',
                'deviceSettings',
                'analytics',
                'signInNavigation',
                'rentReporting',
                'quests',
                'budgeting',
                'paymentLimits',
                'iapSubscriptions',
                'referrals',
            ],
        // @ts-expect-error TODO fix createEMMAStore return type
        storage: AsyncStorage,
        // We need to only persist the selected space id on web so we have it before any requests
        ...(isWeb && {
            transforms: [
                createTransform((inboundState) => inboundState, (state, key) => {
                    if (key === 'spaces') {
                        return {
                            ...initialState,
                            selectedSpace: state.selectedSpace,
                        };
                    }
                    return state;
                }, { whitelist: ['spaces'] }),
            ],
        }),
    }, onComplete);
    if (isDebuggingInChrome) {
        window.store = store;
    }
    saga.run(startupSaga);
    return store;
}
export default configureStore;
