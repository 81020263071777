import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { ScrollView, View } from 'react-native';
import { getWebSignInQRCodeStatus, } from 'actions';
import { AppView } from 'design-system/AppView';
import { CardView } from 'design-system/CardView';
import EmptyComponent from 'design-system/EmptyComponent';
import { Indicator } from 'design-system/Indicator';
import { QRCode } from 'design-system/QRCode';
import Text from 'design-system/Text';
import { IconEmma } from 'design-system/icons';
import { rem } from 'design-system/values';
import useOpenSupport from 'features/support/hooks/useOpenSupport';
import { muteAll, useFetchOld } from 'hooks';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useStyles from 'hooks/useStyles';
import OnboardingMobileWeb from 'login/components/OnboardingMobileWeb';
import useSignIn from 'login/hooks/useSignIn';
import { useAppSelector, useAppStore } from 'store/hooks';
import createStyleSheets from 'utils/createStyleSheets';
import Spacer from 'design-system/Spacer';
import Links from 'utils/links';
const STEPS = [
    {
        title: 'Scan the QR code to sign in',
        subTitle: 'Use your camera app or the QR scanner built in the Emma app - just visit Settings',
    },
    {
        title: 'Confirm your identity',
        subTitle: 'Make sure you are signed in the Emma app and confirm your identity',
    },
    {
        title: 'Start using the web dashboard',
        subTitle: 'Enjoy Emma for Web!',
    },
];
const logo = require('../../../features/pay/img/bear@3x.png');
const OnBoardingScreen = ({ navigation, }) => {
    const { sidebarWidth } = useAppFrameDimensions(true);
    useLayoutEffect(() => {
        navigation.setOptions({
            title: sidebarWidth ? 'Scan QR Code' : 'Welcome',
            headerTitle: EmptyComponent,
        });
    }, []);
    const [signIn, , , handleNextStep] = useSignIn({
        reset: true,
    });
    useEffect(() => {
        signIn({ requestQr: true });
    }, []);
    const clientId = useAppSelector((store) => store.signIn.currentState?.clientId);
    const qrStatus = useAppSelector((store) => store.signInWeb.webSignInConfirmation?.qrStatus);
    const [, , , fetch] = useFetchOld(muteAll);
    const store = useAppStore();
    useEffect(() => {
        const timeout = setInterval(async () => {
            const client = store.getState().signIn.currentState?.clientId;
            if (!client)
                return;
            const result = await fetch(getWebSignInQRCodeStatus({
                clientId: client,
            }));
            if (result?.qrStatus === 'AUTHORIZED') {
                clearInterval(timeout);
                handleNextStep(result.nextStep, { clientId: client });
            }
            else if (result?.qrStatus === 'DENIED' ||
                result?.qrStatus === 'LOGGED_IN') {
                clearInterval(timeout);
                signIn({ requestQr: true });
            }
        }, 2500);
        return () => {
            clearInterval(timeout);
        };
    }, [clientId]);
    const openContactSupport = useOpenSupport();
    const styles = useStyles(styleSet);
    const { paddingHorizontalStyle } = useAppFrameDimensions();
    const contentContainerStyle = useMarginBottom('paddingBottom', undefined, paddingHorizontalStyle);
    const url = `emma://web-sign-in?clientId=${clientId}`;
    const showLoading = !clientId || qrStatus === 'OPENED';
    const openHelp = useCallback(() => {
        Links.openKnownLink('webSignInHelp');
    }, []);
    if (sidebarWidth === 0) {
        return <OnboardingMobileWeb />;
    }
    return (<AppView type="secondary" withFrame={false}>
      <ScrollView contentContainerStyle={contentContainerStyle}>
        <IconEmma withText style={styles.logo} width={rem(92)} height={rem(37)}/>
        <CardView style={styles.outerCard} noMargin>
          <CardView style={styles.qrCard} noMargin>
            {showLoading && (<Indicator dark style={styles.qrLoadingIndicator}/>)}
            <View style={showLoading && styles.qrLoading}>
              <QRCode value={url} ecl="quartile" logo={logo}/>
            </View>
          </CardView>
          {qrStatus === 'OPENED' ? (<View>
              <Text Text-24 style={styles.itemTitle}>
                Waiting for your response...
              </Text>
              <Text TextThin-14 Secondary>
                Approve this login by opening the Emma app and tapping
                &quot;Approve and sign in&quot;.{'\n\n'}Need help?{' '}
                <Text TextThin-14 Primary onPress={openContactSupport}>
                  Get in touch
                </Text>
              </Text>
            </View>) : (<View>
              {STEPS.map((step, index, arr) => (<View key={step.title} style={[
                    styles.itemContainer,
                    index === arr.length - 1 && styles.itemContainerLast,
                ]}>
                  <View style={styles.index}>
                    <Text Numbers-20 Primary>
                      {index + 1}
                    </Text>
                  </View>
                  <View style={styles.itemTextContainer}>
                    <Text Numbers-20 style={styles.itemTitle}>
                      {step.title}
                    </Text>
                    <Text TextThin-16 Secondary>
                      {step.subTitle}
                    </Text>
                  </View>
                </View>))}
              <Spacer.H16 />
              <View style={styles.helpContainer}>
                <Text TextThin-16 Secondary>
                  Struggling to sign in?{' '}
                  <Text Text-16 Primary onPress={openHelp}>
                    Get help
                  </Text>
                </Text>
              </View>
            </View>)}
        </CardView>
      </ScrollView>
    </AppView>);
};
const styleSet = createStyleSheets((colors) => ({
    logo: {
        marginTop: rem(40),
        marginBottom: rem(64),
        alignSelf: 'center',
    },
    outerCard: {
        borderRadius: rem(24),
        paddingHorizontal: rem(24),
        paddingTop: rem(32),
        paddingBottom: rem(24),
        marginHorizontal: rem(16),
    },
    qrCard: {
        backgroundColor: colors.background.dark,
        padding: rem(20),
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: rem(24),
        borderWidth: rem(1),
        borderColor: colors.borders.divider,
    },
    qrLoading: {
        opacity: 0.1,
    },
    qrLoadingIndicator: {
        position: 'absolute',
    },
    itemContainer: {
        flexDirection: 'row',
        paddingVertical: rem(16),
        alignItems: 'center',
    },
    itemContainerLast: {
        paddingBottom: 0,
    },
    itemTitle: {
        marginBottom: rem(4),
    },
    itemTextContainer: {
        flexShrink: 1,
    },
    index: {
        width: rem(48),
        height: rem(48),
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.buttons.brandGroup,
        borderRadius: rem(12),
        marginRight: rem(16),
    },
    helpContainer: {
        paddingTop: rem(16),
        alignItems: 'center',
        borderTopWidth: rem(1),
        borderTopColor: colors.borders.divider,
    },
}));
export default OnBoardingScreen;
