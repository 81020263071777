import React, { useCallback } from 'react';
import { Linking, Platform, View } from 'react-native';
import InAppReview from 'react-native-in-app-review';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Modal from 'design-system/Modal';
import { Button, Spacer } from 'design-system';
import ModalRating from 'design-system/ModalRating';
import { knownLinks } from 'utils/links';
import { useAppDispatch, useAppStore } from 'store/hooks';
import { delay } from 'utils/delay';
import { selectRating } from 'reducers/selectors';
import { SET_LAST_REVIEW_SESSION_COUNT } from 'actions/types/deviceSettings';
import { logQuest } from 'features/quests/actions';
import { LAST_RATE_TIME, HAS_RATED_APP_FLAG } from '../constants';
const strings = {
    reviewTitle: Platform.OS === 'ios'
        ? 'Give us a 5 stars review on the App Store!'
        : 'Give us a 5 stars review on the Google Play Store!',
    reviewNegative: 'No, thanks',
    reviewPositive: "Yes, let's do it!",
};
const useModalRating = () => {
    const dispatch = useAppDispatch();
    const store = useAppStore();
    const showModal = useCallback(() => {
        Modal.show(<View>
        <Spacer h={24}/>
        <Button brand title={strings.reviewPositive} onPress={() => {
                Linking.openURL(Platform.OS === 'android'
                    ? knownLinks.playStore
                    : `${knownLinks.appStore}?action=write-review`);
                Modal.hide();
            }}/>
        <Spacer h={8}/>
        <Button gray title={strings.reviewNegative} onPress={() => {
                Modal.hide();
            }}/>
      </View>, { title: strings.reviewTitle });
    }, []);
    const setLastReviewSessionCount = useCallback(() => {
        dispatch({
            type: SET_LAST_REVIEW_SESSION_COUNT,
            lastReviewSessionCount: selectRating(store.getState()),
        });
    }, []);
    const setAsyncstorageKey = useCallback(async () => {
        try {
            await AsyncStorage.setItem(HAS_RATED_APP_FLAG, 'true');
            await AsyncStorage.setItem(LAST_RATE_TIME, Date.now().toString());
        }
        catch (e) {
            //
        }
    }, []);
    const requestInAppReview = useCallback(async () => {
        dispatch(logQuest('RATE'));
        setAsyncstorageKey();
        if (InAppReview.isAvailable()) {
            try {
                await InAppReview.RequestInAppReview();
                return;
            }
            catch (e) {
                // noop, we can fallback
            }
        }
        delay(() => {
            showModal();
        });
    }, [setAsyncstorageKey, showModal]);
    const requestReview = useCallback((skipLoveCheck) => {
        if (skipLoveCheck) {
            requestInAppReview();
            return;
        }
        setLastReviewSessionCount();
        Modal.show(<ModalRating onPressed={(positive) => {
                Modal.hide(() => {
                    if (positive) {
                        requestInAppReview();
                    }
                });
            }}/>);
    }, [requestInAppReview, setLastReviewSessionCount]);
    return { requestReview };
};
export default useModalRating;
