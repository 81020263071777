import React, { memo, useCallback, useContext, useMemo } from 'react';
import moment from 'moment';
import Spacer from 'design-system/Spacer';
import { CalendarRange } from 'design-system/calendar/CalendarRange';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import { formatPeriodMoment } from 'utils/formatting';
import { minDate } from '../constants';
import { AnalyticsFilterContext } from '../context';
import { isWeb } from '../../../constants';
const WeeklyContent = () => {
    const styles = useStyles(styleSet);
    const { todayDateStr, weeklyRange, setWeeklyRange } = useContext(AnalyticsFilterContext);
    const onChangeRange = useCallback((range) => {
        const fromDate = range[0] ? moment(range[0]).utc(true) : undefined;
        const toDate = range[1] ? moment(range[1]).utc(true) : undefined;
        if (fromDate && toDate) {
            setWeeklyRange([fromDate, toDate]);
        }
        else {
            setWeeklyRange(undefined);
        }
    }, [setWeeklyRange]);
    const title = useMemo(() => {
        if (weeklyRange) {
            return formatPeriodMoment({ from: weeklyRange[0], to: weeklyRange[1] });
        }
        return '';
    }, [weeklyRange]);
    const initialDates = useMemo(() => weeklyRange
        ? [
            weeklyRange[0].format('YYYY-MM-DD'),
            weeklyRange[1].format('YYYY-MM-DD'),
        ]
        : undefined, [weeklyRange]);
    return (<>
      <Spacer h={16}/>
      <CalendarRange hideDays firstDay={1} showReset selectWeek showDisabled title={title} blackMonthText minDate={minDate} maxDate={todayDateStr} hideExtraDays={false} initialDates={initialDates} onChangeRange={onChangeRange} style={styles.calendarContainer} headerStyle={styles.headerStyle} initialDate={initialDates ? initialDates[1] : todayDateStr}/>
      <Spacer h={16}/>
    </>);
};
export default memo(WeeklyContent);
const styleSet = createStyleSheets((colors) => ({
    calendarContainer: {
        maxWidth: '100%',
        backgroundColor: isWeb ? colors.background.light : colors.cards.onDark,
    },
    headerStyle: {
        paddingHorizontal: 0,
    },
}));
