import { REHYDRATE } from 'redux-persist/constants';
import { DEMO_MODAL_NOTIFICATIONS, FORCE_SHOW_MODAL_NOTIFICATIONS, } from 'config/debug';
import * as types from '../actions/types';
export const initialState = {
    featureFlags: {},
    pushId: null,
    netScoreShow: null,
    scrambled: false,
    currentScreen: '',
    modalNotifications: [],
    colorScheme: undefined, // set in user preferences
};
const utils = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE:
            const utils = action.payload?.utils;
            if (utils) {
                return {
                    ...initialState,
                    ...utils,
                    scrambled: false,
                    currentScreen: null,
                    previousScreen: undefined,
                    colorScheme: utils.colorScheme ?? undefined,
                    failedToNavigateToScreen: undefined,
                };
            }
            return state;
        case types.GET_FEATURE_FLAGS_SUCCESS:
            return { ...state, featureFlags: action.payload };
        case types.SET_SCRAMBLED:
            return { ...state, scrambled: action.payload };
        case types.SET_PUSH_ID:
            return { ...state, pushId: action.payload };
        case types.SET_CURRENT_ROUTE_NAME:
            return {
                ...state,
                currentScreen: action.payload,
                previousScreen: ['EmmaProModal', 'SelectPremiumTierScreen'].includes(state.currentScreen || '')
                    ? state.previousScreen
                    : state.currentScreen || undefined,
            };
        case types.GET_MODAL_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                modalNotifications: __DEV__ && FORCE_SHOW_MODAL_NOTIFICATIONS
                    ? DEMO_MODAL_NOTIFICATIONS
                    : action.payload.notifications,
            };
        case types.DISMISS_MODAL_NOTIFICATIONS_SUCCESS:
        case types.CLICKED_MODAL_NOTIFICATIONS_SUCCESS:
            if (action.extra.dismissedAt) {
                return { ...state, modalNotifications: [] };
            }
            return state;
        case types.SET_COLOR_SCHEME: {
            return { ...state, colorScheme: action.payload };
        }
        case types.SET_UNHANDLED_NAVIGATE_ACTION: {
            return { ...state, failedToNavigateToScreen: action.payload };
        }
        default:
            return state;
    }
};
export default utils;
