import React from 'react';
import { View, ActivityIndicator, StyleSheet, } from 'react-native';
import useColors from 'hooks/useColors';
import { assertUnreachable } from 'utils/types';
import { headerHeight } from './values';
const getColorFromColorProps = (colorProps, colors) => {
    if ('color' in colorProps) {
        return colorProps.color;
    }
    if ('brand' in colorProps) {
        return colors.elements.brand;
    }
    if ('dark' in colorProps) {
        return colors.elements.primary;
    }
    if ('light' in colorProps) {
        return colors.elements.onLight;
    }
    if ('black' in colorProps) {
        return colors.elements.black;
    }
    if ('white' in colorProps) {
        return colors.elements.white;
    }
    assertUnreachable(colorProps);
    return colors.elements.onLight;
};
export const Indicator = ({ style, includeHeaderHeight, ...colorProps }) => {
    const colors = useColors();
    return (<View style={[
            styles.content,
            style,
            includeHeaderHeight && styles.headerHeight,
        ]}>
      <ActivityIndicator color={getColorFromColorProps(colorProps, colors)}/>
    </View>);
};
const styles = StyleSheet.create({
    content: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerHeight: {
        marginTop: -headerHeight,
    },
});
export default Indicator;
