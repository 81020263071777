import React from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import Button from 'design-system/Button';
import { rem } from 'design-system/values';
import Squircle from 'design-system/Squircle';
import useColors from 'hooks/useColors';
import TouchableBounce from 'utils/packages/TouchableBounce';
import { isWeb } from '../../../../constants';
const SubscriptionEditTypeModal = ({ title, subTitle, confirm, negative, onConfirm, onCancel, icon, }) => {
    const colors = useColors();
    return (<View style={styles.container}>
      <Squircle width={rem(72)} height={rem(72)} style={styles.icon} color={colors.background.dark}>
        {icon}
      </Squircle>
      <Text Text-20 style={styles.title}>
        {title}
      </Text>
      <Text TextThin-16 Gray centered style={styles.subtitle}>
        {subTitle}
      </Text>
      <Button onPress={onConfirm} style={styles.button} brand title={confirm}/>
      <TouchableBounce onPress={onCancel}>
        <Text Text-16 Gray>
          {negative || 'No, thanks'}
        </Text>
      </TouchableBounce>
    </View>);
};
const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        ...(!isWeb && {
            paddingTop: rem(50),
        }),
    },
    icon: {
        marginBottom: rem(24),
    },
    title: {
        marginBottom: rem(4),
    },
    subtitle: {
        marginBottom: rem(32),
    },
    button: {
        alignSelf: 'stretch',
        marginBottom: rem(16),
    },
});
export default SubscriptionEditTypeModal;
