import React, { useState } from 'react';
import { View, StyleSheet, } from 'react-native';
import Svg, { Defs, Stop, LinearGradient, Rect } from 'react-native-svg';
import { Colors, rem } from './values';
export const GradientView = ({ color, width, height, gradientVector, style, }) => {
    const { x1 = 0, y1 = 0, x2 = 0, y2 = 0 } = gradientVector ?? {};
    return (<Svg width={width} height={height} style={[
            style,
            {
                zIndex: 1,
            },
        ]}>
      <Defs>
        <LinearGradient id="grad" x1={x1} y1={y1} x2={x2} y2={y2}>
          <Stop offset="0" stopColor={color} stopOpacity="1"/>
          <Stop offset="1" stopColor={color} stopOpacity="0"/>
        </LinearGradient>
      </Defs>
      <Rect x="0" y="0" width={width} height={height} fill="url(#grad)"/>
    </Svg>);
};
export const FadingEdgesContainer = ({ fadeDistance = { top: rem(24), bottom: rem(24) }, color = { top: Colors.white, bottom: Colors.white }, style, children, }) => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const onLayout = (layoutEvent) => {
        setWidth(layoutEvent.nativeEvent.layout.width);
        setHeight(layoutEvent.nativeEvent.layout.height);
    };
    return (<View style={[style, styles.containerHorizontal]} onLayout={onLayout}>
      {color.left && fadeDistance.left && fadeDistance.left > 0 ? (<GradientView width={fadeDistance.left} height={height} style={{ marginRight: -fadeDistance.left }} color={color.left} gradientVector={{ x1: 0, x2: 1 }}/>) : null}
      <View style={styles.containerVertical} onLayout={onLayout}>
        {color.top && fadeDistance.top && fadeDistance.top > 0 ? (<GradientView width={width} height={fadeDistance.top} style={{ marginBottom: -fadeDistance.top }} color={color.top} gradientVector={{ y1: 0, y2: 1 }}/>) : null}
        {children}
        {color.bottom && fadeDistance.bottom && fadeDistance.bottom > 0 ? (<GradientView width={width} height={fadeDistance.bottom} style={{ marginTop: -fadeDistance.bottom }} color={color.bottom} gradientVector={{ y1: 1, y2: 0 }}/>) : null}
      </View>
      {color.right && fadeDistance.right && fadeDistance.right > 0 ? (<GradientView width={fadeDistance.right} height={height} style={{ marginLeft: -fadeDistance.right }} color={color.right} gradientVector={{ x1: 1, x2: 0 }}/>) : null}
    </View>);
};
const styles = StyleSheet.create({
    containerHorizontal: {
        flex: 1,
        flexDirection: 'row',
    },
    containerVertical: {
        flex: 1,
        flexDirection: 'column',
    },
});
export default FadingEdgesContainer;
