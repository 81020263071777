import React, { memo, useEffect } from 'react';
import { clamp } from 'lodash';
import { useWindowDimensions, View } from 'react-native';
import Animated, { Extrapolate, interpolate, useSharedValue, interpolateColor, useAnimatedStyle, } from 'react-native-reanimated';
import { rem } from 'design-system';
import Text from 'design-system/Text';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { selectIsScrambled } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { formatAmount } from 'utils/formatting';
import createStyleSheets from 'utils/createStyleSheets';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
const padding = rem(16);
const SetCategoryBudgetsAllocationOld = ({ currency, totalBudget, totalAllocated, scrollY, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const percentAllocated = (totalAllocated / totalBudget) * 100;
    const amountRemaining = totalBudget - totalAllocated;
    const scrambled = useAppSelector(selectIsScrambled);
    const value = useSharedValue(percentAllocated);
    const barStyle = useAnimatedStyle(() => ({
        width: `${value.value}%`,
    }), []);
    const { width: frameWidth } = useAppFrameDimensions();
    const { width: screenWidth } = useWindowDimensions();
    const card = useAnimatedStyle(() => ({
        width: interpolate(scrollY.value, [0, 40], [frameWidth - padding * 2, screenWidth], Extrapolate.CLAMP),
        margin: interpolate(scrollY.value, [0, 40], [padding, 0], Extrapolate.CLAMP),
        backgroundColor: interpolateColor(scrollY.value, [0, 40], [colors.cards.onDark, colors.background.light]),
    }), [colors]);
    useEffect(() => {
        value.value = percentAllocated;
    }, [percentAllocated, value]);
    return (<Animated.View style={[styles.card, card]}>
      <View style={styles.titleRow}>
        {Boolean(totalBudget) && (<Text Numbers-16>{Math.round(percentAllocated)}% set</Text>)}
        <Text Numbers-16>
          {formatAmount(totalAllocated, currency, 2, scrambled, true)}/
          {formatAmount(totalBudget, currency, 2, scrambled, true)}
        </Text>
      </View>
      <View style={styles.barContainer}>
        <Animated.View style={styles.bar}/>
        <Animated.View style={[styles.bar, styles.barFilled, barStyle]}/>
      </View>
      <LeftToAllocate currency={currency} scrambled={scrambled} amountRemaining={amountRemaining}/>
    </Animated.View>);
};
const LeftToAllocate = ({ amountRemaining, currency, scrambled, }) => {
    const toAllocate = clamp(amountRemaining, 0, Infinity);
    return toAllocate === 0 ? (<Text TextThin-14 Red style={leftStyles.leftRow}>
      {formatAmount(toAllocate, currency, 2, scrambled, true)} left to allocate
    </Text>) : (<Text TextThin-14 Gray style={leftStyles.leftRow}>
      {formatAmount(toAllocate, currency, 2, scrambled, true)} left to allocate
    </Text>);
};
const leftStyles = {
    leftRow: {
        paddingHorizontal: rem(8),
    },
};
const styleSet = createStyleSheets((colors) => ({
    card: {
        padding: rem(16),
        backgroundColor: colors.cards.onDark,
        borderRadius: rem(16),
        margin: rem(16),
        overflow: 'hidden',
        alignSelf: 'center',
    },
    titleRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: rem(12),
    },
    barContainer: {
        marginBottom: rem(12),
        paddingHorizontal: rem(5),
        overflow: 'hidden',
    },
    bar: {
        height: rem(4),
        borderRadius: rem(14),
        width: '100%',
        backgroundColor: colors.cards.transparentOnDark,
    },
    barFilled: {
        position: 'absolute',
        backgroundColor: colors.gradients.ultimate.colors[0],
    },
}));
export default memo(SetCategoryBudgetsAllocationOld);
