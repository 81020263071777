import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useEffect, useMemo } from 'react';
import { getStockMeta } from '../actions';
export const lastAPICallTimes = {};
const useStockMeta = (stock) => {
    const symbol = typeof stock === 'string' ? stock : stock.symbol;
    const id = useAppSelector((state) => state.invest.cache[symbol]?.id);
    const name = useAppSelector((state) => state.invest.cache[symbol]?.name);
    const iconUrl = useAppSelector((state) => state.invest.cache[symbol]?.iconUrl);
    const description = useAppSelector((state) => state.invest.cache[symbol]?.description);
    const ceo = useAppSelector((state) => state.invest.cache[symbol]?.ceo);
    const sector = useAppSelector((state) => state.invest.cache[symbol]?.sector);
    const website = useAppSelector((state) => state.invest.cache[symbol]?.website);
    const employees = useAppSelector((state) => state.invest.cache[symbol]?.employees);
    const headquarters = useAppSelector((state) => state.invest.cache[symbol]?.headquarters);
    const pe = useAppSelector((state) => state.invest.cache[symbol]?.pe);
    const eps = useAppSelector((state) => state.invest.cache[symbol]?.eps);
    const beta = useAppSelector((state) => state.invest.cache[symbol]?.beta);
    const marketCap = useAppSelector((state) => state.invest.cache[symbol]?.marketCap);
    const forwardPe = useAppSelector((state) => state.invest.cache[symbol]?.forwardPe);
    const dividendRate = useAppSelector((state) => state.invest.cache[symbol]?.dividendRate);
    const dividendYield = useAppSelector((state) => state.invest.cache[symbol]?.dividendYield);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if ((!name || !iconUrl || !description) && typeof stock === 'string') {
            setTimeout(() => {
                // schedule a macro task to avoid multiple API requests for the same thing.
                // This is a good way to wait for somebody else to fetch this metadata.
                // For example StockScreen fetches the same endpoint for its own use.
                if (!lastAPICallTimes[symbol] || new Date().getTime() - lastAPICallTimes[symbol] > 2000) {
                    lastAPICallTimes[symbol] = new Date().getTime();
                    dispatch(getStockMeta(symbol));
                }
            });
        }
    }, [description, iconUrl, name, stock, symbol]);
    return useMemo(() => {
        if (typeof stock === 'string') {
            return {
                id,
                name,
                iconUrl,
                description,
                ceo,
                sector,
                website,
                employees,
                headquarters,
                pe,
                eps,
                beta,
                marketCap,
                forwardPe,
                dividendRate,
                dividendYield,
            };
        }
        return stock;
    }, [
        beta,
        ceo,
        description,
        dividendRate,
        dividendYield,
        employees,
        eps,
        forwardPe,
        headquarters,
        iconUrl,
        id,
        marketCap,
        name,
        pe,
        sector,
        stock,
        website,
    ]);
};
export default useStockMeta;
