import React, { useCallback } from 'react';
import emoji from 'node-emoji';
import { CardView, SettingsItemNavigate, rem } from 'design-system';
import Section from 'design-system/Section';
import IconTransactionCategory from 'features/transactions/icons/IconTransactionCategory';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import Alert from 'utils/packages/Alert';
import Modal from 'utils/packages/Modal';
import { styles } from './styles';
import ExcludedModal from './ExcludedModal';
const TransactionCategorySection = ({ transaction }) => {
    const navigation = useMainStackNavigation();
    const editCategory = useCallback(() => {
        navigation.navigate('EditCategory', {
            category: transaction.category,
            transaction,
        });
    }, [transaction]);
    const openEdit = useCallback(() => {
        if (transaction.isPending) {
            Alert.alert(emoji.emojify('Hey, slow down :face_with_rolling_eyes:'), "Pending transactions like to change, so we can't edit them yet. Don't worry, they don't count in Analytics.");
            return;
        }
        if (transaction.category?.id === 'internal') {
            Modal.show(<ExcludedModal onPressEdit={() => {
                    Modal.hide(() => {
                        editCategory();
                    });
                }} onCancel={() => {
                    Modal.hide();
                }}/>, { detatched: true });
            return;
        }
        editCategory();
    }, [editCategory, transaction.category?.id, transaction.isPending]);
    return (<Section style={styles.section}>
      <CardView noMarginTop noMarginBottom webPadding>
        <SettingsItemNavigate titleFirst icon={<IconTransactionCategory width={rem(24)} height={rem(24)} category={transaction.category}/>} title={transaction.category?.displayName} description={'Change Category'} onPress={openEdit}/>
      </CardView>
    </Section>);
};
export default TransactionCategorySection;
