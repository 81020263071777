import React, { memo, useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import { CommonActions } from '@react-navigation/native';
import IconEye from 'design-system/icons/IconEye';
import IconText from 'design-system/icons/IconText';
import { SeparatedList } from 'design-system/SeparatedList';
import PopUpMenuModal from 'design-system/PopUpMenuModal.web';
import { IconCloseBold } from 'design-system/icons/IconCloseBold';
import { IconCloseSquircle, SettingsItem, rem } from 'design-system';
import useColors from 'hooks/useColors';
import Modal from 'utils/packages/Modal';
import { muteAll, useFetchOld } from 'hooks';
import { isWeb } from '../../../../constants';
import SubscriptionEditTypeModal from './SubscriptionEditTypeModalContent';
import { cancelSubscriptionAction, editSubscription } from '../../actions';
const SeparatedListComp = isWeb ? View : SeparatedList;
const ModalToUse = isWeb ? PopUpMenuModal : Modal;
const SubscriptionMenu = ({ subscription, mode, navigation, }) => {
    const colors = useColors();
    const [, , , fetch] = useFetchOld(muteAll);
    const onPressRename = () => {
        ModalToUse.hide(() => {
            navigation.navigate('RenameSubscription', {
                data: subscription,
            });
        });
    };
    const goBack = useCallback(() => {
        if (navigation.canGoBack()) {
            navigation.goBack();
        }
        else {
            navigation.dispatch(CommonActions.reset({
                index: 0,
                routes: [{ name: 'Feed' }],
            }));
        }
    }, []);
    const onPressIncorrect = () => {
        const onConfirm = () => {
            fetch(cancelSubscriptionAction(subscription.id));
            Modal.hide();
            goBack();
        };
        ModalToUse.hide(() => {
            Modal.show(<SubscriptionEditTypeModal icon={<IconCloseBold />} title="Not a recurring payment" subTitle={'Would you like to\nremove it?'} confirm="Yes, remove" onConfirm={onConfirm} onCancel={() => Modal.hide()}/>, {
                detatched: true,
            });
        });
    };
    const onPressActiveInactive = () => {
        const onConfirm = () => {
            fetch(editSubscription(subscription.id, {
                isActive: !subscription.isActive,
            }));
            Modal.hide();
            goBack();
        };
        ModalToUse.hide(() => {
            Modal.show(<SubscriptionEditTypeModal icon={<IconEye width={40} height={40}/>} title="Incorrect type" subTitle={`Is this recurring payment ${subscription.isActive ? 'inactive' : 'active'}?`} confirm="Yes, it is" onConfirm={onConfirm} onCancel={() => Modal.hide()}/>, {
                detatched: true,
            });
        });
    };
    return (<SeparatedList style={styles.container}>
      {mode !== 'editPrediction' ? (<SeparatedListComp>
          <SettingsItem title="Rename" onPress={onPressRename} icon={<IconText gradientColors={colors.gradients.ultimate.colors}/>}/>
          <SettingsItem title="This is not a recurring payment" onPress={onPressIncorrect} icon={<IconCloseSquircle color={colors.gradients.red.colors[0]}/>}/>
          <SettingsItem title={subscription.isActive ? 'This is inactive' : 'This is active'} onPress={onPressActiveInactive} icon={<IconEye />}/>
        </SeparatedListComp>) : null}
    </SeparatedList>);
};
const styles = StyleSheet.create({
    container: {
        ...(!isWeb && {
            marginTop: rem(20),
        }),
    },
});
export default memo(SubscriptionMenu);
