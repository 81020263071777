import useStyles from 'hooks/useStyles';
import React, { useMemo, useCallback, useRef } from 'react';
import { View, StyleSheet, Platform, Pressable, } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import TouchableBounce from 'utils/packages/TouchableBounce';
import { splitStyle } from 'utils/dimensions';
import createStyleSheets from 'utils/createStyleSheets';
import { assertUnreachable } from 'utils/types';
import useColors from 'hooks/useColors';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import Indicator from './Indicator';
import { Colors, rem } from './values';
import Text from './Text';
const PREVENT_DOUBLE_TAP_TIMEOUT = 500; // should be enough to update react state and rerender
/**
 * Use `hug` if you don't want it to be wide
 */
export const Button = (props) => {
    const lastTapTime = useRef();
    const colorStyles = useStyles(styleSet);
    const { onPress: providedOnPressHandler } = props;
    const [isHovering, setIsHovering] = React.useState(false);
    const handleEvent = useCallback((event) => () => {
        setIsHovering(event === 'onHoverIn');
    }, []);
    const onPress = useCallback(() => {
        if (providedOnPressHandler) {
            if (lastTapTime.current) {
                const now = new Date().getTime();
                if (now - lastTapTime.current > PREVENT_DOUBLE_TAP_TIMEOUT) {
                    lastTapTime.current = now;
                    providedOnPressHandler();
                }
            }
            else {
                lastTapTime.current = new Date().getTime();
                providedOnPressHandler();
            }
        }
    }, [providedOnPressHandler]);
    const [extStyle, inStyle] = useMemo(() => splitStyle(props.style, {
        removeBackground: true,
        removeBorders: true,
        removeShadows: true,
    }), [props]);
    const containerStyle = useMemo(() => (props.outline ? styles.containerOutlined : styles.container), [props]);
    const buttonStyle = useMemo(() => {
        const color = props;
        if ('black' in color) {
            if (color.absolutely) {
                if ('onBrand' in color) {
                    return colorStyles.blackAbsolutelyOnBrand;
                }
                return styles.absolutelyBlack;
            }
            if (color.onBrand) {
                if (props.disabled) {
                    // that's the only color which changes when disabled, and it also overrides the "layer opacity"
                    return colorStyles.blackOnBrandDisabled;
                }
                return colorStyles.blackOnBrand;
            }
            return colorStyles.black;
        }
        if ('gray' in color || 'grey' in color || 'secondary' in color) {
            return colorStyles.gray;
        }
        if ('brand' in color)
            return colorStyles.brand;
        if ('transparent' in color || 'transparentReversed' in color) {
            return styles.transparent;
        }
        if ('transparentOnDark' in color) {
            return colorStyles.transparentOnDark;
        }
        if ('frosted' in color)
            return colorStyles.frosted;
        if ('brandReversed' in color)
            return colorStyles.brandReversed;
        if ('errorReversed' in color)
            return colorStyles.errorReversed;
        if ('errorPrimary' in color)
            return colorStyles.errorPrimary;
        if ('brandLight' in color)
            return colorStyles.brandLight;
        if ('positive' in color)
            return colorStyles.positive;
        if ('brandBorder' in color)
            return colorStyles.brandBorder;
        if ('brandTextBorder' in color)
            return colorStyles.brandTextBorder;
        if ('onColor' in color)
            return colorStyles.onColor;
        if ('custom' in color)
            return {};
        assertUnreachable(color);
        return {};
    }, [props, colorStyles]);
    const colors = useColors();
    const bottom = useMarginBottom('bottom', undefined, styles.floatingPosition);
    const textColor = useMemo(() => {
        const color = props;
        if ('gray' in color || 'grey' in color) {
            return colors.text.secondary;
        }
        if ('brandReversed' in color)
            return colors.text.textOnBrand;
        if ('errorReversed' in color)
            return colors.text.negative;
        if ('errorPrimary' in color) {
            return colors.text.onPrimary;
        }
        if ('black' in color) {
            if (color.absolutely && !color.onBrand) {
                return colors.text.white;
            }
            return colors.text.onPrimary;
        }
        if ('brand' in color || 'positive' in color) {
            return colors.text.whiteOnColor;
        }
        if ('brandLight' in color) {
            return colors.text.textOnBrand;
        }
        if ('frosted' in color) {
            return colors.text.white;
        }
        if ('custom' in color) {
            if ('custom' in props && 'textColor' in props && props.textColor) {
                return props.textColor;
            }
            return colors.text.white;
        }
        if ('transparent' in color || 'secondary' in color) {
            return colors.text.primary;
        }
        if ('transparentOnDark' in color) {
            return colors.text.secondary;
        }
        if ('transparentReversed' in color) {
            return colors.text.white;
        }
        if ('brandBorder' in color) {
            return colors.text.textOnBrand;
        }
        if ('brandTextBorder' in color) {
            return colors.text.brand;
        }
        if ('onColor' in color) {
            return colors.text.white;
        }
        assertUnreachable(color);
        return colors.text.white;
    }, [
        colors.text.brand,
        colors.text.negative,
        colors.text.onPrimary,
        colors.text.primary,
        colors.text.secondary,
        colors.text.textOnBrand,
        colors.text.white,
        colors.text.whiteOnColor,
        props,
    ]);
    const hoverStyles = useMemo(() => {
        const color = props;
        if ('brand' in color) {
            return colorStyles.brandHover;
        }
        if ('brandLight' in color) {
            return colorStyles.brandLightHover;
        }
        if ('brandReversed' in color || 'brandBorder' in color) {
            return colorStyles.brandGroupHover;
        }
        if ('gray' in color) {
            return colorStyles.grayHover;
        }
        return undefined;
    }, [
        colorStyles.brandGroupHover,
        colorStyles.brandHover,
        colorStyles.brandLightHover,
        colorStyles.grayHover,
        props,
    ]);
    const fontStyle = useMemo(() => {
        if (props.extra && props.small) {
            return { 'Text-12': true };
        }
        if (props.cards || props.square) {
            if (props.thin) {
                return { 'TextThin-14': true };
            }
            return { 'Text-14': true };
        }
        if (props.thin) {
            return { 'TextThin-16': true };
        }
        if (props.small) {
            return { 'Text-14': true };
        }
        return { 'Text-16': true };
    }, [props.cards, props.extra, props.small, props.square, props.thin]);
    const indicator = <Indicator color={textColor}/>;
    const { paddingHorizontal } = useAppFrameDimensions(true);
    const content = props.isFetching || props.loading ? (indicator) : (<View style={[styles.content, inStyle]}>
        {props.leftIcon && (<View style={[styles.leftIconContainer, props.leftIconContainerStyle]}>
            {props.leftIcon}
          </View>)}
        <Text color={textColor} style={[!props.flex && styles.textFlexShrink]} {...fontStyle} numberOfLines={1}>
          {props.title}
        </Text>
        {props.rightIcon && (<View style={[styles.rightIconContainer, props.rightIconContainerStyle]}>
            {props.rightIcon}
          </View>)}
      </View>);
    if ('custom' in props && props.gradientColors)
        return (<TouchableBounce testID={props.testID} disabled={props.disabled || props.isFetching || props.loading} style={[
                props.disabled && styles.disabled,
                containerStyle,
                props.flex && styles.flex,
                props.medium && styles.medium,
                props.small && styles.small,
                props.extra && styles.extraSmall,
                props.square && styles.square,
                extStyle,
                (props.floating || props.fixed) && bottom,
                props.floating && props.disabled && styles.floatingDisabled,
                props.cards && styles.cards,
                props.hug && styles.hug,
                props.containerStyle,
            ]} onPress={props.onPress}>
        <LinearGradient style={[
                styles.gradient,
                props.extra && styles.extraSmall,
                props.square && styles.squareRadius,
            ]} colors={props.gradientColors} useAngle angle={290}>
          {content}
        </LinearGradient>
      </TouchableBounce>);
    return (<Pressable testID={props.testID} onHoverIn={handleEvent('onHoverIn')} onHoverOut={handleEvent('onHoverOut')} disabled={props.disabled || props.isFetching || props.loading} style={[
            props.disabled && styles.disabled,
            containerStyle,
            props.flex && styles.flex,
            props.medium && styles.medium,
            props.small && styles.small,
            props.extra && styles.extraSmall,
            props.square && styles.square,
            extStyle,
            buttonStyle,
            (props.floating || props.fixed) && bottom,
            props.floating && { marginHorizontal: paddingHorizontal },
            props.floating && props.disabled && styles.floatingDisabled,
            props.cards && styles.cards,
            props.hug && styles.hug,
            props.containerStyle,
            isHovering && hoverStyles,
        ]} onPress={props.preventDoubleTap ? onPress : props.onPress}>
      {content}
    </Pressable>);
};
export const HEIGHT = rem(56);
export const HEIGHT_PLUS_PADDING = rem(56 + 16);
export const HEIGHT_MEDIUM = rem(48);
export const HEIGHT_SMALL = rem(40);
export const HEIGHT_EXTRA_SMALL = rem(32);
export const HEIGHT_SQUARE = rem(34);
export const BORDER_RADIUS_SQUARE = rem(10);
const OUTLINE_WIDTH = rem(2);
/**
 * These are invariant styles, they are not affected by light/dark color scheme.
 */
const styles = StyleSheet.create({
    container: {
        height: HEIGHT,
        borderRadius: HEIGHT,
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'stretch',
        ...(Platform.OS === 'ios' && {
            overflow: 'hidden',
        }),
    },
    hug: {
        alignSelf: 'center',
    },
    containerOutlined: {
        height: HEIGHT + OUTLINE_WIDTH * 2,
        borderRadius: HEIGHT + OUTLINE_WIDTH * 2,
        borderWidth: OUTLINE_WIDTH,
        borderColor: Colors.offBlack,
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    cards: {
        borderRadius: rem(8),
        paddingHorizontal: rem(12),
        height: rem(29),
    },
    medium: {
        height: HEIGHT_MEDIUM,
        paddingHorizontal: rem(24),
    },
    small: {
        height: HEIGHT_SMALL,
        paddingHorizontal: rem(22),
    },
    extraSmall: {
        height: HEIGHT_EXTRA_SMALL,
        alignSelf: 'center',
        paddingHorizontal: rem(22),
    },
    square: {
        height: HEIGHT_SQUARE,
        borderRadius: BORDER_RADIUS_SQUARE,
    },
    squareRadius: {
        borderRadius: BORDER_RADIUS_SQUARE,
    },
    leftIconContainer: {
        paddingRight: 12,
    },
    rightIconContainer: {
        paddingLeft: 12,
    },
    floatingPosition: {
        position: 'absolute',
        left: rem(16),
        right: rem(16),
    },
    floatingDisabled: {
        opacity: 0,
    },
    gradient: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        borderRadius: HEIGHT,
        justifyContent: 'center',
    },
    content: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        ...(Platform.OS === 'ios' && {
            overflow: 'hidden',
        }),
    },
    textFlexShrink: {
        flexShrink: 1,
    },
    /**
     * Stays the same no matter of color scheme
     */
    absolutelyBlack: {
        backgroundColor: 'black',
    },
    /**
     * Stays the same no matter of color scheme
     */
    transparent: {
        backgroundColor: 'transparent',
    },
    disabled: {
        opacity: 0.3,
    },
    flex: {
        flex: 1,
    },
});
const styleSet = createStyleSheets((colors) => ({
    black: {
        backgroundColor: colors.buttons.primary,
    },
    transparentOnDark: {
        backgroundColor: colors.cards.transparentOnDark,
    },
    blackOnBrand: {
        backgroundColor: colors.buttons.primaryOnBrand,
    },
    blackAbsolutelyOnBrand: {
        backgroundColor: colors.buttons.blackAbsolutelyOnBrand,
    },
    blackOnBrandDisabled: {
        backgroundColor: colors.buttons.primaryOnBrandDisabled,
        opacity: 0.6,
    },
    brand: {
        backgroundColor: colors.buttons.brand,
    },
    brandReversed: {
        backgroundColor: colors.buttons.brandGroup,
    },
    errorReversed: {
        backgroundColor: colors.buttons.negativeLight,
    },
    errorPrimary: {
        backgroundColor: colors.buttons.negativePrimary,
    },
    brandLight: {
        backgroundColor: colors.buttons.brandLight,
    },
    gray: {
        backgroundColor: colors.buttons.secondary,
    },
    frosted: {
        backgroundColor: colors.buttons.frosted,
    },
    positive: {
        backgroundColor: colors.text.positive,
    },
    brandBorder: {
        borderWidth: 1,
        borderColor: colors.buttons.brandGroup,
    },
    brandTextBorder: {
        borderWidth: 1,
        backgroundColor: colors.background.dark,
        borderColor: colors.text.brand,
    },
    onColor: {
        backgroundColor: colors.buttons.onColor,
    },
    brandHover: {
        backgroundColor: colors.buttons.brandHover,
    },
    brandGroupHover: {
        backgroundColor: colors.buttons.brandGroupHover,
    },
    brandLightHover: {
        backgroundColor: colors.buttons.brandLightHover,
    },
    grayHover: {
        backgroundColor: colors.control.hoverOnDark,
    },
}));
export default Button;
