import { openLink, } from 'utils/packages/plaid';
import Amplitude from './amplitude';
const onExit = (metadata) => {
    let eventName = 'ExitWithoutError';
    if (metadata && metadata.error) {
        eventName = 'ExitWithError';
    }
    Amplitude.logEvent(`PlaidLink.${eventName}`, metadata);
};
const onSuccessCb = (metadata, linkPlaidConnection, setConnectionsStatus, getSyncingStatus) => {
    const meta = metadata;
    Amplitude.logEvent('PlaidLink.Success', meta);
    Promise.resolve(linkPlaidConnection(meta)).then((response) => {
        if (response && response.type === 'LINK_PLAID_CONNECTION_SUCCESS')
            Amplitude.logEvent('PlaidLink.RedirectLoginSuccess', meta);
        else
            Amplitude.logEvent('PlaidLink.RedirectLoginFail', meta);
        setConnectionsStatus(true);
        getSyncingStatus();
        return response;
    });
};
export const openPlaid = (getPlaidLink, linkPlaidConnection, setConnectionsStatus, getSyncingStatus) => {
    Promise.resolve(getPlaidLink()).then((response) => {
        if (response?.payload?.params?.token) {
            const onSuccess = (meta) => {
                onSuccessCb(meta, linkPlaidConnection, setConnectionsStatus, getSyncingStatus);
            };
            openLink({
                onExit,
                onSuccess,
                tokenConfig: {
                    token: response.payload.params.token,
                    noLoadingState: false,
                },
            });
        }
        return response;
    });
};
export const openPlaidRenew = (
// todo we need to fix types here too but it was having too many impacts elsewhere
data, linkPlaidConnection, setConnectionsStatus, getSyncingStatus) => {
    const onSuccess = (meta) => {
        onSuccessCb(meta, linkPlaidConnection, setConnectionsStatus, getSyncingStatus);
    };
    // @ts-expect-error
    if (data?.payload?.params?.token) {
        openLink({
            onExit,
            onSuccess,
            tokenConfig: {
                // @ts-expect-error
                token: data.payload.params.token,
                noLoadingState: false,
            },
        });
    }
};
