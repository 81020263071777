import React, { useCallback } from 'react';
import { IconNote } from 'design-system/icons';
import { IconBoldAdd } from 'design-system/icons/IconBoldAdd';
import Links from 'utils/links';
import { delay } from 'utils/delay';
import { loanTypeReasonValue, } from 'features/save/screens/monevo/models';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { SettingsItem } from 'design-system/SettingsItem';
const QuotesModalContent = ({ hideModal, url, flow, type }) => {
    const navigation = useMainStackNavigation();
    const handleSeeLatestQuote = useCallback(() => {
        hideModal();
        delay(() => {
            Links.openLink(url);
        }, 60);
    }, [hideModal, url]);
    const handleStartNewQuote = useCallback(() => {
        hideModal();
        delay(() => {
            if (type === 'CREDIT_CARD') {
                navigation.navigate('UserInfo', {
                    flow,
                    type,
                    loan_purpose: loanTypeReasonValue.CREDIT_CARD,
                });
                return;
            }
            navigation.navigate('LoanAmount', { flow, type });
        }, 60);
    }, [flow, hideModal, type]);
    return (<>
      <SettingsItem title="See latest quote" onPress={handleSeeLatestQuote} icon={<IconNote gradientKey="darkPurple"/>}/>
      <SettingsItem title="Start a new quote" onPress={handleStartNewQuote} icon={<IconBoldAdd gradientKey="orange"/>}/>
    </>);
};
export default QuotesModalContent;
