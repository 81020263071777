import { useCallback, useRef } from 'react';
import moment from 'moment';
import { getCategoryExpenses, getCategoryTotals, getMerchantExpenses, isFetchingCategoryExpenses, } from 'actions';
import { selectHasSetPaydayRange } from 'reducers/selectors';
import { useAppDispatch, useAppStore } from 'store/hooks';
import { useMainStackNavigation } from 'utils/types/navigationV6';
export const mapStepToMomentUnit = (step) => {
    if (step && step !== 'payperiod') {
        if (step === 'isoWeek') {
            return 'week';
        }
        if (step === 'custom') {
            return 'day';
        }
        return step;
    }
    return 'month';
};
export const useOpenCategory = (monthlyTotals, position, categoryRoute, step, accountIds) => {
    const navigation = useMainStackNavigation();
    const dispatch = useAppDispatch();
    const store = useAppStore();
    // TODO come up with a better way to optimize rerenders
    const monthlyTotalsRef = useRef(monthlyTotals);
    monthlyTotalsRef.current = monthlyTotals;
    const positionRef = useRef(position);
    positionRef.current = position;
    const stepRef = useRef(step);
    stepRef.current = step;
    const accountIdsRef = useRef(accountIds);
    accountIdsRef.current = accountIds;
    return useCallback((data) => {
        // const { id } = data.data;
        const { from, to } = monthlyTotalsRef.current[positionRef.current];
        let toDate = moment(to);
        let fromDate = moment(from);
        const unit = mapStepToMomentUnit(stepRef.current);
        // For 'custom' filters we only want to get the range they selected
        if (stepRef.current !== 'custom') {
            if (positionRef.current === 0) {
                fromDate = fromDate.subtract(6, unit).startOf(unit);
            }
            else {
                toDate = moment(to).add(3, unit).startOf(unit);
                fromDate = moment(from).subtract(3, unit).startOf(unit);
                if (moment(to).add(3, unit).diff(moment()) > 0) {
                    toDate = moment(monthlyTotalsRef.current[0].to);
                }
            }
        }
        const categoryId = data.type === 'category' ? data.data.id : undefined;
        const merchantId = data.type === 'merchant' ? data.data.id : undefined;
        dispatch(isFetchingCategoryExpenses());
        if (categoryRoute === 'Category') {
            dispatch(getCategoryTotals({
                dateFrom: fromDate.format('YYYY-MM-DD'),
                dateTo: toDate.format('YYYY-MM-DD'),
                categoryId,
                merchantId,
                isPayDay: selectHasSetPaydayRange(store.getState()),
                step: stepRef.current,
                reset: true,
                accountIds: accountIdsRef.current,
            }));
        }
        const fromFormatted = moment(from).format('YYYY-MM-DD');
        const toFormatted = moment(to).format('YYYY-MM-DD');
        if (merchantId) {
            dispatch(getMerchantExpenses(fromFormatted, toFormatted, merchantId, accountIdsRef.current));
        }
        else if (categoryId) {
            dispatch(getCategoryExpenses(fromFormatted, toFormatted, categoryId, accountIdsRef.current));
        }
        if (categoryRoute === 'Category') {
            const params = {
                ...(merchantId && {
                    merchantId,
                }),
                ...(categoryId && {
                    categoryId,
                }),
                from: moment(from).format('YYYY-MM-DD'),
                to: moment(to).format('YYYY-MM-DD'),
                step: stepRef.current,
                accountIds: accountIdsRef.current,
            };
            navigation.navigate({ name: categoryRoute, params });
        }
        else {
            const monthlyCategoryParams = {
                ...(merchantId && {
                    merchantId,
                }),
                ...(categoryId && {
                    categoryId,
                }),
                from: moment(from).format('YYYY-MM-DD'),
                to: moment(to).format('YYYY-MM-DD'),
                step: stepRef.current,
                isEditable: data.type === 'merchant'
                    ? positionRef.current === 0
                    : Boolean(data.data.budgetLimit),
            };
            const params = monthlyCategoryParams;
            navigation.navigate({ name: categoryRoute, params });
        }
    }, [categoryRoute, dispatch, store]);
};
