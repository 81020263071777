import React, { useCallback, useState, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import moment from 'moment';
import Button from 'design-system/Button';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import Modal from 'utils/packages/Modal';
import { useMarginBottom } from 'hooks/useMarginBottom';
import { useRequiredBenefitCallbackWithSpaces } from 'features/premium/hooks/useRequiredBenefitCallback';
import Controller from './Controller';
import { filterStartYear, options } from './constants';
import { DateFilterContext } from './context';
import { getYearArrArr } from '../analyticsFilter';
import { isWeb } from '../../constants';
const currentYear = moment().year();
const DateFilter = ({ onSave, period }) => {
    const styles = useStyles(modalStyleSet);
    const paddingBottom = useMarginBottom('paddingBottom', undefined, styles.container);
    const currentStates = useMemo(() => ({
        currentYear: moment().year(),
        currentMonth: moment().month(),
        currentQuarter: moment().quarter(),
        todayDateStr: new Date().toISOString().slice(0, 10),
    }), []);
    const { yearData, defaultYearIndex } = getYearArrArr(filterStartYear, currentYear, currentYear);
    // weekly
    const [weeklyRange, setWeeklyRange] = useState();
    // monthly
    const [selectedYear, setSelectedYear] = useState(currentStates.currentYear);
    const [selectedMonth, setSelectedMonth] = useState(currentStates.currentMonth);
    // quarterly
    const [selectedQuarter, setSelectedQuarter] = useState(currentStates.currentQuarter);
    // yearly
    const [selectedYearFilter, setSelectedYearFilter] = useState(currentStates.currentYear);
    const [selectedYearFilterIndex, setSelectedYearFilterIndex] = useState(defaultYearIndex);
    // custom
    const [to, setTo] = useState();
    const [from, setFrom] = useState();
    const contextValue = useMemo(() => ({
        period,
        ...currentStates,
        // week
        weeklyRange,
        setWeeklyRange,
        // month
        selectedYear,
        selectedMonth,
        setSelectedYear,
        setSelectedMonth,
        // quarter
        selectedQuarter,
        setSelectedQuarter,
        // year
        yearData,
        defaultYearIndex,
        selectedYearFilter,
        setSelectedYearFilter,
        selectedYearFilterIndex,
        setSelectedYearFilterIndex,
        to,
        from,
        setTo,
        setFrom,
    }), [
        currentStates,
        defaultYearIndex,
        from,
        period,
        selectedMonth,
        selectedQuarter,
        selectedYear,
        selectedYearFilter,
        selectedYearFilterIndex,
        to,
        weeklyRange,
        yearData,
    ]);
    const [weekly, monthly, quarterly, yearly, , custom] = options;
    const handleSave = () => {
        switch (period) {
            case weekly:
                if (weeklyRange) {
                    onSave({
                        from: weeklyRange[0],
                        to: weeklyRange[1],
                        type: 'Weekly',
                    });
                }
                break;
            case monthly:
                if (selectedMonth !== undefined) {
                    onSave({
                        month: selectedMonth,
                        year: selectedYear,
                        type: 'Monthly',
                    });
                }
                break;
            case quarterly:
                if (selectedQuarter !== undefined) {
                    onSave({
                        quarter: selectedQuarter,
                        year: selectedYear,
                        type: 'Quarterly',
                    });
                }
                break;
            case yearly:
                if (selectedYearFilter !== undefined) {
                    onSave({ year: selectedYearFilter, type: 'Yearly' });
                }
                break;
            case custom:
                if (from) {
                    onSave({ from, to, type: 'Custom' });
                }
                break;
            default:
                break;
        }
    };
    return (<DateFilterContext.Provider value={contextValue}>
      <View style={paddingBottom}>
        {!isWeb && (<View style={styles.titleContainer}>
            <Text flex Text-24 centered>
              Filter period
            </Text>
          </View>)}
        <Controller />
        <View style={styles.buttonContainer}>
          <Button brand onPress={handleSave} title="Save"/>
        </View>
      </View>
    </DateFilterContext.Provider>);
};
const useDateFilter = () => {
    const show = useRequiredBenefitCallbackWithSpaces(useCallback((onSave, period) => {
        Modal.show(<DateFilter onSave={onSave} period={period}/>, {
            containerStyle: styles.modal,
            lightBg: isWeb,
            title: isWeb ? (<View style={styles.titleContainer}>
            <Text flex Text-16>
              Filter period
            </Text>
          </View>) : undefined,
            titleBottomBorder: isWeb,
        });
    }, []), 'search');
    const hide = useCallback(() => {
        Modal.hide();
    }, []);
    return [show, hide];
};
export default useDateFilter;
const styles = StyleSheet.create({
    modal: {
        paddingHorizontal: 0,
        margin: 0,
        paddingBottom: 0,
    },
    titleContainer: {
        justifyContent: 'center',
    },
});
const modalStyleSet = createStyleSheets((colors) => ({
    container: {
        backgroundColor: isWeb ? colors.cards.onLight : colors.background.dark,
    },
    titleContainer: {
        paddingVertical: rem(8),
        paddingHorizontal: rem(16),
        marginTop: rem(26),
    },
    resetButton: {
        alignSelf: 'center',
        marginTop: rem(8),
        marginBottom: rem(16),
    },
    resetButtonCompact: {
        marginBottom: rem(8),
    },
    buttonContainer: {
        ...(!isWeb && {
            marginHorizontal: rem(16),
        }),
        marginTop: rem(8),
    },
}));
